import {CallAPI, CallAPIWithoutResponse} from "../../../api/API";
// todo refactor
export const getContactData = async (setLoad, setError, setData) => {
    const result = await CallAPI('/contactPage', null, 'GET')
    if (result.isSuccess) {
        setData(result.data)
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania treści strony"
        })
    }
    setLoad(false)
}

export const saveContactData = async (accessToken, setError, mainHeader, subHeader, description, content) => {

    const body = {
        mainHeader: mainHeader,
        subHeader: subHeader,
        description: description,
        content: content,
    }

    const result = await CallAPIWithoutResponse('/contactPage/add', body, 'POST', accessToken)
    if (!result.isSuccess) {
        setError({
            show: true,
            title: "Bląd zapisania",
            message: "Błąd podczas zapisywania treści strony"
        })
    }
}