import React, {useState} from "react";
import Checkbox from "../../form/checkbox";
import GetPhotoButton from "../../buttons/getPhotoButton";
import PrintingInColor from "./PrintingInColor";
// todo refactor
const ProductItem = ({product, activeOrder}) => {

    const [printLogo, setPrintLogo] = useState(true)

    return (
        <div>
            <p>Id produktu: {product.id}</p>
            <p>Obraz: {product.imageTemplate.cardName}</p>
            <p>Format: {product.editorSize.width + ' x ' + product.editorSize.height}</p>
            <p>Cena za obraz: {product.editorSize.price + ' zł'}</p>
            <p>Zamówionych sztuk: {product.volume}</p>
            <p>Rama: {product.frame ? product.frame.name : 'Brak'}</p>
            {product.frame ?
                <p>Cena za rame: {product.frame.price + ' zł'}</p>
                :
                null
            }
            <div className="mb-3">
                <Checkbox
                    label='Drukuj Logo'
                    value={printLogo}
                    id={product.id}
                    onChangeScript={(event) => setPrintLogo(event.target.checked)}
                />
            </div>
            {product.imageTemplate.type === 'Wiele grafik' ?
                <p>Przesłane zdjęcia:
                    {product.imageTemplate.graphicsSections.map((section, index) => {
                        let idx = index + 1
                        return <GetPhotoButton id={section.id}
                                               key={section.id}
                                               name={product.photoName + '_' + idx + '_' + product.editorSize.width + 'x' + product.editorSize.height + '_' + activeOrder.id + '.jpeg'}
                                               url={'/approvedOrder/downloadGraphicSection/' + activeOrder.clientContext.id + '/' + product.id + '/' + section.id}
                        />
                    })}
                </p>
                :
                <p>Przesłane zdjęcie:
                    <GetPhotoButton id={product.id}
                                    name={product.photoName + '_' + product.editorSize.width + 'x' + product.editorSize.height + '_' + activeOrder.id + '.jpeg'}
                                    url={'/approvedOrder/downloadPhoto/' + activeOrder.clientContext.id + '/' + product.id}
                    />
                </p>
            }
            <p>Plik do druku (bez zadrukowanych boków):
                {product.imageTemplate.type === 'Własne zdjęcie' ?
                    <GetPhotoButton id={product.id}
                                    name={product.photoName + '_' + product.editorSize.width + 'x' + product.editorSize.height + '_' + activeOrder.id + '_frame.jpeg'}
                                    url={'/approvedOrder/downloadPhoto/frame/' + activeOrder.clientContext.id + '/' + product.id + '?printedSides=noPrintedSides&color=null&printLogo=' + printLogo}
                    />
                    :
                    <GetPhotoButton id={product.id}
                                    name={product.photoName + '_' + product.editorSize.width + 'x' + product.editorSize.height + '_' + activeOrder.id + '_frame.jpeg'}
                                    url={'/approvedOrder/downloadPhoto/graphic/' + activeOrder.clientContext.id + '/' + product.id + '?printedSides=noPrintedSides&color=null&printLogo=' + printLogo}
                    />
                }
            </p>
            <PrintingInColor product={product}
                             contextId={activeOrder.clientContext.id}
                             printLogo={printLogo}
                             activeOrder={activeOrder}/>
            <p>Plik do druku (Zadrukowane boki - lustrzane odbicie):
                {product.imageTemplate.type === 'Własne zdjęcie' ?
                    <GetPhotoButton id={product.id}
                                    name={product.photoName + '_' + product.editorSize.width + 'x' + product.editorSize.height + '_' + activeOrder.id + '_frame_reflection.jpeg'}
                                    url={'/approvedOrder/downloadPhoto/frame/' + activeOrder.clientContext.id + '/' + product.id + '?printedSides=printingReflection&color=null&printLogo=' + printLogo}
                    />
                    :
                    <GetPhotoButton id={product.id}
                                    name={product.photoName + '_' + product.editorSize.width + 'x' + product.editorSize.height + '_' + activeOrder.id + '_frame_reflection.jpeg'}
                                    url={'/approvedOrder/downloadPhoto/graphic/' + activeOrder.clientContext.id + '/' + product.id + '?printedSides=printingReflection&color=null&printLogo=' + printLogo}
                    />
                }
            </p>
            <p>Plik do druku (Zadrukowane boki - pixel):
                {product.imageTemplate.type === 'Własne zdjęcie' ?
                    <GetPhotoButton id={product.id}
                                    name={product.photoName + '_' + product.editorSize.width + 'x' + product.editorSize.height + '_' + activeOrder.id + '_frame_pixel.jpeg'}
                                    url={'/approvedOrder/downloadPhoto/frame/' + activeOrder.clientContext.id + '/' + product.id + '?printedSides=printingPixel&color=null&printLogo=' + printLogo}
                    />
                    :
                    <GetPhotoButton id={product.id}
                                    name={product.photoName + '_' + product.editorSize.width + 'x' + product.editorSize.height + '_' + activeOrder.id + '_frame_pixel.jpeg'}
                                    url={'/approvedOrder/downloadPhoto/graphic/' + activeOrder.clientContext.id + '/' + product.id + '?printedSides=printingPixel&color=null&printLogo=' + printLogo}
                    />
                }
            </p>
            <img className='img-fluid col-4'
                 src={'/ordersPhoto/' + product.photoName + '_frame.jpeg'}
                 alt='Pobierz plik do druku'
            />
            <hr/>
        </div>
    )
};

export default ProductItem;