import React from "react";

const ModalBox = ({children, size}) => {

    return (
        <div
            className="modal modal-display-block"
            tabIndex="-1"
            role="dialog"
        >
            <div className={`modal-dialog modal-${size}`}>
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>
    )
};

export default ModalBox;