import React, {useState} from "react";
import AddImageTemplateBox from "./addImageTemplateBox";
import TemplatesBox from "./templatesBox";
import TabWrapper from "../TabWrapper";

const ImageTemplate = () => {

    const [update, setUpdate] = useState(true)

    return (
        <TabWrapper>
            <AddImageTemplateBox setUpdate={setUpdate}/>
            <TemplatesBox {...{
                update: update,
                setUpdate: setUpdate
            }}/>
        </TabWrapper>
    )
}

export default ImageTemplate;