export const CallAPI = async (url, body, method) => {
// todo refactor
    const result = {
        isSuccess: false,
        data: {}
    }

    if (method === 'GET') {
        await fetch(process.env.REACT_APP_API_URL + url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        }).then((response) => {
            if (response.ok) {
                result.isSuccess = true
                return response
            } else {
                result.isSuccess = false
                return response.text().then(text => {
                    throw(JSON.parse(text));
                })
            }
        }).then((response) => response.json()).then((data) => {
            result.data = data
        }).catch((error) => {
            result.isSuccess = false
            result.data = error
        });
    } else {
        await fetch(process.env.REACT_APP_API_URL + url, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(body),
        }).then((response) => {
            if (response.ok) {
                result.isSuccess = true
                return response
            } else {
                result.isSuccess = false
                return response.text().then(text => {
                    throw(JSON.parse(text));
                })
            }
        }).then((response) => response.json()).then((data) => {
            result.data = data
        }).catch((error) => {
            result.isSuccess = false
            result.data = error
        });
    }

    return result;
}

export const CallSecurityAPI = async (url, body, method, accessToken) => {

    const result = {
        isSuccess: false,
        data: {}
    }

    if (method === 'GET') {
        await fetch(process.env.REACT_APP_API_URL + url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken
            },
            credentials: 'include',
        }).then((response) => {
            if (response.ok) {
                result.isSuccess = true
                return response
            } else {
                result.isSuccess = false
                return response.text().then(text => {
                    throw(JSON.parse(text));
                })
            }
        }).then((response) => response.json()).then((data) => {
            result.data = data
        }).catch((error) => {
            result.isSuccess = false
            result.data = error
        });
    } else {
        await fetch(process.env.REACT_APP_API_URL + url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken
            },
            credentials: 'include',
            body: JSON.stringify(body),
        }).then((response) => {
            if (response.ok) {
                result.isSuccess = true
                return response
            } else {
                result.isSuccess = false
                return response.text().then(text => {
                    throw(JSON.parse(text));
                })
            }
        }).then((response) => response.json()).then((data) => {
            result.data = data
        }).catch((error) => {
            result.isSuccess = false
            result.data = error
        });
    }

    return result;
}

export const CallAPIWithoutResponse = async (url, body, method, accessToken) => {

    const result = {
        isSuccess: false,
    }

    if (method === 'GET') {
        await fetch(process.env.REACT_APP_API_URL + url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken
            },
            credentials: 'include',
        }).then((response) => {
            result.isSuccess = response.ok;
        }).catch(() => {
            result.isSuccess = false
        });
    } else {
        await fetch(process.env.REACT_APP_API_URL + url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken
            },
            credentials: 'include',
            body: JSON.stringify(body),
        }).then((response) => {
            result.isSuccess = response.ok;
        }).catch(() => {
            result.isSuccess = false
        });
    }

    return result;
}

export const CallAPIGetImage = async (url, accessToken, fileName) => {

    const result = {
        isSuccess: false,
    }

    await fetch(process.env.REACT_APP_API_URL + url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        },
        credentials: 'include',
    }).then((response) => {
        if (response.ok) {
            result.isSuccess = true
            return response.blob()
        } else {
            result.isSuccess = false
        }
    }).then(blob => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        a.click();
    }).catch(() => {
        result.isSuccess = false
    });

    return result;
}

export const CallAPIWithFile = async (url, body, accessToken) => {

    const result = {
        isSuccess: false,
    }

    await fetch(process.env.REACT_APP_API_URL + url, {
        method: 'POST',
        headers: {
            'Authorization': accessToken
        },
        credentials: 'include',
        body: body,
    }).then((response) => {
        result.isSuccess = response.ok;
    }).catch(() => {
        result.isSuccess = false
    });

    return result;
}