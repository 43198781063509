import React, {useContext, useRef} from "react";
import Input from "../../form/input";
import TextArea from "../../form/textArea";
import PrimaryButton from "../../buttons/primaryButton";
import {CallAPIWithoutResponse} from "../../../api/API";
import {AppContext} from "../../../context/AppContext";
import {getSlides} from "./Functions";
// todo refactor
const DetailsSlider = ({slide, setSlides, setActiveDetails}) => {

    const {user, setError} = useContext(AppContext);
    const titleRef = useRef("");
    const titleColorRef = useRef("");
    const contentRef = useRef("");
    const contentColorRef = useRef("");
    const linkRef = useRef("");
    const altRef = useRef("");

    const updateSlide = async () => {
        const body = {
            id: slide?.id,
            imageName: slide?.imageName,
            imageAlt: altRef.current.value,
            title: titleRef.current.value,
            titleColor: titleColorRef.current.value,
            content: contentRef.current.value,
            contentColor: contentColorRef.current.value,
            link: linkRef.current.value,
            delay: slide?.delay
        }
        const result = await CallAPIWithoutResponse('/slide', body, 'PUT', user.accessToken)
        if (result.isSuccess) {
            await getSlides(() => {}, setError, setSlides, user.accessToken, setActiveDetails)
        } else {
            setError({
                show: true,
                title: "Bląd zapisania",
                message: "Błąd podczas zapisywania slidera"
            })
        }
    };

    return (
        <>
            <div className="mb-3">
                <Input id={'title'} label={'Tytuł'} type={'text'}
                       require={true} reference={titleRef} value={slide?.title}/>
            </div>
            <div className="mb-3">
                <Input id={'titleColor'} label={'Tytuł kolor (hex)'} type={'text'}
                       require={true} reference={titleColorRef} value={slide?.titleColor}/>
            </div>
            <div className="mb-3">
                <TextArea label={'Treść'} id={'Treść'} rows={4} reference={contentRef} defaultValue={slide?.content}/>
            </div>
            <div className="mb-3">
                <Input id={'content'} label={'Treść kolor (hex)'} type={'text'}
                       require={true} reference={contentColorRef} value={slide?.contentColor}/>
            </div>
            <div className="mb-3">
                <Input id={'Link'} label={'Link'} type={'text'}
                       require={true} reference={linkRef} value={slide?.link}/>
            </div>
            <div className="mb-3">
                <Input id={'ALT grafiki'} label={'ALT grafiki'} type={'text'}
                       require={true} reference={altRef} value={slide?.imageAlt}/>
            </div>
            <PrimaryButton label={'Zapisz'}
                           onClickFunction={() => updateSlide()}/>
        </>
    );
};

export default DetailsSlider;