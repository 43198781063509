import {CallAPIWithoutResponse} from "../../../../api/API";
// todo refactor
export const addTemplateImage = async ({accessToken, setError, body, setUpdate}) => {
    const result = await CallAPIWithoutResponse('/imageTemplate/add', body, 'POST', accessToken)
    if (result.isSuccess) {
        setUpdate(true)
    } else {
        setError({
            show: true,
            title: "Bląd dodawania",
            message: "Błąd dodawania nowego szablonu"
        })
    }
}