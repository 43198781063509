import React, {useContext, useEffect, useRef, useState} from "react";
import FeatherIcon from "feather-icons-react";
import Input from "../../../form/input";
import Select from "../../../form/select";
import PrimaryButton from "../../../buttons/primaryButton";
import {useApi} from "../../../../hooks/useApi";
import {ImageTemplateContext} from "../../../../context/ImageTemplateContext";
// todo refactor
const TemplateText = ({templateTexts, imageTemplateId}) => {

    const {setUpdate} = useContext(ImageTemplateContext)

    const [textRows, setTextRows] = useState([])
    const [isEdit, setEdit] = useState(false)
    const [editText, setEditText] = useState(null)

    const textRef = useRef('');
    const maxLengthRef = useRef('');
    const fontRef = useRef('');
    const widthRef = useRef('');
    const colorRef = useRef('');
    const topRef = useRef('');
    const onLeftRef = useRef('');
    const fontSizeRef = useRef('');

    const {postApi, putApi, deleteApi} = useApi()

    const onClickEdit = (text) => {
        setEdit(true)
        setEditText(text)
        textRef.current.value = text.text
        maxLengthRef.current.value = text.maxLength
        fontRef.current.value = text.font
        widthRef.current.value = text.width
        colorRef.current.value = text.color
        topRef.current.value = text.top
        onLeftRef.current.value = text.onLeft
        fontSizeRef.current.value = text.fontSize
    }

    const onClickCancel = () => {
        setEdit(false)
        textRef.current.value = null
        maxLengthRef.current.value = null
        fontRef.current.value = null
        widthRef.current.value = null
        colorRef.current.value = null
        topRef.current.value = null
        onLeftRef.current.value = null
        fontSizeRef.current.value = null
    }

    useEffect(() => {
        const texts = []
        templateTexts.forEach(text => {
            texts.push(
                <tr key={text.id}>
                    <td>{text.text}</td>
                    <td>
                        <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Zobacz szczegóły"
                                onClick={() => onClickEdit(text)}
                        >
                            <FeatherIcon icon="eye"/>
                        </button>
                        <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Usuń"
                                onClick={
                                    async () => {
                                        await deleteApi('/templateText/' + text.id + '/' + imageTemplateId)
                                        setUpdate(true)
                                    }
                                }
                        >
                            <FeatherIcon icon="trash"/>
                        </button>
                    </td>
                </tr>
            )
        })
        setTextRows(texts)
    }, [templateTexts])

    return (
        <>
            {isEdit ?
            null
                :
             <>
                 <h3>Teksty na szablonie</h3>
                 <div className="table-responsive">
                     <table className="table table-striped table-hover">
                         <thead>
                         <tr>
                             <th>Tekst</th>
                             <th>Akcje</th>
                         </tr>
                         </thead>
                         <tbody>
                         {textRows}
                         </tbody>
                     </table>
                 </div>
             </>
            }
            <div className="mb-3">
                <Input id={'text'} label={'Tekst'} type={'text'}
                       require={true} reference={textRef}/>
            </div>
            <div className="mb-3">
                <Input id={'maxLength'} label={'Maksymalna ilość znaków'} type={'number'}
                       require={true} reference={maxLengthRef}/>
            </div>
            <div className="mb-3">
                <Select id={'font'} label={'Czcionka'} reference={fontRef}
                        require={true}
                        optionsData={[
                            'aleo',
                            'anonymous',
                            'caviarDreams',
                            'ecatherina',
                            'greatVibes',
                            'monospace',
                            'neuropol',
                            'nunito',
                            'sansSerif',
                            'takeCover',
                            'topSecret'
                        ]}/>
            </div>
            <div className="mb-3">
                <Input id={'width'} label={'Szerokość pola z tekstem'} type={'number'}
                       require={true} reference={widthRef}/>
            </div>
            <div className="mb-3">
                <Input id={'color'} label={'Kolor'} type={'text'}
                       require={true} reference={colorRef}/>
            </div>
            <div className="mb-3">
                <Input id={'top'} label={'Pozycja od góry'} type={'number'}
                       require={true} reference={topRef}/>
            </div>
            <div className="mb-3">
                <Input id={'onLeft'} label={'Pozycja od lewej'} type={'number'}
                       require={true} reference={onLeftRef}/>
            </div>
            <div className="mb-3">
                <Input id={'fontSize'} label={'Rozmiar czcionki'} type={'number'}
                       require={true} reference={fontSizeRef}/>
            </div>
            <PrimaryButton label={isEdit ? 'Zapisz' : 'Dodaj tekst'}
                           onClickFunction={
                               async () => {
                                   const body = {
                                       id: isEdit ? editText.id : null,
                                       text: textRef.current.value,
                                       maxLength: maxLengthRef.current.value,
                                       font: fontRef.current.value,
                                       width: widthRef.current.value,
                                       color: colorRef.current.value,
                                       top: topRef.current.value,
                                       onLeft: onLeftRef.current.value,
                                       fontSize: fontSizeRef.current.value
                                   }
                                   if (isEdit) {
                                       await putApi('/templateText', body)
                                       onClickCancel()
                                   } else {
                                       await postApi('/templateText/' + imageTemplateId, body)
                                   }
                                   setUpdate(true)
                               }
                           }
            />
            {isEdit ?
                <button className="btn btn-cyan-soft m-lg-2"
                        type="button"
                        onClick={() => onClickCancel()}>
                    Anuluj
                </button>
                :
                null
            }
        </>
    )
};

export default TemplateText;