import React from "react";

const Background = ({children}) => {

    return (
        <div className="modal-backdrop modal-background">
            {children}
        </div>
    )
};

export default Background;