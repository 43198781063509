import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../../../context/AppContext";
import {ImageTemplateContext} from "../../../../context/ImageTemplateContext";
import {deleteImageTemplate, getImageCategory, updateTemplateImage} from "./functions";
import DangerButton from "../../../buttons/dangerButton";
import Select from "../../../form/select";
import PrimaryButton from "../../../buttons/primaryButton";
// todo refactor
const ConfigTab = () => {

    const {user, setError} = useContext(AppContext);
    const {id, setUpdate, category, subCategory} = useContext(ImageTemplateContext)

    const categoryRef = useRef('')
    const subCategoryRef = useRef('')

    const [categoriesData, setCategoriesData] = useState([])
    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])

    useEffect(() => {
        void getImageCategory({setError: setError, setCategories: setCategories, setCategoriesData: setCategoriesData})
    }, [])

    useEffect(() => {
        const subCat = ['']
        categoriesData.forEach(categoryData => {
            if (categoryData.link === category) {
                categoryData.imageSubCategories.forEach(sub => {
                    subCat.push(sub.link)
                })
            }
        })
        setSubCategories(subCat)
    }, [categoriesData])

    const onChangeCategory = () => {
        const subCat = ['']
        categoriesData.forEach(categoryData => {
            if (categoryData.link === categoryRef.current.value) {
                categoryData.imageSubCategories.forEach(sub => {
                    subCat.push(sub.link)
                })
            }
        })
        setSubCategories(subCat)
    }

    return (
        <>
            <DangerButton label={'Usuń szablon'}
                          onClickFunction={
                              () => deleteImageTemplate(
                                  {
                                      id: id,
                                      setError: setError,
                                      accessToken: user.accessToken,
                                      setUpdate: setUpdate
                                  }
                              )}
            />
            <hr/>
            <h5>Wybierz dostępność szablonu w kategoriach i podkategoriach</h5>
            <div className="mb-3">
                <Select id={'category'} label={'Kategoria'} reference={categoryRef}
                        require={true} value={category}
                        optionsData={categories}
                        onChange={onChangeCategory}
                />
            </div>
            <div className="mb-3">
                <Select id={'subCategory'} label={'Podkategoria'} reference={subCategoryRef}
                        require={true} value={subCategory}
                        optionsData={subCategories}/>
            </div>
            <PrimaryButton label={'Zapisz'}
                           onClickFunction={
                               () => updateTemplateImage(
                                   {
                                       url: 'updateConfig',
                                       id: id,
                                       setError: setError,
                                       accessToken: user.accessToken,
                                       body: {
                                           category: categoryRef.current.value,
                                           subCategory: subCategoryRef.current.value,
                                       }
                                   }
                               )}/>
        </>
    )
}

export default ConfigTab;