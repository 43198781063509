import React, {useState} from "react";
import NavBar from "../../components/navBar";
import SideBar from "../../components/sideBar";
import PageHeader from "../../components/pageHeader";
import AppError from "../../components/alerts/appError";
import SiteEntries from "../../components/tabs/siteEntries";
import Administration from "../../components/tabs/administration";
import MainPage from "../../components/tabs/mainPage";
import ImagesPage from "../../components/tabs/imagesPage";
import AboutPage from "../../components/tabs/aboutPage";
import ContactPage from "../../components/tabs/contactPage";
import ImageTemplate from "../../components/tabs/imageTemplate";
import Orders from "../../components/tabs/orders";
import Footer from "../../components/tabs/footer/Footer";
import BasketPage from "../../components/tabs/basketPage";
import Project from "../../components/tabs/project/Project";
import BlogPage from "../../components/tabs/blogPage/BlogPage";
import BlogEntry from "../../components/tabs/blogEntry/BlogEntry";
import Discount from "../../components/tabs/discount/Discount";

const Application = () => {

    const [activeTab, setActiveTab] = useState('Pulpit')

    return (
        <>
            <NavBar/>
            <div id="layoutSidenav">
                <SideBar setActiveTab={setActiveTab}/>
                <div id="layoutSidenav_content">
                    <main>
                        <PageHeader activeTab={activeTab}/>
                        {
                            {
                                'Pulpit': null,
                                'Strona główna': <MainPage/>,
                                'Obrazy': <ImagesPage/>,
                                'Projekt': <Project/>,
                                'Szablony obrazów': <ImageTemplate/>,
                                'Blog': <BlogPage/>,
                                'Wpisy na blogu': <BlogEntry/>,
                                'O nas': <AboutPage/>,
                                'Kontakt': <ContactPage/>,
                                'Koszyk' : <BasketPage/>,
                                'Stopka': <Footer/>,
                                'Rabaty': <Discount/>,
                                'Wejścia na stronę': <SiteEntries/>,
                                'Zamówienia' : <Orders/>,
                                'Administracja': <Administration/>,
                            }[activeTab]
                        }
                    </main>
                </div>
            </div>
            <AppError/>
        </>
    )
}

export default Application;