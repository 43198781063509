import React, {useContext, useRef} from "react";
import {AppContext} from "../../../../context/AppContext";
import {addTemplateImage} from "./functions";
import Card from "../../../cards/card";
import PrimaryButton from "../../../buttons/primaryButton";
import Select from "../../../form/select";
import Input from "../../../form/input";
// todo refactor
const AddImageTemplateBox = ({setUpdate}) => {

    const {user, setError} = useContext(AppContext);

    const typeRef = useRef('')
    const nameRef = useRef('')

    return (
        <Card cardName={"Dodaj nowy szablon"}
              size={3}
        >
            <div className="mb-3">
                <Input label={'Nazwa'}
                       type={'text'}
                       reference={nameRef}
                       id={'name'}
                />
            </div>
            <div className="mb-3">
                <Select id={'type'}
                        label={'Typ szablonu'}
                        reference={typeRef}
                        optionsData={['Własne zdjęcie', 'Grafika', 'Nieregularna grafika', 'Wiele grafik']}
                />
            </div>
            <PrimaryButton label={'Dodaj'}
                           onClickFunction={() => addTemplateImage({
                               accessToken: user.accessToken,
                               setError: setError,
                               body: {
                                   type: typeRef.current.value,
                                   name: nameRef.current.value
                               },
                               setUpdate: setUpdate,
                           })}
            />
        </Card>
    )
}

export default AddImageTemplateBox;