import React from "react";
import Row from "../layout/Row";

const TabWrapper = ({children}) => {

    return (
        <div className="container-xl px-4 mt-5">
            <Row>
                {children}
            </Row>
        </div>
    )
};

export default TabWrapper;