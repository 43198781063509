import React, {useContext, useEffect, useState} from "react";
import Spinner from "../../spinner";
import {CallSecurityAPI} from "../../../api/API";
import {AppContext} from "../../../context/AppContext";
import AddNewContentSection from "./AddNewContentSection";
import Contents from "./Contents";
// todo refactor
const MainPageContent = () => {

    const {user, setError} = useContext(AppContext);

    const [isLoad, setLoad] = useState(true);
    const [data, setData] = useState([])

    const getContent = async () => {
        const result = await CallSecurityAPI('/mainPageContent', null, 'GET', user.accessToken)
        if (result.isSuccess) {
            setData(result.data)
        } else {
            setError({
                show: true,
                title: "Bląd pobierania",
                message: "Błąd pobierania treści"
            })
        }
        setLoad(false)
    }

    useEffect(() => {
        void getContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="col-lg-10 mb-4">
            <div className="card card-header-actions">
                <div className="card-header">
                    Dodatkowa treść strony
                </div>
                <div className="card-body d-flex flex-column justify-content-center">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        <>
                            <Contents data={data} getContent={getContent}/>
                            <hr/>
                            <AddNewContentSection getContent={getContent}/>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default MainPageContent;