import {CallAPI, CallAPIWithoutResponse} from "../../../api/API";
// todo refactor
export const getRealization = async (setLoad, setError, setTime, setPackageData, setSend) => {
    const result = await CallAPI('/realization/all', null, 'GET')
    if (result.isSuccess) {
        result.data.forEach(realization => {
            if (realization.type === 'time') {
                setTime({
                    title: realization.title,
                    desc: realization.description
                })
            } else if (realization.type === 'packaging') {
                setPackageData({
                    title: realization.title,
                    desc: realization.description
                })
            } else if (realization.type === 'send') {
                setSend({
                    title: realization.title,
                    desc: realization.description
                })
            }
        })
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania danych realizacji"
        })
    }
    setLoad(false)
}

export const saveRealization = async (accessToken, setError, timeTitle, timeDesc, packageTitle, packageDesc, sendTitle, sendDesc) => {

    const bodyTime = {
        type: 'time',
        title: timeTitle,
        description: timeDesc,
    }

    const bodyPackage = {
        type: 'packaging',
        title: packageTitle,
        description: packageDesc,
    }

    const bodySend = {
        type: 'send',
        title: sendTitle,
        description: sendDesc,
    }

    const resultTime = await CallAPIWithoutResponse('/realization/add', bodyTime, 'POST', accessToken)
    const resultPackage = await CallAPIWithoutResponse('/realization/add', bodyPackage, 'POST', accessToken)
    const resultSend = await CallAPIWithoutResponse('/realization/add', bodySend, 'POST', accessToken)
    if (!resultTime.isSuccess || !resultPackage.isSuccess || !resultSend.isSuccess) {
        setError({
            show: true,
            title: "Bląd zapisania",
            message: "Błąd podczas zapisywania realizacji"
        })
    }
}