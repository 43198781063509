import React, {useContext} from "react";
import {FileInfoContext} from "./FileInfoContext";
// todo refactor
const Header = () => {

    const {header} = useContext(FileInfoContext);

    return (
        <h6>{header}</h6>
    );
};

export default Header;