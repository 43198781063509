import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../../context/AppContext";
import PrimaryButton from "../../buttons/primaryButton";
import Spinner from "../../spinner";
import Input from "../../form/input";
import Select from "../../form/select";
import TextArea from "../../form/textArea";
import {getImageCategory, getImageSubCategory, updateImageCategory, updateImageSubCategory} from "./Functions";
import {ImageCardContext} from "../../../context/ImageCardContext";
// todo refactor
const BaseDataTab = ({isSubCategory}) => {

    const {activeCategory, activeSubCategory} = useContext(ImageCardContext);
    const {user, setError} = useContext(AppContext);

    const nameRef = useRef("");
    const linkRef = useRef("");
    const titleRef = useRef("");
    const metaDescriptionRef = useRef("");
    const metaKeywordsRef = useRef("");
    const metaRobotsRef = useRef("");
    const linkCanonicalRef = useRef("");
    const siteImageRef = useRef("");
    const openGraphRef = useRef("");

    const [isLoad, setLoad] = useState(true);
    const [data, setData] = useState({})

    useEffect(() => {
        if (isSubCategory) {
            void getImageSubCategory(activeSubCategory.id, setLoad, setError, setData)
        } else {
            void getImageCategory(activeCategory.id, setLoad, setError, setData)
        }
    }, [])

    return (
        <>
            {isLoad ?
                <div className='d-flex justify-content-center'>
                    <Spinner/>
                </div>
                :
                <>
                    <div className="mb-3">
                        <Input id={'name'} label={'Nazwa'} type={'text'}
                               reference={nameRef} require={true} value={data.name}/>
                    </div>
                    <div className="mb-3">
                        <Input id={'link'} label={'Link'} type={'text'}
                               reference={linkRef} require={true} value={data.link}/>
                    </div>
                    <div className="mb-3">
                        <Input id={'title'} label={'Tytuł'} type={'text'}
                               reference={titleRef} require={true} value={data.title}/>
                    </div>
                    <div className="mb-3">
                        <Input id={'metaDescription'} label={'Metadane - opis'} type={'text'}
                               reference={metaDescriptionRef} require={true} value={data.metaDescription}/>
                    </div>
                    <div className="mb-3">
                        <Input id={'metaKeywords'} label={'Metadane - słowa kluczowe'} type={'text'}
                               reference={metaKeywordsRef} require={true} value={data.metaKeywords}/>
                    </div>
                    <div className="mb-3">
                        <Select id={'metaRobots'} label={'Metadane - robots'} reference={metaRobotsRef}
                                require={true} value={data.metaRobots}
                                optionsData={['', 'index,follow', 'noindex,follow', 'noindex,nofollow', 'index,nofollow']}/>
                    </div>
                    <div className="mb-3">
                        <Input id={'linkCanonical'} label={'Kanoniczny URL'} type={'text'}
                               reference={linkCanonicalRef} require={true} value={data.linkCanonical}/>
                    </div>
                    <div className="mb-3">
                        <Input id={'siteImage'} label={'Site image'} type={'text'}
                               reference={siteImageRef} require={true} value={data.siteImage}/>
                    </div>
                    <div className="mb-3">
                        <TextArea id={'openGraph'} label={'Open Graph'} reference={openGraphRef}
                                  defaultValue={data.openGraph} require={true} rows={8}/>
                    </div>
                    {isSubCategory ?
                        <PrimaryButton label={'Zapisz'}
                                       onClickFunction={
                                           () =>
                                               updateImageSubCategory(
                                                   user.accessToken,
                                                   setError,
                                                   activeSubCategory.id,
                                                   nameRef.current.value,
                                                   linkRef.current.value,
                                                   titleRef.current.value,
                                                   metaDescriptionRef.current.value,
                                                   metaKeywordsRef.current.value,
                                                   metaRobotsRef.current.value,
                                                   linkCanonicalRef.current.value,
                                                   siteImageRef.current.value,
                                                   openGraphRef.current.value,
                                               )}
                        />
                        :
                        <PrimaryButton label={'Zapisz'}
                                       onClickFunction={
                                           () =>
                                               updateImageCategory(
                                                   user.accessToken,
                                                   setError,
                                                   activeCategory.id,
                                                   nameRef.current.value,
                                                   linkRef.current.value,
                                                   titleRef.current.value,
                                                   metaDescriptionRef.current.value,
                                                   metaKeywordsRef.current.value,
                                                   metaRobotsRef.current.value,
                                                   linkCanonicalRef.current.value,
                                                   siteImageRef.current.value,
                                                   openGraphRef.current.value,
                                               )}
                        />
                    }
                </>
            }
        </>
    )
}

export default BaseDataTab;