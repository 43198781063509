import React from "react";
import ImageCategoriesCard from "../../cards/imageCategoriesCard";
import MainContentCard from "../../cards/mainContentCard";
import ExtraContentCard from "../../cards/extraContentCard";
import FrameCard from "../../cards/frameCard";
import TabWrapper from "../TabWrapper";
import TextBoxCard from "../../cards/textBoxCard/TextBoxCard";
import BasicPageDataCard from "../../cards/basicPageDataCard/BasicPageDataCard";

const ImagesPage = () => {

    return (
        <TabWrapper>
            <BasicPageDataCard page={'TEMPLATES_PAGE'}/>
            <MainContentCard/>
            <ExtraContentCard/>
            <TextBoxCard page={'IMAGE'}/>
            <ImageCategoriesCard/>
            <FrameCard/>
        </TabWrapper>
    )
}

export default ImagesPage;