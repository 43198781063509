import {CallAPIWithoutResponse} from "../../api/API";

export const toggleSideBar = () => {
    document.body.classList.toggle('sidenav-toggled');
    localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sidenav-toggled'));
}
// todo refactor
export const logout = async (setLogout, setUser, setError) => {
    setLogout(true)
    const result = await CallAPIWithoutResponse('/logoutapi', null, 'GET')
    if (result.isSuccess) {
        setUser({
            logged: false,
            accessToken: null,
        })
    } else {
        setError({
            show: true,
            title: "Bląd wylogowania",
            message: "Błąd podczas wylogowywania"
        })
    }
    setLogout(false)
}