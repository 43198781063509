import React from "react";

const Header = ({title}) => {

    return (
        <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
        </div>
    )
};

export default Header;