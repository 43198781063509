import React, {useState} from "react";
import Card from "../../cards/card";
import {useApi} from "../../../hooks/useApi";
import FeatherIcon from "feather-icons-react";
import OrderDetails from "./OrderDetails";
import DeleteOrderModal from "./DeleteOrderModal";
import TabWrapper from "../TabWrapper";
// todo refactor
const Orders = () => {

    const {getApi} = useApi()

    const [ordersList, setOrdersList] = useState([]);
    const [showOrder, setShowOrder] = useState(false);
    const [activeOrder, setActiveOrder] = useState({})
    const [orderToDelete, setOrderToDelete] = useState(null)

    const onStart = async () => {
        const result = await getApi('/approvedOrder/all')
        if (result.isSuccess) {
            let orders = []
            result.data.forEach(order => {
                orders.push(
                    <tr key={order.id}>
                        <td>{order.id}</td>
                        <td>
                            {order.email}<br/>
                            {order.deliveryName + ' ' + order.deliverySurname} <br/>
                            {order.deliveryCompanyName ? order.deliveryCompanyName : null}
                        </td>
                        <td>{order.orderDate}</td>
                        <td>
                            {order.express ?
                                <span className="link-red">express 48h</span>
                                :
                                <span className="badge badge-light text-body">standard 96h</span>}
                        </td>
                        <td>{order.totalOrdersPrice} zł</td>
                        <td>
                            {
                                {
                                    'waiting for payment': <span className="link-blue">Oczekuje na płatność</span>,
                                    'paid':  <span className="link-green">Zapłacone</span>,
                                    'send':  'Wysłane',
                                    'close': 'Zamknięte',
                                }[order.status]
                            }
                        </td>
                        <td>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Zobacz szczegóły"
                                    onClick={() => onClickShowMore(order)}
                            >
                                <FeatherIcon icon="eye"/>
                            </button>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="modal"
                                    data-bs-placement="top"
                                    data-bs-target="#deleteOrderModal"
                                    title="Usuń"
                                    onClick={() => setOrderToDelete(order.id)}
                            >
                                <FeatherIcon icon="delete"/>
                            </button>
                        </td>
                    </tr>
                )
            })
            setOrdersList(orders)
        }
    }

    const onClickShowMore = (order) => {
        setShowOrder(true)
        setActiveOrder(order)
    }

    return (
        <TabWrapper>
            <DeleteOrderModal orderToDelete={orderToDelete} onApprove={onStart}/>
            <Card cardName={showOrder ? 'Zamówienie id: ' + activeOrder.id : 'Lista zamówień'}
                  size={12}
                  onStart={onStart}
                  showHeaderButton={showOrder}
                  buttonLabel={'Wstecz'}
                  onClickButton={() => setShowOrder(false)}
            >
                {showOrder ?
                    <OrderDetails activeOrder={activeOrder}/>
                    :
                    <div className='table-responsive'>
                        <table className="table table-striped table-hover">
                            <thead>
                            <tr>
                                <th>id zamówienia</th>
                                <th>Dane Klienta</th>
                                <th>Data zamówienia</th>
                                <th>Realizacja</th>
                                <th>Kwota</th>
                                <th>Status</th>
                                <th>Akcje</th>
                            </tr>
                            </thead>
                            <tbody>
                            {ordersList}
                            </tbody>
                        </table>

                    </div>
                }
            </Card>
        </TabWrapper>
    );

};

export default Orders;