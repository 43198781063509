import React, {useEffect, useState} from "react";
import Modal from "../../modal/Modal";
import {useApi} from "../../../hooks/useApi";
import Row from "../../layout/Row";
import Column from "../../layout/Column";
import Input from "../../form/input";
import TextArea from "../../form/textArea";
import Space from "../../layout/Space";
import {getMessage} from "./EmailMessage"

const SendEmailChangeStatusModal = ({show, setShow, status, order}) => {

    const {postFormData, postApi} = useApi();

    const defaultSubject = "Zmiana statusu Twojego zamówienia"
    const [invoiceLink, setInvoiceLink] = useState("")
    const [sendLink, setSendLink] = useState("")
    const defaultMessage = getMessage(order, invoiceLink, sendLink)
    const [recipient, setRecipient] = useState(order.email)
    const [subject, setSubject] = useState(defaultSubject)
    const [message, setMessage] = useState(defaultMessage)

    useEffect(() => {
        setMessage(getMessage(order, invoiceLink, sendLink))
    }, [invoiceLink, order, sendLink])

    const onApprove = async () => {
        const body = new FormData();
        body.append('status',
            {
                'Oczekuje na płatność': 'waiting for payment',
                'Zapłacone': 'paid',
                'Wysłane': 'send',
                'Zamknięte': 'close',
            }[status]
        )
        void await postFormData('/approvedOrder/status/' + order.id, body)
        const emailBody = {
            recipient: recipient,
            subject: subject,
            message: message
        }
        void await postApi('/emails/send', emailBody)
        setShow(false)
    };

    return (
        show &&
        <Modal title={"Wyślij email"}
               onCancel={() => setShow(false)}
               onApprove={onApprove}>
            <Row>
                <Column>
                    <Input label={'Email'} type={'text'} value={order.email}
                           onChange={(e) => setRecipient(e.target.value)}/>
                </Column>
            </Row>
            <Space/>
            <Row>
                <Column>
                    <Input label={'Tytuł'} type={'text'} onChange={(e) => setSubject(e.target.value)}
                           value={defaultSubject}/>
                </Column>
            </Row>
            <Space/>
            {status === 'Wysłane' ?
                <>
                    <Row>
                        <Column>
                            <Input label={'Link do FV'} type={'text'} onChange={(e) => setInvoiceLink(e.target.value)}
                                   value={""}/>
                        </Column>
                    </Row>
                    <Space/>
                    <Row>
                        <Column>
                            <Input label={'Link do wysyłki'} type={'text'} onChange={(e) => setSendLink(e.target.value)}
                                   value={""}/>
                        </Column>
                    </Row>
                    <Space/>
                </>
                :
                null
            }
            <Row>
                <Column>
                    <TextArea label={'Treść'} rows={8} onChange={(e) => setMessage(e.target.value)}
                              value={message}/>
                </Column>
            </Row>
            <Space/>
        </Modal>
    )
};

export default SendEmailChangeStatusModal;