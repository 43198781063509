import React, {useContext, useState} from "react";
import {AppContext} from "../../context/AppContext";
import {logout, toggleSideBar} from "./Functions";
import FeatherIcon from "feather-icons-react";
import profileImage from "../../assets/img/illustrations/profiles/profile-2.png";
// todo refactor
const NavBar = () => {

    const {setUser, setError} = useContext(AppContext);
    const [isLogout, setLogout] = useState(false);

    return (
        <nav
            className="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white"
            id="sidenavAccordion">
            <button className="btn btn-icon btn-transparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0"
                    id="sidebarToggle"
                    onClick={() => toggleSideBar()}>
                <FeatherIcon icon="menu"/>
            </button>
            <p className="navbar-brand pe-3 ps-4 ps-lg-2">Printini CMS</p>
            <ul className="navbar-nav align-items-center ms-auto">
                <li className="nav-item dropdown no-caret dropdown-user me-3 me-lg-4">
                    <div className="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage"
                         role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                         aria-expanded="false">
                        {isLogout ?
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            :
                            <img className="img-fluid" src={profileImage} alt={'profile image'}/>
                        }
                    </div>
                    <div className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up"
                         aria-labelledby="navbarDropdownUserImage">
                        <h6 className="dropdown-header d-flex align-items-center">
                            <img className="dropdown-user-img"
                                 src={profileImage} alt={'profile image'}/>
                            <div className="dropdown-user-details">
                                <div className="dropdown-user-details-name">Admin</div>
                            </div>
                        </h6>
                        <div className="dropdown-divider"></div>
                        <div className="dropdown-item" onClick={() => logout(setLogout, setUser, setError)}>
                            <div className="dropdown-item-icon">
                                <FeatherIcon icon="log-out"/>
                            </div>
                            Wyloguj
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default NavBar;