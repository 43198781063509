import React, {useContext, useRef} from "react";
import {AppContext} from "../../../context/AppContext";
import {deleteExtraContent, saveExtraContent} from "./Functions";
import Input from "../../form/input";
import TextArea from "../../form/textArea";
import PrimaryButton from "../../buttons/primaryButton";
import DangerButton from "../../buttons/dangerButton";
// todo refactor
const ExtraContentBox = ({data, setData, setLoad}) => {

    const {user, setError} = useContext(AppContext);

    const headerRef = useRef("");
    const contentRef = useRef("")

    return (
        <>
            <div className="mb-3">
                <Input id={'header'} label={'Tytuł + nagłówek (h1, h2, h3...)'} type={'text'}
                       reference={headerRef} require={true} value={data.header}/>
            </div>
            <div className="mb-3">
                <TextArea id={'content'} label={'Treść'} reference={contentRef}
                          defaultValue={data.content} require={true} rows={12}/>
            </div>
            <div className='d-flex'>
                <div className='m-1'>
                    <PrimaryButton label={'Zapisz'}
                                   onClickFunction={() =>
                                       saveExtraContent(
                                           user.accessToken,
                                           setError,
                                           data.id,
                                           headerRef.current.value,
                                           contentRef.current.value
                                       )}/>
                </div>
                <div className='m-1'>
                    <DangerButton label={'Usuń'}
                                  onClickFunction={() =>
                                      deleteExtraContent(
                                          user.accessToken,
                                          setLoad,
                                          setError,
                                          data,
                                          setData
                                      )
                                  }/>
                </div>
            </div>
            <hr/>
        </>
    )
}

export default ExtraContentBox;