import React from "react";
// todo refactor
const FileInput = ({id, label, reference, require, onChange}) => {

    return (
        <>
            <label htmlFor={id}
                   className="form-label">
                {label}
            </label>
            <input className="form-control form-control-sm"
                   id={id}
                   type="file"
                   placeholder={label}
                   ref={reference}
                   required={require}
                   onChange={onChange}
            />
        </>
    )
}

export default FileInput;