import React from "react";
import FeatherIcon from "feather-icons-react";
import {Col, Row} from "react-bootstrap";

const UploadFile = ({file, label, onChange, onDelete}) => {

    return (
        file ?
            <div>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <h6>{label}</h6>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <p className='mt-3 p-1'>{file.fileName}</p>
                        <button className="btn btn-transparent-dark" type="button" onClick={onDelete}>
                            <FeatherIcon icon="trash"/>
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <img className='img-fluid col-2'
                             src={'/files/' + file.fileId + file.extension}
                             alt='Podgląd grafiki'
                        />
                    </Col>
                </Row>
            </div>
            :
            <div>
                <label htmlFor={file?.fileId}
                       className="form-label">
                    {label}
                </label>
                <input className="form-control form-control-sm"
                       id={file?.fileId}
                       type="file"
                       placeholder={label}
                       onChange={onChange}
                />
            </div>
    )
};

export default UploadFile;