import React from "react";
import Card from "../card";
import DiscountRecord from "./DiscountRecord";

const DiscountsList = ({discounts, getDiscounts}) => {

    return (
        <Card cardName="Rabaty">
            {discounts.map((discount) => {
                return <DiscountRecord
                    key={discount.id}
                    discount={discount}
                    getDiscounts={getDiscounts}
                />
            })}
        </Card>
    )
};

export default DiscountsList;