import React, {useContext, useEffect, useRef, useState} from "react";
import {getImageCategories, saveImageCategory} from "./Functions";
import Input from "../../form/input";
import PrimaryButton from "../../buttons/primaryButton";
import Spinner from "../../spinner";
import {AppContext} from "../../../context/AppContext";
import {ImageCardContext} from "../../../context/ImageCardContext";
// todo refactor
const CategoryView = () => {

    const {user, setError} = useContext(AppContext);
    const {setActiveView, setActiveCategory, isProject} = useContext(ImageCardContext);

    const [isLoad, setLoad] = useState(true);
    const [categories, setCategories] = useState([]);

    const nameRef = useRef("");
    const linkRef = useRef("");

    useEffect(() => {
        void getImageCategories(setLoad, setError, setCategories, user.accessToken, setActiveView, setActiveCategory, isProject)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {isLoad ?
                <div className='d-flex justify-content-center'>
                    <Spinner/>
                </div>
                :
                <>
                    <div className="table-responsive">
                        <table className="table table-striped table-hover">
                            <thead>
                            <tr>
                                <th>Nazwa</th>
                                <th>Link</th>
                                <th>Akcje</th>
                            </tr>
                            </thead>
                            <tbody>
                            {categories}
                            </tbody>
                        </table>
                    </div>
                    <div className="mb-3">
                        <Input id={'name'} label={'Nazwa'} type={'text'}
                               require={true} reference={nameRef}/>
                    </div>
                    <div className="mb-3">
                        <Input id={'Link'} label={'Link'} type={'text'}
                               require={true} reference={linkRef}/>
                    </div>
                    <PrimaryButton label={'Zapisz'}
                                   onClickFunction={
                                       () => saveImageCategory(
                                           user.accessToken,
                                           setError,
                                           setLoad,
                                           setCategories,
                                           setActiveView,
                                           setActiveCategory,
                                           nameRef.current.value,
                                           linkRef.current.value,
                                           isProject
                                       )}
                    />
                </>
            }
        </>
    )
}

export default CategoryView;