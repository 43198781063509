import React, {useEffect, useState} from "react";
import Spinner from "../../spinner";
// todo refactor
const CardCollapse = ({children, cardName, descripton, size, onStart}) => {

    const [isLoad, setLoad] = useState(true);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (onStart !== undefined) {
            onStart()
        }
        setLoad(false)
    }, [])

    return (
        <div className={"col-lg-" + size + " mb-4"}>
            <div className="card card-collapsable">
                <a className="card-header" href="#collapseCard" data-bs-toggle="collapse" role="button"
                   aria-expanded="true" aria-controls="collapseCard"
                   onClick={() => setShow(!show)}
                >
                    {cardName}
                    {descripton ? <p className="text-black-50">{descripton}</p> : null}
                    <div className="card-collapsable-arrow">
                        <i className="fas fa-chevron-down"/>
                    </div>
                </a>
                <div className={show ? "collapse show" : "collapse"} id="collapseCardExample">
                    <div className="card-body d-flex flex-column justify-content-center">
                        {isLoad ?
                            <div className='d-flex justify-content-center'>
                                <Spinner/>
                            </div>
                            :
                            <>
                                {children}
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardCollapse;