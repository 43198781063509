import React, {useEffect, useState} from "react";
import TabWrapper from "../TabWrapper";
import BlogEntryAddCard from "../../cards/BlogEntryCard/BlogEntryAddCard";
import {useApi} from "../../../hooks/useApi";
import BlogEntryListCard from "../../cards/BlogEntryCard/BlogEntryListCard";
import BasicPageDataCard from "../../cards/basicPageDataCard/BasicPageDataCard";
import BlogEntryCard from "../../cards/BlogEntryCard/BlogEntryCard";
import BlogEntryData from "../../cards/BlogEntryCard/BlogEntry";

const BlogEntry = () => {

    const [blogData, setBlogData] = useState([])
    const [activeEntry, setActiveEntry] = useState(null)

    const {getApi} = useApi()

    const getBlogData = async () => {
        const result = await getApi('/guiComponent/BLOG/BLOG_DATA')
        if (result.isSuccess && result.data.length > 0) {
            const data = JSON.parse(result.data[0].data)
            setBlogData(data)
        }
    }

    useEffect(() => {
        void getBlogData()
    }, [])

    return (
        <TabWrapper>
            {activeEntry == null ?
                <>
                    <BlogEntryAddCard
                        blogData={blogData}
                        setBlogData={setBlogData}
                    />
                    <BlogEntryListCard
                        blogData={blogData}
                        setBlogData={setBlogData}
                        setActiveEntry={setActiveEntry}
                    />
                </>
                :
                <>
                    <BasicPageDataCard key={'BLOG_ENTRY' + activeEntry.link} page={'BLOG_ENTRY' + activeEntry.link}/>
                    <BlogEntryCard activeEntry={activeEntry} setBlogData={setBlogData}/>
                    <BlogEntryData activeEntry={activeEntry} setBlogData={setBlogData}/>
                </>
            }

        </TabWrapper>
    )
};

export default BlogEntry;