import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../../context/AppContext";
import {ImageCardContext} from "../../../context/ImageCardContext";
import {getSubCategories, saveImageSubCategory} from "./Functions";
import Input from "../../form/input";
import PrimaryButton from "../../buttons/primaryButton";
import Spinner from "../../spinner";
// todo refactor
const SubCategoryTab = () => {

    const {user, setError} = useContext(AppContext);
    const {activeCategory, setActiveView, setActiveSubCategory} = useContext(ImageCardContext);

    const subNameRef = useRef("");
    const subLinkRef = useRef("");

    const [isLoad, setLoad] = useState(true);
    const [subCategories, setSubCategories] = useState([])

    useEffect(() => {
        void getSubCategories(setLoad, setError, activeCategory.id, setSubCategories, setActiveView, setActiveSubCategory)
    }, [])

    return (
        <>
            {isLoad ?
                <div className='d-flex justify-content-center'>
                    <Spinner/>
                </div>
                :
                <>
                    <div className="table-responsive">
                        <table className="table table-striped table-hover">
                            <thead>
                            <tr>
                                <th>Nazwa</th>
                                <th>Link</th>
                                <th>Akcje</th>
                            </tr>
                            </thead>
                            <tbody>
                            {subCategories}
                            </tbody>
                        </table>
                    </div>
                    <div className="mb-3">
                        <Input id={'name'} label={'Nazwa'} type={'text'}
                               require={true} reference={subNameRef}/>
                    </div>
                    <div className="mb-3">
                        <Input id={'Link'} label={'Link'} type={'text'}
                               require={true} reference={subLinkRef}/>
                    </div>
                    <PrimaryButton label={'Zapisz'}
                                   onClickFunction={
                                       () => saveImageSubCategory(
                                           user.accessToken,
                                           setError,
                                           setLoad,
                                           setSubCategories,
                                           activeCategory.id,
                                           setActiveView,
                                           setActiveSubCategory,
                                           subNameRef.current.value,
                                           subLinkRef.current.value,
                                       )}/>
                </>
            }
        </>
    )
}

export default SubCategoryTab;