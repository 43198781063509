import React from "react";
// todo refactor
const Checkbox = ({id, label, value, reference, onChangeScript}) => {
    return (
        <>
            <div className="form-check">
                <input className="form-check-input" id={id} ref={reference} type="checkbox" defaultChecked={value} onChange={onChangeScript}/>
                <label className=" form-check-label" htmlFor={id}>{label}</label>
            </div>
        </>
    )
}

export default Checkbox;