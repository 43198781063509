import React, {useContext, useEffect, useRef, useState} from "react";
import PrimaryButton from "../../buttons/primaryButton";
import Spinner from "../../spinner";
import Input from "../../form/input";
import {AppContext} from "../../../context/AppContext";
import TextArea from "../../form/textArea";
import {getRealization, saveRealization} from "./Functions";
// todo refactor
const RealizationCard = () => {

    const {user, setError} = useContext(AppContext);
    const [isLoad, setLoad] = useState(false);
    const [time, setTime] = useState({});
    const [packageData, setPackageData] = useState({});
    const [send, setSend] = useState({});
    const titleTimeRef = useRef("");
    const titlePackageRef = useRef("");
    const titleSendRef = useRef("");
    const descTimeRef = useRef("");
    const descPackageRef = useRef("");
    const descSendRef = useRef("");

    useEffect(() => {
        void getRealization(setLoad, setError, setTime, setPackageData, setSend)
    }, [])

    return (
        <div className="col-lg-12">
            <div className="card card-header-actions mb-4">
                <div className="card-header">
                    Realizacja
                    <PrimaryButton label={'Zapisz'}
                                   onClickFunction={() =>
                                       saveRealization(
                                           user.accessToken,
                                           setError,
                                           titleTimeRef.current.value,
                                           descTimeRef.current.value,
                                           titlePackageRef.current.value,
                                           descPackageRef.current.value,
                                           titleSendRef.current.value,
                                           descSendRef.current.value
                                       )}/>
                </div>
                <div className="card-body">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        <div className="row">
                            <div className="col-md-4">
                                <h5 className="card-title text-center">Czas</h5>
                                <div className="mb-3">
                                    <Input id={'title'} label={'Tytuł'} type={'text'}
                                           reference={titleTimeRef} require={true} value={time.title}/>
                                </div>
                                <div className="mb-3">
                                    <TextArea id={'metaDescription'} label={'Opis'} rows={5}
                                              reference={descTimeRef} require={true} defaultValue={time.desc}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <h5 className="card-title text-center">Pakowanie</h5>
                                <div className="mb-3">
                                    <Input id={'title'} label={'Tytuł'} type={'text'}
                                           reference={titlePackageRef} require={true} value={packageData.title}/>
                                </div>
                                <div className="mb-3">
                                    <TextArea id={'metaDescription'} label={'Opis'} rows={5}
                                              reference={descPackageRef} require={true} defaultValue={packageData.desc}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <h5 className="card-title text-center">Wysyłka</h5>
                                <div className="mb-3">
                                    <Input id={'title'} label={'Tytuł'} type={'text'}
                                           reference={titleSendRef} require={true} value={send.title}/>
                                </div>
                                <div className="mb-3">
                                    <TextArea id={'metaDescription'} label={'Opis'} rows={5}
                                              reference={descSendRef} require={true} defaultValue={send.desc}/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default RealizationCard;