import React, {useContext, useRef} from "react";
import Input from "../../../form/input";
import PrimaryButton from "../../../buttons/primaryButton";
import {CallAPIWithoutResponse} from "../../../../api/API";
import {ImageTemplateContext} from "../../../../context/ImageTemplateContext";
import {AppContext} from "../../../../context/AppContext";
import DangerButton from "../../../buttons/dangerButton";
// todo refactor
const GraphicsSections = ({
                              graphicId,
                              number,
                              editorFieldWidth,
                              editorFieldHeight,
                              editorFieldPositionLeft,
                              editorFieldPositionTop
                          }) => {

    const editorFieldWidthRef = useRef('')
    const editorFieldHeightRef = useRef('')
    const editorFieldPositionLeftRef = useRef('')
    const editorFieldPositionTopRef = useRef('')

    const {id, setUpdate,} = useContext(ImageTemplateContext)
    const {user, setError} = useContext(AppContext);

    const saveGraphicsSection = async () => {
        const result = await CallAPIWithoutResponse('/imageTemplate/updateGraphicSection/' + id,
            {
                id: graphicId,
                editorFieldPositionLeft: editorFieldPositionLeftRef.current.value,
                editorFieldPositionTop: editorFieldPositionTopRef.current.value,
                editorFieldWidth: editorFieldWidthRef.current.value,
                editorFieldHeight: editorFieldHeightRef.current.value,
            },
            'PUT', user.accessToken)
        if (result.isSuccess) {
            setUpdate(true)
        } else {
            setError({
                show: true,
                title: "Bląd dodawania",
                message: "Błąd zapisywania sekcji"
            })
        }
    }

    const deleteGraphicsSection = async () => {
        const result = await CallAPIWithoutResponse('/imageTemplate/deleteGraphicSection/' + id + '/' + graphicId,
            null, 'DELETE', user.accessToken)
        if (result.isSuccess) {
            setUpdate(true)
        } else {
            setError({
                show: true,
                title: "Bląd usuwanue",
                message: "Błąd usuwania sekcji"
            })
        }
    }

    return (
        <>
            <h6>Sekcja: {number}</h6>
            <div className="mb-3">
                <Input id={'editorFieldWidth'} label={'Szerokość edytora'} type={'number'}
                       value={editorFieldWidth} reference={editorFieldWidthRef}/>
            </div>
            <div className="mb-3">
                <Input id={'editorFieldHeight'} label={'Wysokość edytora'} type={'number'}
                       value={editorFieldHeight} reference={editorFieldHeightRef}/>
            </div>
            <div className="mb-3">
                <Input id={'editorFieldPositionLeft'} label={'Pozycja edyta od lewej'} type={'number'}
                       value={editorFieldPositionLeft} reference={editorFieldPositionLeftRef}/>
            </div>
            <div className="mb-3">
                <Input id={'editorFieldPositionTop'} label={'Pozycja edyta od góry'} type={'number'}
                       value={editorFieldPositionTop} reference={editorFieldPositionTopRef}/>
            </div>
            <div className='d-flex justify-content-around col-5'>
                <PrimaryButton label={'Zapisz'} onClickFunction={saveGraphicsSection}/>
                <DangerButton label={'Usuń'} onClickFunction={deleteGraphicsSection}/>
            </div>
            <hr/>
        </>

    )
};

export default GraphicsSections;