import React, {useContext, useRef, useState} from "react";
import PrimaryButton from "../../buttons/primaryButton";
import Input from "../../form/input";
import TextArea from "../../form/textArea";
import FileInput from "../../form/fileInput";
import Radio from "../../form/radio/Radio";
import {CallAPIWithFile, CallAPIWithoutResponse} from "../../../api/API";
import {AppContext} from "../../../context/AppContext";
// todo refactor
const AddNewContentSection = ({getContent}) => {

    const {user, setError} = useContext(AppContext);

    const headerRef = useRef("");
    const contentRef = useRef("")
    const linkRef = useRef("")
    const imageRef = useRef("")
    const altRef = useRef("")
    const buttonOneLabelRef = useRef("")
    const buttonOneLinkRef = useRef("")
    const buttonTwoLabelRef = useRef("")
    const buttonTwoLinkRef = useRef("")

    const options = [
        {
            value: "onlyContent",
            label: "Sama treść"
        },
        {
            value: "isMovie",
            label: "Film"
        },
        {
            value: "isImage",
            label: "Grafika"
        }
    ]

    const [selectedOption, setSelectedOption] = useState('onlyContent');

    const addNewContent = async () => {
        const body = {
            header: headerRef.current.value,
            content: contentRef.current.value,
            link: linkRef.current.value,
            image: selectedOption === 'isImage' ? imageRef.current.files[0].name : '',
            alt: altRef.current.value,
            isMovie: selectedOption === 'isMovie',
            isImage: selectedOption === 'isImage',
            buttonOneLabel: buttonOneLabelRef.current.value,
            buttonOneLink: buttonOneLinkRef.current.value,
            buttonTwoLabel: buttonTwoLabelRef.current.value,
            buttonTwoLink: buttonTwoLinkRef.current.value,
        }
        const result = await CallAPIWithoutResponse('/mainPageContent', body, 'POST', user.accessToken)
        if (!result.isSuccess) {
            setError({
                show: true,
                title: "Bląd dodawania treśći",
                message: "Błąd podczas dodawania treści"
            })
        }
        if (selectedOption === 'isImage') {
            const bodyImage = new FormData();
            bodyImage.append('image', imageRef.current.files[0])
            const resultImage = await CallAPIWithFile('/mainPageContent/addImage', bodyImage, user.accessToken)
            if (!resultImage.isSuccess) {
                setError({
                    show: true,
                    title: "Bląd zapisania",
                    message: "Błąd podczas zapisywania grafiki"
                })
            }
        }
        void getContent();
    }

    return (
        <>
            <div className="mb-3">
                <Input id={'header'} label={'Tytuł + nagłówek (h1, h2, h3...)'} type={'text'}
                       reference={headerRef} require={true}/>
            </div>
            <div className="mb-3">
                <TextArea id={'content'} label={'Treść'} reference={contentRef}
                          require={true} rows={12}/>
            </div>
            <div className="mb-3">
                <Radio options={options} value={selectedOption} setValue={setSelectedOption}/>
            </div>
            {
                selectedOption === "isMovie"
                &&
                <div className="mb-3">
                    <Input id={'link'} label={'Link do filmu'} type={'text'}
                           reference={linkRef} require={true}/>
                </div>
            }
            {
                selectedOption === "isImage"
                &&
                <>
                    <div className="mb-3">
                        <FileInput id={"image"} label={"Grafika"} require={true} reference={imageRef}/>
                    </div>
                    <div className="mb-3">
                        <Input id={'alt'} label={'Alt grafiki'} type={'text'}
                               reference={altRef} require={true}/>
                    </div>
                </>
            }
            {
                (selectedOption === "isMovie" || selectedOption === "isImage")
                &&
                <>
                    <div className="mb-3">
                        <Input id={'buttonOneLabel'} label={'Przycisk 1 - label'} type={'text'}
                               reference={buttonOneLabelRef} require={true}/>
                    </div>
                    <div className="mb-3">
                        <Input id={'buttonOneLink'} label={'Przycisk 1 - link'} type={'text'}
                               reference={buttonOneLinkRef} require={true}/>
                    </div>
                    <div className="mb-3">
                        <Input id={'buttonTwoLabel'} label={'Przycisk 2 - label'} type={'text'}
                               reference={buttonTwoLabelRef} require={true}/>
                    </div>
                    <div className="mb-3">
                        <Input id={'buttonTwoLink'} label={'Przycisk 2 - link'} type={'text'}
                               reference={buttonTwoLinkRef} require={true}/>
                    </div>
                </>
            }
            <PrimaryButton label={'Dodaj'}
                           onClickFunction={() => addNewContent()}/>
        </>
    );
};

export default AddNewContentSection;