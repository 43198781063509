import React from "react";
import Modal from "../../modal/Modal";
import {useApi} from "../../../hooks/useApi";

const DeleteTextBoxModal = ({show, setShow, item, getTextBoxes}) => {

    const {deleteApi} = useApi()

    const deleteTextBox = async () => {
        const resultFile = await deleteApi('/appFile/' + item.file.id)
        const result = await deleteApi('/guiComponent/' + item.id)
        if (resultFile.isSuccess && result.isSuccess) {
            getTextBoxes()
            setShow(false)
        }
    }

    return (
        show &&
        <Modal title="Czy na pewno?"
               onCancel={() => setShow(false)}
               onApprove={() => deleteTextBox()}
               size="md">
            <p>Czy na pewno chcesz usunąć ten tekst box?</p>
        </Modal>
    )
};

export default DeleteTextBoxModal;