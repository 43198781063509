import React, {useState} from "react";
import Modal from "../../modal/Modal";
import {Col, Row} from "react-bootstrap";
import Radio from "../../form/radio/Radio";
import TextArea from "../../form/textArea";
import Space from "../../layout/Space";
import ListRecords from "./ListRecords";
import {useApi} from "../../../hooks/useApi";
import UploadFile from "../../form/uploadFile/UploadFile";
import Input from "../../form/input";

const EditTextBoxModal = ({page, show, setShow, getTextBoxes, item}) => {

    const [selectedType, setSelectedType] = useState(item.type);
    const [selectedVariant, setSelectedVariant] = useState(item.variant);
    const [selectedVariantValidation, setSelectedVariantValidation] = useState(null);
    const [selectedContentType, setSelectedContentType] = useState(item.contentType);
    const [recordsList, setRecordsList] = useState(item.recordsList)
    const [title, setTitle] = useState(item.heading)
    const [titleValidation, setTitleValidation] = useState(null)
    const [text, setText] = useState(item.text)
    const [file, setFile] = useState(item.file)
    const [fileBlob, setFileBlob] = useState(null)
    const [fileName, setFileName] = useState(item?.file?.fileName)
    const [imageAlt, setImageAlt] = useState(item?.file?.imageAlt)
    const [buttonLabel, setButtonLabel] = useState(item?.buttonLabel);
    const [buttonLink, setButtonLink] = useState(item?.buttonLink);

    const types = [
        {
            value: "BASIC",
            label: "Podstawowy"
        },
        {
            value: "OVERRIDING",
            label: "Nachodzący"
        }
    ]

    const variantBasic = [
        {
            value: "BLUE",
            label: "Niebieski"
        },
        {
            value: "WHITE",
            label: "Biały"
        },
        {
            value: "YELLOW",
            label: "Żółty"
        },
        {
            value: "NO_BACKGROUND",
            label: "Bez tła"
        }
    ]

    const variantOverriding = [
        {
            value: "BLUE",
            label: "Niebieski"
        },
        {
            value: "WHITE",
            label: "Biały"
        },
        {
            value: "YELLOW",
            label: "Żółty"
        }
    ]

    const contentType = [
        {
            value: "TEXT",
            label: "Tekst"
        },
        {
            value: "LIST",
            label: "Lista"
        }
    ]

    const {putApi} = useApi()

    const cancel = () => {
        setShow(false)
    }

    const valid = () => {
        let isValid = true
        const emptyTitle = title === "" || title === null

        if (emptyTitle && selectedContentType === "TEXT") {
            isValid = false
            setTitleValidation("Tytuł jest wymagany")
        } else {
            setTitleValidation(null)
        }

        if (selectedVariant === "NO_BACKGROUND" && selectedType === "OVERRIDING") {
            isValid = false
            setSelectedVariantValidation("Wybierz wariant koloru")
        } else {
            setSelectedVariantValidation(null)
        }

        return isValid;
    }

    const approve = async () => {
        if (valid()) {
            // jak jest fileBlob to wiadomo ze zostala zmieniona grafika,
            // jak nie zostala zmieniona to nie wysylamy starej na serwer bo juz tam jest.
            let fileData = { ...file, imageAlt: imageAlt }
            if (fileBlob) {
                const fileBody = {
                    "id" : item.file.id,
                    "fileName" : fileName,
                    "fileId" : item.file.fileId,
                    "extension": '.' + fileName.split('.').pop(),
                    "fileBlob" : fileBlob
                }
                const resultFilePost = await putApi("/appFile/" + item.file.id, fileBody)
                const data = { ...resultFilePost.data, imageAlt: imageAlt }
                setFile(data)
                fileData = data
            }
            const data = {
                type: selectedType,
                variant: selectedVariant,
                contentType: selectedContentType,
                recordsList: selectedContentType === "LIST" ? recordsList : [],
                title: title,
                text: selectedContentType === "TEXT" ? text : "",
                position: item.position,
                buttonLabel: buttonLabel,
                buttonLink: buttonLink,
                file: fileData
            }
            const body = {
                page: page,
                type: 'TEXT_BOX',
                data: JSON.stringify(data)
            }
            await putApi('/guiComponent/' + item.id, body)
            await getTextBoxes()
            setShow(false)
        }
    }

    const onUploadFile = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const reader = new FileReader();
        reader.onload = (event) => {
            const base64String = event.target.result;
            setFileBlob(base64String.split(',')[1])
        };
        reader.readAsDataURL(file);
    }

    return (
        show &&
        <Modal title="Edytuj tekst box"
               onCancel={() => cancel()}
               onApprove={() => approve()}>
            <Row>
                <Col>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <strong>Typ tekst boxa:</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-sm-flex justify-content-center">
                            <Radio options={types} value={selectedType} setValue={setSelectedType}/>
                        </Col>
                    </Row>
                    <Space space={30}/>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <strong>Wariant koloru:</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-sm-flex justify-content-center" xs={12}>
                            <Radio options={selectedType === "BASIC" ?
                                variantBasic :
                                variantOverriding}
                                   value={selectedVariant}
                                   setValue={setSelectedVariant}
                                   isValid={!selectedVariantValidation}
                            />
                        </Col>
                        <Col className="d-sm-flex justify-content-center" xs={12}>
                            {selectedVariantValidation ? <span className="text-danger">{selectedVariantValidation}</span> : null}
                        </Col>
                    </Row>
                    <Space space={30}/>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <UploadFile file={file}
                                        label="Przesłana grafika"
                                        onDelete={() => setFile(null)}
                                        onChange={(e) => onUploadFile(e)}/>
                        </Col>
                    </Row>
                    <Space space={30}/>
                    <Row>
                        <Col className="d-sm-flex justify-content-center">
                            {
                                selectedType === "OVERRIDING" ?
                                    <p>Sugerowany rozmiar: wysokość: 440px szerokość: 840px </p>
                                    :
                                    <p>Sugerowany rozmiar: 390px szerokość: 530px </p>
                            }
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Col sm={6}>
                            <Input id='ALT' label='ALT Grafiki' type='text'
                                   value={imageAlt}
                                   onChange={(e) => setImageAlt(e.target.value)}/>
                        </Col>
                    </Row>
                    <Space space={30}/>
                    <Row className="d-flex justify-content-center">
                        <Col sm={6} className='mt-3'>
                            <Input id='buttonLabel'
                                   label='Etykieta przycisku'
                                   type='text'
                                   value={buttonLabel}
                                   onChange={(e) => setButtonLabel(e.target.value)}/>
                        </Col>
                        <Col sm={6} className='mt-3'>
                            <Input id='buttonLink'
                                   label='Link przycisku'
                                   type='text'
                                   value={buttonLink}
                                   onChange={(e) => setButtonLink(e.target.value)}/>
                        </Col>
                    </Row>
                    <Space space={30}/>
                    <Row>
                        <Col>
                            <TextArea label='Tytuł'
                                      defaultValue={title}
                                      rows={2}
                                      isValid={titleValidation === null}
                                      onChange={(e) => setTitle(e.target.value)}/>
                            {titleValidation ? <span className="text-danger">{titleValidation}</span> : null}
                        </Col>
                    </Row>
                    <Space space={30}/>
                    <Row>
                        <Col className="d-sm-flex justify-content-center">
                            <Radio options={contentType} value={selectedContentType} setValue={setSelectedContentType}/>
                        </Col>
                    </Row>
                    {
                        selectedContentType === "TEXT" && selectedType !== "OVERRIDING" &&
                        <Row>
                            <Col>
                                <TextArea label='Treść'
                                          defaultValue={text}
                                          rows={6}
                                          onChange={(e) => setText(e.target.value)}/>
                            </Col>
                        </Row>
                    }
                    {
                        selectedContentType === "LIST" &&
                        <ListRecords recordsList={recordsList} setRecordsList={setRecordsList}/>
                    }
                </Col>
            </Row>
        </Modal>
    )
};

export default EditTextBoxModal;