import React from "react";
import {FileInfoContext} from "./FileInfoContext";
import Header from "./Header";
import FileName from "./FileName";
import DeleteButton from "./DeleteButton";
import PreviewImage from "./PreviewImage";
// todo refactor
const FileInfo = ({header, fileName, onClick, src}) => {

    return (
        <FileInfoContext.Provider value={{
            header,
            fileName,
            onClick,
            src
        }}>
            <Header/>
            <div className='d-flex align-content-center'>
                <FileName/>
                <DeleteButton/>
            </div>
            <PreviewImage/>
        </FileInfoContext.Provider>
    )
}

export default FileInfo;