import React, {useContext, useEffect, useState} from "react";
import Spinner from "../../spinner";
import {getClientContexts} from "./Functions";
import {AppContext} from "../../../context/AppContext";
import TabWrapper from "../TabWrapper";
// todo refactor
const SiteEntries = () => {

    const {user, setError} = useContext(AppContext);
    const [isLoad, setLoad] = useState(true);
    const [sites, setSites] = useState([])
    const [showDetails, setShowDetails] = useState(false)
    const [detailsPhotos, setDetailsPhotos] = useState([])

    useEffect(() => {
        void getClientContexts(setLoad, user, setError, setSites, setShowDetails, setDetailsPhotos)
    }, [])

    return (
        <TabWrapper>
            <div className="card card-header-actions mb-4">
                <div className="card-header">
                    Lista wejść na stronę
                    {showDetails ?
                        <button className="btn btn-primary btn-sm"
                                onClick={() => setShowDetails(false)}>
                            Wstecz
                        </button>
                        : null
                    }
                </div>
                <div className="card-body">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        showDetails ?
                            <div>
                                <h5 className="card-title">Przesłane zdjęcia:</h5>
                                {detailsPhotos}
                            </div>
                            :
                            <div className='table-responsive'>
                                <table className="table table-striped table-hover">
                                    <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Ilość zdjęć</th>
                                        <th>Akcje</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {sites}
                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
            </div>
        </TabWrapper>
    )
}

export default SiteEntries;