import React from "react";
// todo refactor
const Radio = ({options, value, setValue, isValid = true}) => {

    return (
        <>
            {options?.map(option => {
                return (
                    <div className="form-check m-2" key={option.value}>
                        <input className={`form-check-input ${isValid ? "" : "border-danger"}`}
                               id={option.value}
                               type="radio"
                               name={option.value}
                               checked={value === option.value}
                               onChange={() => setValue(option.value)}
                        />
                        <label className={`form-check-label ${isValid ? "" : "text-danger"}`}
                               htmlFor={option.value}>
                            {option.label}
                        </label>
                    </div>
                )
            })}
        </>
    );
};

export default Radio;