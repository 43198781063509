import React, {useState} from "react";
import {Card as ReactCard, Col, Row} from "react-bootstrap";
import SecondaryButton from "../../buttons/SecondaryButton";
import DangerButton from "../../buttons/dangerButton";
import DeleteBlogEntryModal from "./DeleteBlogEntryModal";

const BlogEntryRecord = ({entry, setBlogData, setActiveEntry}) => {

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    return (
        <ReactCard className="mb-3 shadow-sm" key={entry.link}>
            <DeleteBlogEntryModal
                entry={entry}
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                setBlogData={setBlogData}/>
            <ReactCard.Body>
                <Row>
                    <Col xs={12} md={9}>
                        <Row>
                            <Col xs={12} lg={8}>
                                <p className="text-md m-0">Nazwa: {entry.name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={8}>
                                <p className="text-md m-0">Link: {entry.link}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={3}>
                        <Row className="justify-content-end mt-3">
                            <Col xs={6} className="d-flex justify-content-end">
                                <SecondaryButton label="Edytuj"
                                                 onClickFunction={() => setActiveEntry(entry)}
                                />
                            </Col>
                            <Col xs={6}>
                                <DangerButton label="Usuń"
                                              onClickFunction={() => setShowDeleteModal(true)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ReactCard.Body>
        </ReactCard>
    )
};

export default BlogEntryRecord;