import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../../context/AppContext";
import {useApi} from "../../../hooks/useApi";
import PrimaryButton from "../../buttons/primaryButton";
import Spinner from "../../spinner";
import Input from "../../form/input";
// todo refactor
const UploadPhotosCard = () => {

    const [isLoad, setLoad] = useState(true);
    const [data, setData] = useState(false);
    const {setError} = useContext(AppContext);

    const photoArchivingTimeRef = useRef("");

    const {postApi, getApi} = useApi();

    const getUploadPhotoInfo = async () => {
        const result = await getApi('/cms/getPhotoArchiveInfo')
        if (result.isSuccess) {
            setData(result.data)
        } else {
            setError({
                show: true,
                title: "Bląd pobierania",
                message: "Błąd pobierania danych o przesłanych zdjęciach"
            })
        }
        setLoad(false)
    }

    const onClickSave = async () => {
        setLoad(true)
        await postApi('/cms/setPhotoArchiveTime/' + photoArchivingTimeRef.current.value, null)
        setLoad(false)
    }

    useEffect(() => {
        void getUploadPhotoInfo(setLoad, setError, setData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="col-lg-6">
            <div className="card card-header-actions">
                <div className="card-header">
                    Przesłane zdjęcia
                    <PrimaryButton label={'Zapisz'}
                                   onClickFunction={onClickSave}/>
                </div>
                <div className="card-body d-flex flex-column justify-content-center">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        <>
                            <p>Aktualna liczba zdjęć na serwerze: <b>{data.photosOnTheServer}</b></p>
                            <p>Liczba usuniętych zdjęć/Liczba wszystkich przesłanych
                                zdjęć: <b>{data.deletedPhotos}/{data.allPhotos}</b></p>
                            <div className="mb-3">
                                <Input id={'photoArchivingTime'}
                                       label={'Czas archiwizacji zdjęć'}
                                       type={'number'}
                                       reference={photoArchivingTimeRef}
                                       require={true}
                                       value={data.photoArchivingTime}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default UploadPhotosCard;