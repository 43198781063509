export const getMessage = (order, invoiceLink, sendLink) => {

    const header = "<div style=\"background-color:#2b2b2b;color:white\">\n" +
                    "<h2 style=\"color:#fff;font-family:'Montserrat',serif;font-size:32px;padding:15px\">printini</h2>\n" +
                    "</div>\n"

    const thanks = "<p>Dziękujemy za złożenie zamówienia w naszym sklepie.</p>\n"

    const orderNumber = "<p>Zamówienie posiada numer: " + order.id + ".</p>\n"

    const orderDetailsHeading = "<h2 style=\"color:#f59e3e;font-family:'Montserrat',serif;font-size:32px\">Szczegóły zamówienia</h2>\n"

    const tableHeading = "<tr>\n" +
                            "<th style=\"border-bottom:2px solid #000;padding:6px 12px\">Produkt</th>\n" +
                            "<th style=\"border-bottom:2px solid #000;padding:6px 12px\">Ilość</th>\n" +
                            "<th style=\"border-bottom:2px solid #000;padding:6px 12px\">Cena jednostkowa (brutto)</th>\n" +
                            "<th style=\"border-bottom:2px solid #000;padding:6px 12px\">Suma (brutto)</th>\n" +
                        "</tr>\n"

    const orderedProducts = order.orders.map((product) => {
        return "<tr>\n" +
            "<td style=\"border-bottom:1px solid #ddd;padding:6px 12px\"><strong>" + product.imageTemplate.name + "</strong><br></td>\n" +
            "<td style=\"border-bottom:1px solid #ddd;padding:6px 12px\" align=\"center\">" + product.volume + " szt.</td>\n" +
            "<td style=\"border-bottom:1px solid #ddd;padding:6px 12px\" align=\"right\">"+ product.editorSize.price + " zł</td>\n" +
            "<td style=\"border-bottom:1px solid #ddd;padding:6px 12px\" align=\"right\">"+ product.editorSize.price * product.volume + " zł</td>\n" +
            "</tr>\n"
    })

    const deliveryCost = "<tr>\n" +
                            "<td style=\"background:#ddd;border-bottom:1px solid #ddd;padding:6px 12px\" align=\"right\" colspan=\"3\">\n" +
                            "    Koszt dostawy\n" +
                            "</td>\n" +
                            "<td style=\"border-bottom:1px solid #ddd;padding:6px 12px\" align=\"right\">20.00 zł</td>\n" +
                        "</tr>\n"

    const totalPrice = "<tr>\n" +
                            "<td style=\"background:#ddd;border-bottom:1px solid #ddd;padding:6px 12px\" align=\"right\" colspan=\"3\">\n" +
                            "    Do zapłaty\n" +
                            "</td>\n" +
                            "<td style=\"border-bottom:1px solid #ddd;padding:6px 12px;font-weight:bold\" align=\"right\">\n" +
                            "    " + order.totalOrdersPrice + " zł\n" +
                            "</td>\n" +
                        "</tr>\n"

    const invoice = invoiceLink ? "<p>Twoja faktura:</p>\n" +
                                    "<a href=\"" + invoiceLink + "\" target=\"_blank\">" + invoiceLink + "</a>\n"
                                : ''

    const send = sendLink ? "<p>Zobacz status swojej wysyłki:</p>\n" +
                            "<a href=\"" + sendLink + "\" target=\"_blank\">" + sendLink + "</a>\n"
                            : ''

    const greetings = "<p>Pozdrawiamy i życzymy wiele radości z zakupu</p>\n" +
                        "<p>Zespół <a href=\"https://printini.pl\" target=\"_blank\">printini.pl</a></p>\n"

    const footer = "<div style=\"background-color:#2b2b2b;color:white;padding:50px\">\n" +
                        "<h2 style=\"color:#fff;font-family:'Montserrat',serif;font-size:32px\">printini</h2>\n" +
                        "<strong>Alixnet&nbsp;Damian&nbsp;Nosiadek | ul.&nbsp;Pilchowicka&nbsp;19 | 44-207&nbsp;Rybnik | nip&nbsp;6423139547 | regon&nbsp;241981439\n" +
                    "</strong></div>\n"

    return "<div style=\"font-family:Lato,sans-serif;font-size:17px;color:#4d4d4d;font-weight:300\">\n" +
        "    <div style=\"max-width:1170px;margin-left:auto;margin-right:auto\">\n" +
                header +
        "        <div style=\"margin:50px\">\n" +
                    thanks +
                    orderNumber +
                    orderDetailsHeading +
        "            <table style=\"border-collapse:collapse\">\n" +
        "                <tbody>\n" +
                            tableHeading +
                            orderedProducts +
                            deliveryCost +
                            totalPrice +
        "                </tbody>\n" +
        "            </table>\n" +
        "            <p>---</p>\n" +
                    invoice +
                    send +
                    greetings +
        "        </div>\n" +
                footer +
        "    </div>\n" +
        "</div>"
}
