import React, {useState} from "react";
import SecondaryButton from "../../buttons/SecondaryButton";
import {Col} from "react-bootstrap";
import EditTextBoxModal from "./EditTextBoxModal";

const EditButton = ({item, getTextBoxes, page}) => {

    const [showEditModal, setShowEditModal] = useState(false);

    return (
        <Col xs={6} className="d-flex justify-content-end">
            <EditTextBoxModal
                page={page}
                show={showEditModal}
                setShow={setShowEditModal}
                item={item}
                getTextBoxes={getTextBoxes}/>
            <SecondaryButton label="Edytuj"
                             onClickFunction={() => setShowEditModal(true)}/>
        </Col>
    )
};

export default EditButton;