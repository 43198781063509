import React, {useContext, useEffect, useRef, useState} from "react";
import Spinner from "../../spinner";
import {AppContext} from "../../../context/AppContext";
import Input from "../../form/input";
import TextArea from "../../form/textArea";
import PrimaryButton from "../../buttons/primaryButton";
import {getOpinions, saveOpinion} from "./Functions";
// todo refactor
const OpinionCard = () => {

    const {user, setError} = useContext(AppContext);
    const [isLoad, setLoad] = useState(true);
    const [opinions, setOpinions] = useState([])
    const nameRef = useRef("");
    const textRef = useRef("");

    useEffect(() => {
        void getOpinions(setLoad, setError, setOpinions)
    }, [])

    return (
        <div className="col-lg-10">
            <div className="card card-header-actions mb-4">
                <div className="card-header">
                    Opinie
                </div>
                <div className="card-body">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        <>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                    <tr>
                                        <th>Imie</th>
                                        <th>Treść</th>
                                        <th>Akcje</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {opinions}
                                    </tbody>
                                </table>
                            </div>
                            <div className="mb-3">
                                <Input id={'name'} label={'Imie'} type={'text'}
                                       require={true} reference={nameRef}/>
                            </div>
                            <div className="mb-3">
                                <TextArea label={'Treść'} id={'text'} rows={6} reference={textRef}/>
                            </div>
                            <PrimaryButton label={'Zapisz'}
                                           onClickFunction={
                                               () => saveOpinion(
                                                   user.accessToken,
                                                   setError,
                                                   setLoad,
                                                   setOpinions,
                                                   nameRef.current.value,
                                                   textRef.current.value,
                                               )}/>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default OpinionCard;