import React from "react";
import TabWrapper from "../TabWrapper";
import BasicPageDataCard from "../../cards/basicPageDataCard/BasicPageDataCard";

const BasketPage = () => {

    return (
        <TabWrapper>
            <BasicPageDataCard page='BASKET'/>
        </TabWrapper>
    )
};

export default BasketPage;