import {CallAPI, CallAPIWithoutResponse} from "../../../api/API";
import React from "react";
import FeatherIcon from "feather-icons-react";
// todo refactor
export const getOpinions = async (setLoad, setError, setOpinions) => {
    const result = await CallAPI('/opinion/all', null, 'GET')
    if (result.isSuccess) {
        const opinions = []
        result.data.forEach(opinion => {
            opinions.push(
                <tr key={opinion.id}>
                    <td>{opinion.name}</td>
                    <td>{opinion.text}</td>
                    <td>
                        <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Usuń"
                                onClick={() => deleteOpinion(opinion.id, setLoad, setError, setOpinions)}>
                            <FeatherIcon icon="trash"/>
                        </button>
                    </td>
                </tr>
            )
        })
        setOpinions(opinions)
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania opinii"
        })
    }
    setLoad(false)
}

export const saveOpinion = async (accessToken, setError, setLoad, setOpinions, name, text) => {

    const body = {
        name: name,
        text: text
    }

    const result = await CallAPIWithoutResponse('/opinion', body, 'POST', accessToken)
    if (result.isSuccess) {
        await getOpinions(setLoad, setError, setOpinions)
    } else {
        setError({
            show: true,
            title: "Bląd zapisania",
            message: "Błąd podczas zapisywania opinii"
        })
    }
}

export const deleteOpinion = async (id, setLoad, setError, setOpinions) => {
    setLoad(true)
    const result = await CallAPIWithoutResponse('/opinion/delete/' + id, null, 'DELETE', null)
    if (result.isSuccess) {
        await getOpinions(setLoad, setError, setOpinions)
    } else {
        setError({
            show: true,
            title: "Bląd usuwania",
            message: "Błąd podczas usuwania opinii"
        })
    }
    setLoad(false)
}