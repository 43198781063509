import React, {useContext, useEffect, useRef, useState} from "react";
import PrimaryButton from "../../buttons/primaryButton";
import Spinner from "../../spinner";
import {getCompany, saveCompany} from "./Functions";
import {AppContext} from "../../../context/AppContext";
import Input from "../../form/input";
// todo refactor
const CompanyCard = () => {

    const {user, setError} = useContext(AppContext);
    const [isLoad, setLoad] = useState(true);
    const [company, setCompany] = useState({});
    const nameRef = useRef("");
    const streetRef = useRef("");
    const addressNumberRef = useRef("");
    const zipCodeRef = useRef("");
    const cityRef = useRef("");
    const phoneRef = useRef("");
    const nipRef = useRef("");
    const accountNumberRef = useRef("");
    const bankRef = useRef("");

    useEffect(() => {
        void getCompany(setLoad, setError, setCompany)
    }, [])

    return (
        <div className="col-lg-6">
            <div className="card card-header-actions">
                <div className="card-header">
                    Dane firmy
                    <PrimaryButton label={'Zapisz'}
                                   onClickFunction={() => saveCompany(
                                       user.accessToken,
                                       setError,
                                       nameRef.current.value,
                                       streetRef.current.value,
                                       addressNumberRef.current.value,
                                       zipCodeRef.current.value,
                                       cityRef.current.value,
                                       phoneRef.current.value,
                                       nipRef.current.value,
                                       accountNumberRef.current.value,
                                       bankRef.current.value
                                   )}/>
                </div>
                <div className="card-body d-flex flex-column justify-content-center">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        <>
                            <div className="mb-3">
                                <Input id={'name'} label={'Nazwa'} type={'text'}
                                       reference={nameRef} require={true} value={company.name}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'street'} label={'Ulica'} type={'text'}
                                       reference={streetRef} require={true} value={company.street}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'addressNumber'} label={'Numer'} type={'text'}
                                       reference={addressNumberRef} require={true} value={company.addressNumber}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'zipCode'} label={'Kod pocztowy'} type={'text'}
                                       reference={zipCodeRef} require={true} value={company.zipCode}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'city'} label={'Miasto'} type={'text'}
                                       reference={cityRef} require={true} value={company.city}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'phone'} label={'Telefon'} type={'text'}
                                       reference={phoneRef} require={true} value={company.phone}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'nip'} label={'NIP'} type={'text'}
                                       reference={nipRef} require={true} value={company.nip}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'accountNumber'} label={'Numer konta'} type={'text'}
                                       reference={accountNumberRef} require={true} value={company.accountNumber}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'bank'} label={'Bank'} type={'text'}
                                       reference={bankRef} require={true} value={company.bank}/>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default CompanyCard;