import React from "react";
import ContactCard from "../../cards/contactCard";
import TabWrapper from "../TabWrapper";
import BasicPageDataCard from "../../cards/basicPageDataCard/BasicPageDataCard";

const ContactPage = () => {

    return (
        <TabWrapper>
            <BasicPageDataCard page={'CONTACT_PAGE'}/>
            <ContactCard/>
        </TabWrapper>
    )
}

export default ContactPage;