import {CallAPIWithoutResponse} from "../../../api/API";
// todo refactor
export const updateApp = async (accessToken, setError) => {
    const result = await CallAPIWithoutResponse('/cms/build', null, 'GET', accessToken)
    if (!result.isSuccess) {
        setError({
            show: true,
            title: "Bląd aktualizacji",
            message: "Błąd podczas aktualizacji aplikacji"
        })
    }
}