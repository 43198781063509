import React, {useContext, useEffect, useRef, useState} from "react";
import Spinner from "../../spinner";
import {AppContext} from "../../../context/AppContext";
import Input from "../../form/input";
import PrimaryButton from "../../buttons/primaryButton";
import {getFaq, saveFaq} from "./Functions";
import TextArea from "../../form/textArea";
// todo refactor
const FaqCard = () => {

    const {user, setError} = useContext(AppContext);
    const [isLoad, setLoad] = useState(true);
    const [faq, setFaq] = useState([])
    const questionRef = useRef("");
    const answerRef = useRef("");

    useEffect(() => {
        void getFaq(setLoad, setError, setFaq)
    }, [])

    return (
        <div className="col-lg-8">
            <div className="card card-header-actions mb-4">
                <div className="card-header">
                    FAQ
                </div>
                <div className="card-body">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        <>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                    <tr>
                                        <th>Pytanie</th>
                                        <th>Odpowiedź</th>
                                        <th>Akcje</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {faq}
                                    </tbody>
                                </table>
                            </div>
                            <div className="mb-3">
                                <Input id={'question'} label={'Pytanie'} type={'text'}
                                       require={true} reference={questionRef}/>
                            </div>
                            <div className="mb-3">
                                <TextArea id={'answer'} label={'Odpowiedź'} rows={3}
                                          reference={answerRef} require={true}/>
                            </div>
                            <PrimaryButton label={'Zapisz'}
                                           onClickFunction={
                                               () => saveFaq(
                                                   user.accessToken,
                                                   setError,
                                                   setLoad,
                                                   setFaq,
                                                   questionRef.current.value,
                                                   answerRef.current.value,
                                               )}/>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default FaqCard;