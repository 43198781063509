import React from "react";
import {useApi} from "../../../hooks/useApi";
// todo refactor
const DeleteOrderModal = ({orderToDelete, onApprove}) => {

    const {deleteApi} = useApi();

    const onClickDelete = async () => {
        const success = await deleteApi('/approvedOrder/' + orderToDelete)
        if (success) {
            onApprove()
        }
    }

    return (
        <div className="modal fade" id="deleteOrderModal" tabIndex="-1" role="dialog"
             aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Potwierdzenie</h5>
                        <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        Czy na pewno chcesz usunąć zamówienie z systemu?
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" type="button" data-bs-dismiss="modal">Anuluj</button>
                        <button className="btn btn-primary" type="button" data-bs-dismiss="modal"
                                onClick={onClickDelete}>Usuń
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteOrderModal;