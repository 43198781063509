import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../../context/AppContext";
import {getContactData, saveContactData} from "./Functions";
import PrimaryButton from "../../buttons/primaryButton";
import TextArea from "../../form/textArea";
import Spinner from "../../spinner";
import Input from "../../form/input";
// todo refactor
const MainContentCard = () => {

    const {user, setError} = useContext(AppContext);

    const [isLoad, setLoad] = useState(true);
    const [data, setData] = useState({});

    const headerRef = useRef("");
    const contentRef = useRef("");

    useEffect(() => {
        void getContactData(user.accessToken, setLoad, setError, setData)
    }, [])

    return (
        <div className="col-lg-6">
            <div className="card card-header-actions">
                <div className="card-header">
                    Główna treść strony
                    <PrimaryButton label={'Zapisz'}
                                   onClickFunction={() => saveContactData(
                                       user.accessToken,
                                       setError,
                                       headerRef.current.value,
                                       contentRef.current.value
                                   )}/>
                </div>
                <div className="card-body d-flex flex-column justify-content-center">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        <>
                            <div className="mb-3">
                                <Input id={'header'} label={'Nagłówek'} type={'text'}
                                       reference={headerRef} require={true} value={data.topHeader}/>
                            </div>
                            <div className="mb-3">
                                <TextArea label={'Treść'} id={'content'} rows={6} reference={contentRef}
                                          defaultValue={data.topContent}/>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default MainContentCard;