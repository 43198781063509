import React, {useContext, useEffect, useRef, useState} from "react";
import {ImageCardContext} from "../../../context/ImageCardContext";
import {AppContext} from "../../../context/AppContext";
import {
    getImageCategory,
    getImageSubCategory,
    saveImageCategoryTopContent,
    saveImageSubCategoryTopContent,
} from "./Functions";
import Spinner from "../../spinner";
import Input from "../../form/input";
import TextArea from "../../form/textArea";
import PrimaryButton from "../../buttons/primaryButton";
// todo refactor
const TopContentTab = ({isSubCategory}) => {

    const {activeCategory, activeSubCategory} = useContext(ImageCardContext);
    const {user, setError} = useContext(AppContext);

    const topHeaderRef = useRef("");
    const topContentRef = useRef("");

    const [isLoad, setLoad] = useState(true);
    const [data, setData] = useState({})

    useEffect(() => {
        if (isSubCategory) {
            void getImageSubCategory(activeSubCategory.id, setLoad, setError, setData)
        } else {
            void getImageCategory(activeCategory.id, setLoad, setError, setData)
        }
    }, [])

    return (
        <>
            {isLoad ?
                <div className='d-flex justify-content-center'>
                    <Spinner/>
                </div>
                :
                <>
                    <div className="mb-3">
                        <Input id={'topHeader'} label={'Tytuł'} type={'text'}
                               reference={topHeaderRef} require={true} value={data.topHeader}/>
                    </div>
                    <div className="mb-3">
                        <TextArea id={'topContent'} label={'Treść'} reference={topContentRef}
                                  defaultValue={data.topContent} require={true} rows={12}/>
                    </div>
                    {isSubCategory ?
                        <PrimaryButton label={'Zapisz'}
                                       onClickFunction={
                                           () =>
                                               saveImageSubCategoryTopContent(
                                                   user.accessToken,
                                                   setError,
                                                   activeSubCategory.id,
                                                   topHeaderRef.current.value,
                                                   topContentRef.current.value,
                                               )}
                        />
                        :
                        <PrimaryButton label={'Zapisz'}
                                       onClickFunction={
                                           () =>
                                               saveImageCategoryTopContent(
                                                   user.accessToken,
                                                   setError,
                                                   activeCategory.id,
                                                   topHeaderRef.current.value,
                                                   topContentRef.current.value,
                                               )}
                        />
                    }
                </>
            }
        </>
    )

}

export default TopContentTab;