import React from "react";
// todo refactor
const ErrorAlert = ({title, message}) => {

    return (
        <div className="alert alert-secondary alert-icon" role="alert">
            <button className="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
            <div className="alert-icon-content">
                <h6 className="alert-heading">{title}</h6>
                {message}
            </div>
        </div>
    )
}

export default ErrorAlert;