import React, {useEffect, useRef, useState} from "react";
import Select from "../../form/select";
import PrimaryButton from "../../buttons/primaryButton";
import ProductItem from "./ProductItem";
import SendEmailChangeStatusModal from "./SendEmailChangeStatusModal";
// todo refactor
const OrderDetails = ({activeOrder}) => {

    const statusRef = useRef('')

    const [products, setProducts] = useState([]);
    const [showModalSendEmail, setShowModalSendEmail] = useState(false);

    useEffect(() => {
        let productsList = []
        activeOrder.orders.forEach(product => {
            productsList.push(
                <ProductItem
                    key={product.id}
                    product={product}
                    activeOrder={activeOrder}
                />
            )
        })
        setProducts(productsList)
    }, [activeOrder.orders])

    return (
        <div className="card">
            <SendEmailChangeStatusModal
                show={showModalSendEmail}
                setShow={setShowModalSendEmail}
                status={statusRef.current.value}
                order={activeOrder}
            />
            <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs" id="cardTab" role="tablist">
                    <li className="nav-item"><a className="nav-link active"
                                                id="overview-tab"
                                                href="#baseData"
                                                data-bs-toggle="tab" role="tab"
                                                aria-controls="overview"
                                                aria-selected="true">Dane zamówienia</a>
                    </li>
                    <li className="nav-item"><a className="nav-link" id="example-tab"
                                                href="#status"
                                                data-bs-toggle="tab" role="tab"
                                                aria-controls="example"
                                                aria-selected="false">Status</a>
                    </li>
                </ul>
            </div>
            <div className="card-body">
                <div className="tab-content" id="cardTabContent">
                    <div className="tab-pane fade show active" id="baseData" role="tabpanel"
                         aria-labelledby="overview-tab">
                        <p>Id zamówienia: {activeOrder.id}</p>
                        <p>Data zamówienia: {activeOrder.orderDate}</p>
                        <p>Realizacja: {activeOrder.express ? 'express' : 'standard'}</p>
                        <p>Email: {activeOrder.email}</p>
                        <p>Telefon: {activeOrder.phoneNumber}</p>
                        <p>Imię: {activeOrder.deliveryName}</p>
                        <p>Nazwisko: {activeOrder.deliverySurname}</p>
                        <p>Firma: {activeOrder.deliveryCompanyName}</p>
                        <p>Ulica: {activeOrder.deliveryStreet}</p>
                        <p>Nr domu: {activeOrder.deliveryHouseNumber}</p>
                        <p>Nr lokalu: {activeOrder.deliveryFlatNumber}</p>
                        <p>Kod pocztowy: {activeOrder.deliveryZipCode}</p>
                        <p>Miasto: {activeOrder.deliveryCity}</p>
                        <p>Uwagi do zamówienia: {activeOrder.comments}</p>
                        <p>Dowód sprzedaży: {activeOrder.isInvoice ? 'Faktura VAT' : 'Paragon'}</p>
                        {activeOrder.isInvoice ?
                            activeOrder.isCompany ?
                                <>
                                    <p>Nazwa Firmy: {activeOrder.invoiceCompanyName}</p>
                                    <p>NIP: {activeOrder.invoiceCompanyNip}</p>
                                    <p>Ulica na fakturze: {activeOrder.invoiceStreet}</p>
                                    <p>Nr domu na fakturze: {activeOrder.invoiceHouseNumber}</p>
                                    <p>Nr lokalu na fakturze: {activeOrder.invoiceFlatNumber}</p>
                                    <p>Kod pocztowy na fakturze: {activeOrder.invoiceZipCode}</p>
                                    <p>Miasto na fakturze: {activeOrder.invoiceCity}</p>
                                </>
                                :
                                <>
                                    <p>Imie na fakturze: {activeOrder.invoiceName}</p>
                                    <p>Nazwisko na fakturze: {activeOrder.invoiceSurname}</p>
                                    <p>Ulica na fakturze: {activeOrder.invoiceStreet}</p>
                                    <p>Nr domu na fakturze: {activeOrder.invoiceHouseNumber}</p>
                                    <p>Nr lokalu na fakturze: {activeOrder.invoiceFlatNumber}</p>
                                    <p>Kod pocztowy na fakturze: {activeOrder.invoiceZipCode}</p>
                                    <p>Miasto na fakturze: {activeOrder.invoiceCity}</p>

                                </>
                            : null
                        }
                        <p>Kurier: {activeOrder.courier}</p>
                        <p>Sposób płatności: {activeOrder.fastTransfer ? 'Przelewy24' : 'Tradycyjny przelew'}</p>
                        <p>Łączna cena: {activeOrder.totalOrdersPrice} zł</p>
                        <hr/>
                        <h3>Zamówione obrazy</h3>
                        <hr/>
                        {products}
                    </div>
                    <div className="tab-pane fade" id="status" role="tabpanel"
                         aria-labelledby="example-tab">
                        <div className="mb-3 col-4">
                            <Select id={'status'} label={'Status'} reference={statusRef}
                                    require={true}
                                    value={
                                        {
                                            'waiting for payment': 'Oczekuje na płatność',
                                            'paid': 'Zapłacone',
                                            'send': 'Wysłane',
                                            'close': 'Zamknięte',
                                        }[activeOrder.status]
                                    }
                                    optionsData={['Oczekuje na płatność', 'Zapłacone', 'Wysłane', 'Zamknięte']}/>
                        </div>
                        <PrimaryButton label={'Zmień status'}
                                       onClickFunction={() => setShowModalSendEmail(true)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default OrderDetails;