import React, {useContext} from "react";
import {FileInfoContext} from "./FileInfoContext";
// todo refactor
const PreviewImage = () => {

    const {src} = useContext(FileInfoContext);

    return (
        <img className='img-fluid col-3'
             src={src}
             alt='Podgląd grafiki'
        />
    )
};

export default PreviewImage