import React from "react";
import Modal from "../../modal/Modal";
import {useApi} from "../../../hooks/useApi";

const DeleteBlogCategoryModal = ({show, setShow, category, setBlogData}) => {

    const {getApi, putApi} = useApi()

    const deleteCategory = async () => {
        let id = null
        let data = []
        const result = await getApi('/guiComponent/BLOG/BLOG_DATA')
        if (result.isSuccess && result.data.length > 0) {
            data = JSON.parse(result.data[0].data).filter(record => record.link !== category.link)
            id = result.data[0].id
        }
        const body = {
            page: 'BLOG',
            type: 'BLOG_DATA',
            data: JSON.stringify(data)
        }
        await putApi('/guiComponent/' + id, body)
        const resultAfterDelete = await getApi('/guiComponent/BLOG/BLOG_DATA')
        if (resultAfterDelete.isSuccess && resultAfterDelete.data.length > 0) {
            setBlogData(JSON.parse(resultAfterDelete.data[0].data));
        }
        setShow(false)
    }

    return (
        show &&
        <Modal title="Czy na pewno?"
               onCancel={() => setShow(false)}
               onApprove={() => deleteCategory()}
               size="md">
            <p>Czy na pewno chcesz usunąć kategorię {category.name}?</p>
        </Modal>
    )
};

export default DeleteBlogCategoryModal;