import React, {useState} from "react";
import Card from "../card";
import {Col, Row} from "react-bootstrap";
import Input from "../../form/input";
import Space from "../../layout/Space";
import PrimaryButton from "../../buttons/primaryButton";
import {useApi} from "../../../hooks/useApi";
import Radio from "../../form/radio/Radio";

const AddDiscountCard = ({getDiscounts}) => {

    const [selectedType, setSelectedType] = useState('VOUCHER');
    const [code, setCode] = useState(null);
    const [expirationDate, setExpirationDate] = useState(null);
    const [percent, setPercent] = useState(null);
    const [maxNumberOfUses, setMaxNumberOfUses] = useState(null);
    const [definedValue, setDefinedValue] = useState(null);

    const [codeValidation, setCodeValidation] = useState(null)
    const [definedValueValidation, setDefinedValueValidation] = useState(null)
    const [expirationDateValidation, setExpirationDateValidation] = useState(null)
    const [percentValidation, setPercentValidation] = useState(null)
    const [maxNumberOfUsesValidation, setMaxNumberOfUsesValidation] = useState(null)


    const {postApi} = useApi()

    const types = [
        {
            value: "VOUCHER",
            label: "Voucher"
        },
        {
            value: "PERCENTAGE",
            label: "Rabat %"
        }
    ]

    const valid = () => {
        let isValid = true
        const emptyCode = code === "" || code === null
        if (emptyCode) {
            isValid = false
            setCodeValidation("Kod jest wymagany")
        }
        if (selectedType === "VOUCHER") {
            const emptyDefinedValue = definedValue === "" || definedValue === null
            if (emptyDefinedValue) {
                isValid = false
                setDefinedValueValidation("Wartość rabatu jest wymagana")
            }
        } else {
            const emptyExpirationDate = expirationDate === "" || expirationDate === null
            if (emptyExpirationDate) {
                isValid = false
                setExpirationDateValidation("Data ważności jest wymagana")
            }
            const emptyPercent = percent === "" || percent === null
            if (emptyPercent) {
                isValid = false
                setPercentValidation("Procent rabatu jest wymagany")
            }
            const emptyMaxNumberOfUses = maxNumberOfUses === "" || maxNumberOfUses === null
            if (emptyMaxNumberOfUses) {
                isValid = false
                setMaxNumberOfUsesValidation("Liczba kuponów jest wymagana")
            }
        }
        return isValid;
    }

    const clearValidation = () => {
        setCodeValidation(null)
        setDefinedValueValidation(null)
        setExpirationDateValidation(null)
        setPercentValidation(null)
        setMaxNumberOfUsesValidation(null)
    }

    const clearValues = () => {
        setCode(null)
        setExpirationDate(null)
        setPercent(null)
        setMaxNumberOfUses(null)
        setDefinedValue(null)
    }

    const onClickAdd = async () => {
        if (valid()) {
            const body = {
                code: code,
                expirationDate: expirationDate,
                discountType: selectedType,
                percent: percent,
                maxNumberOfUses: maxNumberOfUses,
                numberOfUses: 0,
                definedValue: definedValue,
                currentValue: definedValue
            }
            await postApi('/discount/', body)
            clearValidation()
            clearValues()
            getDiscounts()
        }
    }

    return (
        <Card cardName='Dodaj nowy rabat'>
            <Row>
                <Col className="d-flex justify-content-center">
                    <strong>Typ rabatu:</strong>
                </Col>
            </Row>
            <Row>
                <Col className="d-sm-flex justify-content-center">
                    <Radio options={types} value={selectedType} setValue={setSelectedType}/>
                </Col>
            </Row>
            <Space space={30}/>
            <Row>
                <Col xs={6}>
                    <Input
                        label='Kod'
                        type='text'
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                    />
                    {codeValidation ? <span className="text-danger">{codeValidation}</span> : null}
                </Col>
                {selectedType === 'VOUCHER' &&
                    <Col xs={6}>
                        <Input
                            label='Wartość rabatu'
                            type='number'
                            value={definedValue}
                            onChange={(e) => setDefinedValue(e.target.value)}
                        />
                        {definedValueValidation ? <span className="text-danger">{definedValueValidation}</span> : null}
                    </Col>
                }
                {selectedType === 'PERCENTAGE' &&
                    <Col>
                        <Input
                            label='Data ważności'
                            type='datetime-local'
                            value={expirationDate}
                            onChange={(e) => setExpirationDate(e.target.value)}
                        />
                        {expirationDateValidation ?
                            <span className="text-danger">{expirationDateValidation}</span> : null}
                    </Col>
                }
            </Row>
            {selectedType === 'PERCENTAGE' &&
                <>
                    <Space space={30}/>
                    <Row>
                        <Col>
                            <Input
                                label='Procent rabatu'
                                type='number'
                                value={percent}
                                onChange={(e) => setPercent(e.target.value)}
                            />
                            {percentValidation ? <span className="text-danger">{percentValidation}</span> : null}
                        </Col>
                        <Col>
                            <Input
                                label='Liczba kuponów'
                                type='number'
                                value={maxNumberOfUses}
                                onChange={(e) => setMaxNumberOfUses(e.target.value)}
                            />
                            {maxNumberOfUsesValidation ?
                                <span className="text-danger">{maxNumberOfUsesValidation}</span> : null}
                        </Col>
                    </Row>
                </>
            }
            <Space space={30}/>
            <Row>
                <Col>
                    <PrimaryButton label='Dodaj' onClickFunction={() => onClickAdd()}/>
                </Col>
            </Row>
        </Card>
    )
};

export default AddDiscountCard;