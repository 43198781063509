import React, {useEffect, useState} from "react";
import PrimaryButton from "../../buttons/primaryButton";
import Spinner from "../../spinner";
// todo refactor
const Card = ({children, cardName, size, showHeaderButton, buttonLabel, onClickButton, onStart}) => {

    const [isLoad, setLoad] = useState(true);

    useEffect(() => {
        if (onStart !== undefined) {
            onStart()
        }
        setLoad(false)
    }, [])

    return (
        <div className={"col-lg-" + size + " mb-4"}>
            <div className="card card-header-actions">
                <div className="card-header">
                    {cardName}
                    {showHeaderButton ?
                        <PrimaryButton label={buttonLabel}
                                       onClickFunction={onClickButton}/>
                        : null
                    }
                </div>
                <div className="card-body d-flex flex-column justify-content-center">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        <>
                            {children}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Card;