import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../../context/AppContext";
import {getAllTemplatesImage} from "./functions";
import ImageTemplate from "../imageTemplate";
// todo refactor
const TemplatesBox = ({update, setUpdate}) => {

    const {user, setError} = useContext(AppContext);

    const [data, setData] = useState([]);
    const [templatesOwn, setTemplatesOwn] = useState([])
    const [templatesGraphics, setTemplatesGraphics] = useState([])
    const [templatesIrregular, setTemplatesIrregular] = useState([])
    const [templatesManyGraphics, setTemplatesManyGraphics] = useState([])

    useEffect(() => {
        if (update) {
            void getAllTemplatesImage({
                accessToken: user.accessToken,
                setError: setError,
                setData: setData
            })
        }
        setUpdate(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])

    useEffect(() => {
        const own = []
        const graphics = []
        const irregular = []
        const manyGraphics = []
        data.forEach(template => {
            if (template.type === "Własne zdjęcie") {
                own.push(<ImageTemplate {...template} setUpdate={setUpdate} key={template.id}/>)
            } else if (template.type === "Grafika") {
                graphics.push(<ImageTemplate {...template} setUpdate={setUpdate} key={template.id}/>)
            } else if (template.type === "Nieregularna grafika") {
                irregular.push(<ImageTemplate {...template} setUpdate={setUpdate} key={template.id}/>)
            } else  if (template.type === "Wiele grafik") {
                manyGraphics.push(<ImageTemplate {...template} setUpdate={setUpdate} key={template.id}/>)
            }
        })
        setTemplatesOwn(own)
        setTemplatesGraphics(graphics)
        setTemplatesIrregular(irregular)
        setTemplatesManyGraphics(manyGraphics)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <>
            <h5 className="card-title pb-3">Własne zdjęcie:</h5>
            {templatesOwn}
            <h5 className="card-title pb-3">Grafika:</h5>
            {templatesGraphics}
            <h5 className="card-title pb-3">Nieregularna grafika:</h5>
            {templatesIrregular}
            <h5 className="card-title pb-3">Wiele grafik:</h5>
            {templatesManyGraphics}
        </>
    )

}

export default TemplatesBox;