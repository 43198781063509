import React from "react";
import {Col, Row} from "react-bootstrap";
import Input from "../../form/input";
import DangerButton from "../../buttons/dangerButton";
import Space from "../../layout/Space";
import PrimaryButton from "../../buttons/primaryButton";

const ListRecords = ({recordsList, setRecordsList}) => {

    const deleteRecord = (index) => {
        const newList = [...recordsList];
        newList.splice(index, 1);
        setRecordsList(newList);
    };

    const changeRecordValue = (index, value) => {
        const newList = recordsList.map((record, i) => {
            if (i === index) {
                return {...record, name: value};
            }
            return record;
        });
        setRecordsList(newList);
    };

    return (
        <>
            {recordsList.map((item, index) => (
                <Row key={item.id} className='mt-3'>
                    <Col xs={9}>
                        <Input label='Element listy' type='text' value={item.name}
                               onChange={(e) => changeRecordValue(index, e.target.value)}/>
                    </Col>
                    <Col xs={1} className="mt-4 d-sm-flex align-content-end">
                        <DangerButton label="Usuń"
                                      onClickFunction={() => deleteRecord(index)}/>
                    </Col>
                </Row>
            ))}
            <Space space={15}/>
            <PrimaryButton label='Dodaj element'
                           onClickFunction={() => setRecordsList([...recordsList, {
                               id: Math.random().toString(16),
                               name: ""
                           }])}/>
        </>
    )
};

export default ListRecords;