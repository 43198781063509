import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../../../context/AppContext";
import {ImageTemplateContext} from "../../../../context/ImageTemplateContext";
import {addSize, deleteSize, updateTemplateImage} from "./functions";
import Input from "../../../form/input";
import PrimaryButton from "../../../buttons/primaryButton";
import FeatherIcon from "feather-icons-react";
import PriceInput from "../../../form/priceInput";
// todo refactor
const OwnPhotoEditorTab = () => {

    const {user, setError} = useContext(AppContext);
    const {
        id,
        editorSizes,
        setUpdate,
        editorColor,
        minWidth,
        minHeight,
        editorFieldWidth,
        editorFieldHeight,
    } = useContext(ImageTemplateContext)

    const widthRef = useRef('')
    const heightRef = useRef('')
    const editorColorRef = useRef('')
    const minWidthRef = useRef('')
    const minHeightRef = useRef('')
    const editorFieldWidthRef = useRef('')
    const editorFieldHeightRef = useRef('')

    const [sizeRows, setSizeRows] = useState([])
    const [price, setPrice] = useState(null)

    useEffect(() => {
        const rows = []
        editorSizes.forEach(row => {
            rows.push(
                <tr key={row.id}>
                    <td>{row.width}</td>
                    <td>{row.height}</td>
                    <td>{row.price}</td>
                    <td>
                        <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Usuń"
                                onClick={() => deleteSize({
                                    id: id,
                                    accessToken: user.accessToken,
                                    setError: setError,
                                    setUpdate: setUpdate,
                                    body: {
                                        id: row.id,
                                        width: row.width,
                                        height: row.height,
                                        price: row.price,
                                    }
                                })}>
                            <FeatherIcon icon="trash"/>
                        </button>
                    </td>
                </tr>
            )
        })
        setSizeRows(rows)
    }, [editorSizes])

    return (
        <>
            <h3>Dostępne rozmiary</h3>
            <div className="table-responsive">
                <table className="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th>Szerokość</th>
                        <th>Wysokość</th>
                        <th>Cena</th>
                        <th>Akcje</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sizeRows}
                    </tbody>
                </table>
            </div>
            <div className="mb-3">
                <Input id={'width'} label={'Szerokość'} type={'number'}
                       require={true} reference={widthRef}/>
            </div>
            <div className="mb-3">
                <Input id={'height'} label={'Wysokość'} type={'number'}
                       require={true} reference={heightRef}/>
            </div>
            <div className="mb-3">
                <PriceInput id={'price'} label={'Cena'} value={null} setValue={setPrice}/>
            </div>
            <PrimaryButton label={'Dodaj rozmiar'}
                           onClickFunction={
                               () => addSize({
                                   id: id,
                                   body: {
                                       width: widthRef.current.value,
                                       height: heightRef.current.value,
                                       price: price,
                                   },
                                   accessToken: user.accessToken,
                                   setError: setError,
                                   setUpdate: setUpdate
                               })}
            />
            <hr/>
            <div className="mb-3">
                <Input id={'editorColor'} label={'Kolor edytora'} type={'text'}
                       value={editorColor} reference={editorColorRef}/>
            </div>
            <div className="mb-3">
                <Input id={'minWidth'} label={'Minimalna szerokość zdjęcia'} type={'number'}
                       value={minWidth} reference={minWidthRef}/>
            </div>
            <div className="mb-3">
                <Input id={'minHeight'} label={'Minimalna wysokość zdjęcia'} type={'number'}
                       value={minHeight} reference={minHeightRef}/>
            </div>
            <div className="mb-3">
                <Input id={'editorFieldWidth'} label={'Szerokość edytora'} type={'number'}
                       value={editorFieldWidth} reference={editorFieldWidthRef}/>
            </div>
            <div className="mb-3">
                <Input id={'editorFieldHeight'} label={'Wysokość edytora'} type={'number'}
                       value={editorFieldHeight} reference={editorFieldHeightRef}/>
            </div>
            <PrimaryButton label={'Zapisz'}
                           onClickFunction={
                               () => updateTemplateImage(
                                   {
                                       url: 'updateEditor',
                                       id: id,
                                       setError: setError,
                                       accessToken: user.accessToken,
                                       body: {
                                           editorColor: editorColorRef.current.value,
                                           minWidth: minWidthRef.current.value,
                                           minHeight: minHeightRef.current.value,
                                           editorFieldWidth: editorFieldWidthRef.current.value,
                                           editorFieldHeight: editorFieldHeightRef.current.value,
                                       }
                                   }
                               )}
            />
        </>
    )
}

export default OwnPhotoEditorTab;