import React from "react";
import UpdateAppCard from "../../cards/updateAppCard";
import BanerCard from "../../cards/banerCard";
import CompanyCard from "../../cards/companyCard";
import UploadPhotosCard from "../../cards/uploadPhotosCard";
import TabWrapper from "../TabWrapper";

const Administration = () => {

    return (
        <TabWrapper>
            <UpdateAppCard/>
            <BanerCard/>
            <CompanyCard/>
            <UploadPhotosCard/>
        </TabWrapper>
    )
}

export default Administration;