import React, {useContext} from "react";
import BaseDataTab from "./baseDataTab";
import TopContentTab from "./TopContentTab";
import ExtraContentTab from "./ExtraContentTab";
import TextBoxCard from "../textBoxCard/TextBoxCard";
import {ImageCardContext} from "../../../context/ImageCardContext";

const CategorySubDetailsView = () => {

    const {activeSubCategory} = useContext(ImageCardContext);

    return (
        <div className="card">
            <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs" id="cardTab" role="tablist">
                    <li className="nav-item"><a className="nav-link active"
                                                id="overview-tab"
                                                href="#base"
                                                data-bs-toggle="tab" role="tab"
                                                aria-controls="overview"
                                                aria-selected="true">Podstawowe dane</a>
                    </li>
                    <li className="nav-item"><a className="nav-link" id="example-tab"
                                                href="#topContent"
                                                data-bs-toggle="tab" role="tab"
                                                aria-controls="example"
                                                aria-selected="false">Główna treść</a>
                    </li>
                    <li className="nav-item"><a className="nav-link" id="example-tab"
                                                href="#extraContent"
                                                data-bs-toggle="tab" role="tab"
                                                aria-controls="example"
                                                aria-selected="false">Dodatkowa treść</a>
                    </li>
                    <li className="nav-item"><a className="nav-link" id="example-tab"
                                                href="#textBoxex"
                                                data-bs-toggle="tab" role="tab"
                                                aria-controls="example"
                                                aria-selected="false">Boxy z treścią</a>
                    </li>
                </ul>
            </div>
            <div className="card-body">
                <div className="tab-content" id="cardTabContent">
                    <div className="tab-pane fade show active" id="base" role="tabpanel"
                         aria-labelledby="overview-tab">
                        <BaseDataTab isSubCategory={true}/>
                    </div>
                    <div className="tab-pane fade" id="topContent" role="tabpanel"
                         aria-labelledby="example-tab">
                        <TopContentTab isSubCategory={true}/>
                    </div>
                    <div className="tab-pane fade" id="extraContent" role="tabpanel"
                         aria-labelledby="example-tab">
                        <ExtraContentTab isSubCategory={true}/>
                    </div>
                    <div className="tab-pane fade" id="textBoxex" role="tabpanel"
                         aria-labelledby="example-tab">
                        <TextBoxCard page={'SUB_CATEGORY-' + activeSubCategory.id}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategorySubDetailsView;