import React from "react";
// todo refactor
const Spinner = () => {

    return (
        <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )
}

export default Spinner;