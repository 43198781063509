import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../context/AppContext";
import {addExtraContent, getContactData} from "./Functions";
import Spinner from "../../spinner";
import PrimaryButton from "../../buttons/primaryButton";
import ExtraContentBox from "./ExtraContentBox";
// todo refactor
const ExtraContentCard = () => {

    const {user, setError} = useContext(AppContext);

    const [isLoad, setLoad] = useState(true);
    const [data, setData] = useState({})

    const [contentsBox, setContentsBox] = useState([])

    useEffect(() => {
        void getContactData(user.accessToken, setLoad, setError, setData)
    }, [])

    useEffect(() => {
        if (data.extraContents !== undefined) {
            const boxs = []
            data.extraContents.forEach(item => {
                boxs.push(<ExtraContentBox data={item} setData={setData} setLoad={setLoad} key={item.id}/>)
            })
            setContentsBox(boxs);
        }
    }, [data])

    return (
        <div className="col-lg-6 mb-4">
            <div className="card card-header-actions">
                <div className="card-header">
                    Dodatkowa treść strony
                </div>
                <div className="card-body d-flex flex-column justify-content-center">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        <>
                            <PrimaryButton label={'Dodaj dodatkową treść'}
                                           onClickFunction={() =>
                                               addExtraContent(
                                                   user.accessToken,
                                                   setLoad,
                                                   setError,
                                                   setData
                                               )
                                           }/>
                            <hr/>
                            {contentsBox}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default ExtraContentCard;