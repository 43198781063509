import React, {useContext} from "react";
import {FileInfoContext} from "./FileInfoContext";
// todo refactor
const FileName = () => {

    const {fileName} = useContext(FileInfoContext)

    return (
        <p className='mt-3 p-1'>{fileName}</p>
    );
}

export default FileName;