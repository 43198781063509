import React from "react";
import Content from "./Content";
// todo refactor
const Contents = ({data, getContent}) => {

    return (
        data.map(item => {
            return (
                <Content key={item.id} item={item} getContent={getContent}/>
            )
        })
    );
};

export default Contents;