import React, {useState} from "react";
import {Card as ReactCard, Col, Row} from "react-bootstrap";
import SecondaryButton from "../../buttons/SecondaryButton";
import DangerButton from "../../buttons/dangerButton";
import DeleteDiscountModal from "./DeleteDiscountModal";
import EditDiscountModal from "./EditDiscountModal";

const DiscountRecord = ({discount, getDiscounts}) => {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const getType = () => {
        if (discount.discountType === 'VOUCHER') {
            return 'Voucher'
        } else {
            return 'Rabat %'
        }
    }

    return (
        <ReactCard className="mb-3 shadow-sm" key={discount.id}>
            <DeleteDiscountModal
                discount={discount}
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                getDiscounts={getDiscounts}
            />
            <EditDiscountModal
                show={showEditModal}
                setShow={setShowEditModal}
                discount={discount}
                getDiscounts={getDiscounts}
            />
            <ReactCard.Body>
                <Row>
                    <Col xs={12} md={9}>
                        <Row>
                            <Col xs={6}>
                                <p className="text m-0">Kod: {discount.code}</p>
                                <p className="text m-0">Typ: {getType()}</p>
                            </Col>
                            {discount.discountType === 'VOUCHER' &&
                                <Col xs={6}>
                                    <p className="text m-0">Wartość: {discount.definedValue}</p>
                                    <p className="text m-0">Aktualna do wykorzystania wartość: {discount.currentValue}</p>
                                </Col>
                            }
                            {discount.discountType === 'PERCENTAGE' &&
                                <Col xs={6}>
                                    <p className="text m-0">Data ważności: {discount.expirationDate}</p>
                                    <p className="text m-0">Procent rabatu: {discount.percent}</p>
                                    <p className="text m-0">Liczba użyć: {discount.numberOfUses}</p>
                                </Col>
                            }
                        </Row>
                    </Col>
                    <Col xs={12} md={3}>
                        <Row className="justify-content-end mt-3">
                            <Col xs={6} className="d-flex justify-content-end">
                                <SecondaryButton label="Edytuj"
                                                 onClickFunction={() => setShowEditModal(true)}
                                />
                            </Col>
                            <Col xs={6}>
                                <DangerButton label="Usuń"
                                              onClickFunction={() => setShowDeleteModal(true)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ReactCard.Body>
        </ReactCard>
    )
};

export default DiscountRecord;