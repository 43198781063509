import React, {useState} from "react";
import {onClick} from './Functions'
// todo refactor
const PrimaryButton = ({label, type, onClickFunction, loading}) => {

    const [isLoad, setLoad] = useState(false);

    return (
        <button className="btn btn-primary"
                type={type ? type : 'button'}
                disabled={isLoad || loading}
                onClick={() => onClick(setLoad, onClickFunction)}
        >
            {isLoad || loading ?
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                : null}
            {label}
        </button>
    )
}

export default PrimaryButton;