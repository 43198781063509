import React, {useContext} from "react";
import Image from "../../../assets/img/illustrations/processing.svg";
import PrimaryButton from "../../buttons/primaryButton";
import {updateApp} from "./Functions";
import {AppContext} from "../../../context/AppContext";
// todo refactor
const UpdateAppCard = () => {

    const {user, setError} = useContext(AppContext);

    return (
        <div className="col-lg-4 mb-4">
            <div className="card mb-4">
                <div className="card-body text-center p-5">
                    <img className="img-fluid mb-5" src={Image} alt={'update app'}/>
                    <h4>Aktualizajca aplikacji</h4>
                    <PrimaryButton label={'Aktualizuj'}
                                   onClickFunction={()=> updateApp(user.accessToken, setError)}/>
                </div>
            </div>
        </div>
    )
}

export default UpdateAppCard;