import React from "react";
import FeatherIcon from "feather-icons-react";

const SideBar = ({setActiveTab}) => {

    return (
        <div id="layoutSidenav_nav">
            <nav className="sidenav shadow-right sidenav-light">
                <div className="sidenav-menu">
                    <div className="nav accordion" id="accordionSidenav">
                        <div className="sidenav-menu-heading">Menu</div>
                        <div className="nav-link" onClick={()=> setActiveTab('Pulpit')}>
                            <div className="nav-link-icon">
                                <FeatherIcon icon="activity"/>
                            </div>
                            Pulpit
                        </div>
                        <div className="sidenav-menu-heading">CMS</div>
                        <div className="nav-link collapsed" data-bs-toggle="collapse"
                             data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
                            <div className="nav-link-icon">
                                <FeatherIcon icon="grid"/>
                            </div>
                            Strony
                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                        </div>
                        <div className="collapse" id="collapsePages" data-bs-parent="#accordionSidenav">
                            <nav className="sidenav-menu-nested nav accordion" id="accordionSidenavPagesMenu">
                                <div className="nav-link" onClick={()=> setActiveTab('Strona główna')}>Strona główna</div>
                                <div className="nav-link" onClick={()=> setActiveTab('Obrazy')}>Obrazy</div>
                                <div className="nav-link" onClick={()=> setActiveTab('Projekt')}>Projekt</div>
                                <div className="nav-link" onClick={()=> setActiveTab('Szablony obrazów')}>Szablony obrazów</div>
                                <div className="nav-link" onClick={()=> setActiveTab('Blog')}>Blog</div>
                                <div className="nav-link" onClick={()=> setActiveTab('Wpisy na blogu')}>Wpisy na blogu</div>
                                <div className="nav-link" onClick={()=> setActiveTab('O nas')}>O nas</div>
                                <div className="nav-link" onClick={()=> setActiveTab('Kontakt')}>Kontakt</div>
                                <div className="nav-link" onClick={()=> setActiveTab('Koszyk')}>Koszyk</div>
                                <div className="nav-link" onClick={()=> setActiveTab('Stopka')}>Stopka</div>
                                <div className="nav-link" onClick={()=> setActiveTab('Rabaty')}>Rabaty</div>
                            </nav>
                        </div>
                        <div className="sidenav-menu-heading">Użytkownicy</div>
                        <div className="nav-link" onClick={()=> setActiveTab('Wejścia na stronę')}>
                            <div className="nav-link-icon">
                                <FeatherIcon icon="users"/>
                            </div>
                            Wejścia na stronę
                        </div>
                        <div className="sidenav-menu-heading">Admin</div>
                        <div className="nav-link" onClick={()=> setActiveTab('Zamówienia')}>
                            <div className="nav-link-icon">
                                <FeatherIcon icon="list"/>
                            </div>
                            Zamówienia
                        </div>
                        <div className="nav-link" onClick={()=> setActiveTab('Administracja')}>
                            <div className="nav-link-icon">
                                <FeatherIcon icon="settings"/>
                            </div>
                            Administracja
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default SideBar