import {CallSecurityAPI} from "../../../api/API";
import FeatherIcon from "feather-icons-react";
import React from "react";
import GetPhotoButton from "../../buttons/getPhotoButton";
// todo refactor
export const getClientContexts = async (setLoad, user, setError, setSites, setShowDetails, setDetailsPhotos) => {
    const result = await CallSecurityAPI('/clientContext/all', null, 'GET', user.accessToken)
    if (result.isSuccess) {
        const sites = []
        result.data.forEach(site => {
            sites.push(
                <tr key={site.id}>
                    <td>{site.id}</td>
                    <td>{site.photos.length}</td>
                    <td>
                        <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Zobacz szczegóły"
                                onClick={() => prepareContextDetails(setShowDetails, setDetailsPhotos, site.photos, site.id)}>
                            <FeatherIcon icon="eye"/>
                        </button>
                    </td>
                </tr>
            )
        })
        setSites(sites)
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania listy wejść na stronę"
        })
    }
    setLoad(false)
}

const prepareContextDetails = (setShowDetails, setDetailsPhotos, photos, contextId) => {
    setShowDetails(true)
    const photosButtons = []
    photos.forEach(photo => {
        photosButtons.push(
            <GetPhotoButton
                key={photo.id}
                url={'/photo/download/cms/' + photo.id + '/' + contextId}
                id={photo.id}
                name={photo.name}
                contextId={contextId}
            />
        )
    })
    setDetailsPhotos(photosButtons)
}