import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import UploadFile from "../../form/uploadFile/UploadFile";
import Input from "../../form/input";
import {useApi} from "../../../hooks/useApi";
import Space from "../../layout/Space";
import PrimaryButton from "../../buttons/primaryButton";

const BlogEntryImages = ({number, activeEntry, setBlogData, imageFile, imageAlt}) => {

        const [file, setFile] = useState(imageFile)
        const [alt, setAlt] = useState(imageAlt)
        const [fileBlob, setFileBlob] = useState(null)
        const [fileName, setFileName] = useState(null)

        const {getApi, postApi, putApi, deleteApi} = useApi()

        const onDeleteFile = async () => {
            let id = null
            let data = []
            const result = await getApi('/guiComponent/BLOG/BLOG_DATA')
            if (result.isSuccess && result.data.length > 0) {
                for (const category of JSON.parse(result.data[0].data)) {
                    let entries = []
                    for (const entry of category.entry) {
                        if (entry.link === activeEntry.link) {
                            let images = []
                            for (const image of entry.images) {
                                if (image.fileId === file.fileId) {
                                    await deleteApi("/appFile/" + file?.id)
                                    setFile(null)
                                } else {
                                    images.push(image)
                                }
                            }
                            entry.images = images
                        }
                        entries.push(entry)
                    }
                    category.entry = entries
                    data.push(category)
                }
                id = result.data[0].id
            }
            const body = {
                page: 'BLOG',
                type: 'BLOG_DATA',
                data: JSON.stringify(data)
            }
            await putApi('/guiComponent/' + id, body)
            const resultAfterSave = await getApi('/guiComponent/BLOG/BLOG_DATA')
            if (resultAfterSave.isSuccess && resultAfterSave.data.length > 0) {
                setBlogData(JSON.parse(resultAfterSave.data[0].data));
            }
        }

        const onUploadFile = (event) => {
            const file = event.target.files[0];
            setFileName(file.name)
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64String = event.target.result;
                setFileBlob(base64String.split(',')[1])
            };
            reader.readAsDataURL(file);
        }

        const onSave = async () => {
            let id = null
            let data = []
            const result = await getApi('/guiComponent/BLOG/BLOG_DATA')
            if (result.isSuccess && result.data.length > 0) {
                for (const category of JSON.parse(result.data[0].data)) {
                    let entries = []
                    for (let entry of category.entry) {
                        if (entry.link === activeEntry.link) {
                            const fileBody = {
                                "id": null,
                                "fileName": fileName,
                                "fileId": null,
                                "extension": '.' + fileName.split('.').pop(),
                                "fileBlob": fileBlob
                            }
                            const resultImagePost = await postApi("/appFile/", fileBody)
                            setFile(resultImagePost.data)
                            if (entry.images) {
                                entry.images?.push({...resultImagePost.data, imageAlt: alt})
                            } else {
                                entry = {
                                    ...entry,
                                    images: [{
                                        ...resultImagePost.data, imageAlt: alt
                                    }]
                                }
                            }
                        }
                        entries.push(entry)
                    }
                    category.entry = entries
                    data.push(category)
                }
                id = result.data[0].id
            }
            const body = {
                page: 'BLOG',
                type: 'BLOG_DATA',
                data: JSON.stringify(data)
            }
            await putApi('/guiComponent/' + id, body)
            const resultAfterSave = await getApi('/guiComponent/BLOG/BLOG_DATA')
            if (resultAfterSave.isSuccess && resultAfterSave.data.length > 0) {
                setBlogData(JSON.parse(resultAfterSave.data[0].data));
            }
        }

        return (
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <UploadFile file={file}
                                        label={"Grafika wpisu - " + number}
                                        onDelete={() => onDeleteFile()}
                                        onChange={(e) => onUploadFile(e)}
                            />
                        </Col>
                        <Col>
                            <Input
                                label='Alt grafiki'
                                type='text'
                                value={alt}
                                onChange={(e) => setAlt(e.target.value)}
                            />
                        </Col>
                        <Col xs={2}>
                            <PrimaryButton label={'Zapisz'}
                                           onClickFunction={() => onSave()}
                            />
                        </Col>
                    </Row>
                </Col>
                <Space space={30}/>
            </Row>
        )
    }
;

export default BlogEntryImages;