import React, {useEffect, useState} from "react";
import Card from "../card";
import {Col, Row} from "react-bootstrap";
import Input from "../../form/input";
import Space from "../../layout/Space";
import PrimaryButton from "../../buttons/primaryButton";
import {useApi} from "../../../hooks/useApi";
import Select from "../../form/select";

const BlogEntryAddCard = ({blogData, setBlogData}) => {

    const [name, setName] = useState(null);
    const [nameValidation, setNameValidation] = useState(null)
    const [link, setLink] = useState(null);
    const [linkValidation, setLinkValidation] = useState(null)
    const [selectCategory, setSelectCategory] = useState(null)
    const [categoryValidation, setCategoryValidation] = useState(null)
    const [categoryOptions, setCategoryOptions] = useState(blogData.map(category => {
        return category.name
    }))

    useEffect(() => {
        setCategoryOptions(blogData.map(category => {
            return category.name
        }))
    }, [blogData])

    const {getApi, putApi} = useApi()

    const valid = (data) => {
        let isValid = true
        const emptyName = name === "" || name === null
        if (emptyName) {
            isValid = false
            setNameValidation("Nazwa jest wymagana")
        } else {
            let nameExist = false
            data.forEach(cat => {
                cat.entry.forEach(entry => {
                    if (entry.name === name) {
                        isValid = false
                        nameExist = true
                        setNameValidation("Podana nazwa już istnieje")
                    }
                })
            })
            if (!nameExist) {
                setNameValidation(null)
            }
        }

        const emptyLink = link === "" || link === null
        if (emptyLink) {
            isValid = false
            setLinkValidation("Link jest wymagany")
        } else {
            let linkExist = false
            data.forEach(cat => {
                cat.entry.forEach(entry => {
                    if (entry.link === link) {
                        isValid = false
                        linkExist = true
                        setLinkValidation("Podany link już istnieje")
                    }
                })
            })
            if (!linkExist) {
                setLinkValidation(null)
            }
        }

        const dontSelectedCategory = selectCategory === "" || selectCategory === null
        if (dontSelectedCategory) {
            isValid = false
            setCategoryValidation("Wybierz kategorię")
        } else {
            setCategoryValidation(null)
        }

        return isValid;
    }

    const onClickAdd = async () => {
        let id = null
        let data = []
        const result = await getApi('/guiComponent/BLOG/BLOG_DATA')
        if (result.isSuccess && result.data.length > 0) {
            data = JSON.parse(result.data[0].data)
            id = result.data[0].id
        }
        if (valid(data)) {
            data.find(record => record.link === selectCategory.link).entry.push({
                name: name,
                link: link,
                file: null,
                cardTitle: null,
                cardDescription: null,
                dateAdded: new Date(),
                entryFile: null,
                title: null,
                content: null,
                images: []
            })
            const body = {
                page: 'BLOG',
                type: 'BLOG_DATA',
                data: JSON.stringify(data)
            }
            await putApi('/guiComponent/' + id, body)
            const resultAfterAdd = await getApi('/guiComponent/BLOG/BLOG_DATA')
            if (resultAfterAdd.isSuccess && resultAfterAdd.data.length > 0) {
                setBlogData(JSON.parse(resultAfterAdd.data[0].data));
            }
        }
    }

    return (
        <Card cardName='Dodaj nowy wpis'>
            <Row>
                <Col>
                    <Input
                        label='Nazwa'
                        type='text'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    {nameValidation ? <span className="text-danger">{nameValidation}</span> : null}
                </Col>
            </Row>
            <Space space='30'/>
            <Row>
                <Col>
                    <Input
                        label='Link'
                        type='text'
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    />
                    {linkValidation ? <span className="text-danger">{linkValidation}</span> : null}
                </Col>
            </Row>
            <Space space='30'/>
            <Row>
                <Col>
                    <Select label='Kategoria'
                            optionsData={[''].concat(categoryOptions)}
                            onChange={(e) => setSelectCategory(blogData.find(record => record.name === e.target.value))}
                    />
                    {categoryValidation ? <span className="text-danger">{categoryValidation}</span> : null}
                </Col>
            </Row>
            <Space/>
            <Row>
                <Col>
                    <PrimaryButton label='Dodaj' onClickFunction={() => onClickAdd()}/>
                </Col>
            </Row>
        </Card>
    )
};

export default BlogEntryAddCard;