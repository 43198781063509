import {CallAPI, CallAPIWithFile} from "../../../api/API";
// todo refactor
export const getAboutData = async (setLoad, setError, setData) => {
    const result = await CallAPI('/aboutPage', null, 'GET')
    if (result.isSuccess) {
        setData(result.data)
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania treści strony"
        })
    }
    setLoad(false)
}

export const saveAboutData = async (accessToken,
                                    setError,
                                    mainHeader,
                                    subHeaderTop,
                                    descriptionTop,
                                    contentTop,
                                    buttonTopLabel,
                                    buttonTopLink,
                                    movieLink,
                                    subHeaderBottom,
                                    descriptionBottom,
                                    contentBottom,
                                    buttonBottomOneLabel,
                                    buttonBottomOneLink,
                                    buttonBottomTwoLabel,
                                    buttonBottomTwoLink,
                                    fileOne,
                                    fileTwo,
                                    imageOneAlt,
                                    imageTwoAlt) => {

    const body = new FormData();
    body.append('mainHeader', mainHeader)
    body.append('subHeaderTop', subHeaderTop)
    body.append('descriptionTop', descriptionTop)
    body.append('contentTop', contentTop)
    body.append('buttonTopLabel', buttonTopLabel)
    body.append('buttonTopLink', buttonTopLink)
    body.append('movieLink', movieLink)
    body.append('subHeaderBottom', subHeaderBottom)
    body.append('descriptionBottom', descriptionBottom)
    body.append('contentBottom', contentBottom)
    body.append('buttonBottomOneLabel', buttonBottomOneLabel)
    body.append('buttonBottomOneLink', buttonBottomOneLink)
    body.append('buttonBottomTwoLabel', buttonBottomTwoLabel)
    body.append('buttonBottomTwoLink', buttonBottomTwoLink)
    body.append('imageOneAlt', imageOneAlt)
    body.append('imageTwoAlt', imageTwoAlt)

    const result = await CallAPIWithFile('/aboutPage/add', body, accessToken)
    if (!result.isSuccess) {
        setError({
            show: true,
            title: "Bląd zapisania",
            message: "Błąd podczas zapisywania treści strony"
        })
    }

    if (fileOne !== undefined) {
        const bodyFileOne = new FormData();
        bodyFileOne.append('file', fileOne)
        const resultImage = await CallAPIWithFile('/aboutPage/addImage/1', bodyFileOne, accessToken)
        if (!resultImage.isSuccess) {
            setError({
                show: true,
                title: "Bląd zapisania",
                message: "Błąd podczas zapisywania grafiki 1"
            })
        }
    }

    if (fileTwo !== undefined) {
        const bodyFileTwo = new FormData();
        bodyFileTwo.append('file', fileTwo)
        const resultImage = await CallAPIWithFile('/aboutPage/addImage/2', bodyFileTwo, accessToken)
        if (!resultImage.isSuccess) {
            setError({
                show: true,
                title: "Bląd zapisania",
                message: "Błąd podczas zapisywania grafiki 2"
            })
        }
    }

}