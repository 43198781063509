import React, {useRef, useState} from "react";
import {useApi} from "../../../hooks/useApi";
import Card from "../card";
import FeatherIcon from "feather-icons-react";
import PrimaryButton from "../../buttons/primaryButton";
import Input from "../../form/input";
import Details from "./Details";
// todo refactor
const FrameCard = () => {

    const {getApi, postFormData, deleteApi} = useApi();

    const [frames, setFrames] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [activeFrame, setActiveFrame] = useState({});

    const nameRef = useRef('')

    const onStart = async () => {
        const result = await getApi('/frame/all')
        if (result.isSuccess) {
            let framesList = []
            result.data.forEach(frame => {
                framesList.push(
                    <tr key={frame.id}>
                        <td>{frame.name}</td>
                        <td>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Zobacz szczegóły"
                                    onClick={() => onClickShowMore(frame)}
                            >
                                <FeatherIcon icon="eye"/>
                            </button>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Usuń"
                                    onClick={() => deleteFrame(frame.id)}
                            >
                                <FeatherIcon icon="trash"/>
                            </button>
                        </td>
                    </tr>
                )
                setFrames(framesList)
            })
        }
    };

    const addFrame = async () => {
        const body = new FormData();
        body.append('name', nameRef.current.value)
        await postFormData('/frame/add', body)
        await onStart()
    }

    const deleteFrame = async (id) => {
        const success = await deleteApi('/frame/' + id)
        if (success) {
            await onStart()
        }
    }

    const onClickShowMore = (frame) => {
        setShowDetails(true);
        setActiveFrame(frame);
    }

    return (
        <Card cardName={showDetails ? activeFrame.name : 'Ramy'}
              size={8}
              onStart={onStart}
              showHeaderButton={showDetails}
              buttonLabel='Wstecz'
              onClickButton={() => setShowDetails(false)}
        >
            {showDetails ?
                <Details activeFrame={activeFrame}
                         onStart={onStart}
                />
                :
                <>
                    <div className='table-responsive'>
                        <table className="table table-striped table-hover">
                            <thead>
                            <tr>
                                <th>Nazwa</th>
                                <th>Akcje</th>
                            </tr>
                            </thead>
                            <tbody>
                            {frames}
                            </tbody>
                        </table>
                    </div>
                    <div className="mb-3">
                        <Input id='name'
                               label='Nazwa'
                               type='text'
                               reference={nameRef}
                        />
                    </div>
                    <div className='col-4'>
                        <PrimaryButton label='Dodaj rame'
                                       onClickFunction={addFrame}
                        />
                    </div>
                </>
            }
        </Card>
    );

};

export default FrameCard;