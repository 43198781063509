import React, {useEffect, useState} from "react";
import {AppContext} from "./context/AppContext";
import {CallAPI} from "./api/API";
import './App.css';
import Login from "./pages/login";
import Application from "./pages/application";
// todo refactor
const App = () => {

    const [user, setUser] = useState(
        {
            logged: false,
            accessToken: null,
        }
    )
    const [error, setError] = useState({
        show: false,
        title: "",
        message: ""
    })

    const Refresh = async () => {
        const result = await CallAPI('/refresh', null, 'GET')
        if (result.isSuccess) {
            setUser({
                ...user,
                ...{
                    logged: true,
                    accessToken: "Bearer " + result.data.access_token,
                }
            })
        } else {
            setUser({
                logged: false,
                accessToken: null,
            })
        }
    }

    useEffect(() => {
        if (!user.logged) {
            void Refresh()
            setInterval(() => {
                void Refresh()
            }, 1000 * 60 * 9);
        }
    }, [])

    return (
        <AppContext.Provider value={
            {
                user: user,
                setUser: setUser,
                error: error,
                setError: setError
            }
        }>
            {user.logged ?
                <Application/>
                :
                <Login/>
            }

        </AppContext.Provider>
    );
}

export default App;
