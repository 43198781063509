import React, {useContext, useRef, useState} from "react";
import {AppContext} from "../../../../context/AppContext";
import {ImageTemplateContext} from "../../../../context/ImageTemplateContext";
import {addImage, deleteImage, updateTemplateImage} from "./functions";
import Input from "../../../form/input";
import TextArea from "../../../form/textArea";
import PrimaryButton from "../../../buttons/primaryButton";
import FileInput from "../../../form/fileInput";
import FileInfo from "../../../fileInfo";
import Checkbox from "../../../form/checkbox";
// todo refactor
const CardTab = () => {

    const {user, setError} = useContext(AppContext);
    const {
        id,
        pro,
        cardImage,
        cardImageAlt,
        cardName,
        cardDescription,
        cardPriceText,
    } = useContext(ImageTemplateContext)

    const proRef = useRef(false)
    const cardImageFileRef = useRef('')
    const cardImageAltRef = useRef('')
    const cardNameRef = useRef('')
    const cardDescriptionRef = useRef('')
    const cardPriceTextRef = useRef('')

    const [cardImageName, setCardImageName] = useState(cardImage)

    return (
        <>
            <div className="mb-3">
                <Checkbox
                    reference={proRef} label={'Szablon premium'} value={pro} id={'isPro' + id}
                />
            </div>
            <div className="mb-3">
                {cardImageName ?
                    <FileInfo
                        header={'Grafika karty'}
                        fileName={cardImageName}
                        src={`/template/${id}/${cardImageName}`}
                        onClick={() => deleteImage({
                            id: id,
                            accessToken: user.accessToken,
                            setError: setError,
                            type: 'cardImage',
                            setImage: setCardImageName
                        })}
                    />
                    :
                    <FileInput id={"cardImage"} label={"Grafika karty"} require={true}
                               reference={cardImageFileRef}
                               onChange={() => addImage({
                                   id: id,
                                   accessToken: user.accessToken,
                                   setError: setError,
                                   type: 'cardImage',
                                   image: cardImageFileRef.current.files[0],
                                   setImage: setCardImageName
                               })}/>
                }
            </div>
            <div className="mb-3">
                <Input id={'cardImageAlt'} label={'Alt grafiki karty'} type={'text'}
                       reference={cardImageAltRef} require={true} value={cardImageAlt}/>
            </div>
            <div className="mb-3">
                <Input id={'cardName'} label={'Nazwa karty'} type={'text'}
                       reference={cardNameRef} require={true} value={cardName}/>
            </div>
            <div className="mb-3">
                <TextArea id={'cardDescription'} label={'Opis karty'} reference={cardDescriptionRef}
                          defaultValue={cardDescription} require={true} rows={8}/>
            </div>
            <div className="mb-3">
                <Input id={'cardPriceText'} label={'Info o cenie'} type={'text'}
                       reference={cardPriceTextRef} require={true} value={cardPriceText}/>
            </div>
            <PrimaryButton label={'Zapisz'}
                           onClickFunction={
                               () => updateTemplateImage(
                                   {
                                       url: 'updateCard',
                                       id: id,
                                       setError: setError,
                                       accessToken: user.accessToken,
                                       body: {
                                           isPro: proRef.current.checked,
                                           cardImageAlt: cardImageAltRef.current.value,
                                           cardName: cardNameRef.current.value,
                                           cardDescription: cardDescriptionRef.current.value,
                                           cardPriceText: cardPriceTextRef.current.value
                                       }
                                   }
                               )}
            />
        </>
    )
}

export default CardTab;