import React from "react";
import AboutCard from "../../cards/aboutCard";
import TagCard from "../../cards/tagCard";
import FaqCard from "../../cards/FaqCard";
import TabWrapper from "../TabWrapper";
import BasicPageDataCard from "../../cards/basicPageDataCard/BasicPageDataCard";

const AboutPage = () => {

    return (
        <TabWrapper>
            <BasicPageDataCard page={'ABOUT_PAGE'}/>
            <AboutCard/>
            <TagCard/>
            <FaqCard/>
        </TabWrapper>
    )
}

export default AboutPage;