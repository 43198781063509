import {CallAPI, CallAPIWithoutResponse} from "../../../api/API";
import React from "react";
import FeatherIcon from "feather-icons-react";
// todo refactor
export const getImageCategories = async (setLoad, setError, setCategories, accessToken, setActiveView, setActiveCategory, isProject) => {
    const result = await CallAPI('/imageCategory/all', null, 'GET')
    if (result.isSuccess) {
        const categories = []
        result.data.forEach(category => {
            if ((isProject && category.isProject) || (!isProject && !category.isProject)) {
                categories.push(
                    <tr key={category.id}>
                        <td>{category.name}</td>
                        <td>{category.link}</td>
                        <td>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Przenieś niżej"
                                    onClick={() => moveDown(category.id, accessToken, setError, setLoad, setCategories, setActiveView, setActiveCategory)}
                            >
                                <FeatherIcon icon="arrow-down"/>
                            </button>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Przenieś wyżej"
                                    onClick={() => moveUp(category.id, accessToken, setError, setLoad, setCategories, setActiveView, setActiveCategory)}
                            >
                                <FeatherIcon icon="arrow-up"/>
                            </button>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Zobacz szczegóły"
                                    onClick={() => showDetails(setActiveView, setActiveCategory, 'CategoryDetailsView', category.id, category.name)}
                            >
                                <FeatherIcon icon="eye"/>
                            </button>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Usuń"
                                    onClick={() => deleteImageCategory(category.id, accessToken, setLoad, setError, setCategories, setActiveView, setActiveCategory)}
                            >
                                <FeatherIcon icon="trash"/>
                            </button>
                        </td>
                    </tr>
                )
            }
        })
        setCategories(categories)
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania kategorii"
        })
    }
    setLoad(false)
}

export const getImageCategory = async (categoryId, setLoad, setError, setCategory) => {
    const result = await CallAPI('/imageCategory/' + categoryId, null, 'GET')
    if (result.isSuccess) {
        setCategory(result.data)
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania kategorii"
        })
    }
    setLoad(false)
}

export const getImageSubCategory = async (subCategoryId, setLoad, setError, setData) => {
    const result = await CallAPI('/imageSubCategory/' + subCategoryId, null, 'GET')
    if (result.isSuccess) {
        setData(result.data)
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania podkategorii"
        })
    }
    setLoad(false)
}

const showDetails = async (setActiveView, setActiveCategory, view, id, name) => {
    setActiveView(view)
    setActiveCategory({id: id, name: name})
}

const moveUp = async (id, accessToken, setError, setLoad, setCategories, setActiveView, setActiveCategory) => {
    await CallAPI('/imageCategory/up/' + id, null, 'PUT')
    await getImageCategories(setLoad, setError, setCategories, accessToken, setActiveView, setActiveCategory)
}

const moveDown = async (id, accessToken, setError, setLoad, setCategories, setActiveView, setActiveCategory) => {
    await CallAPI('/imageCategory/down/' + id, null, 'PUT')
    await getImageCategories(setLoad, setError, setCategories, accessToken, setActiveView, setActiveCategory)
}

const moveUpSub = async (id, setLoad, setError, setSubCategories, setActiveView, setActiveSubCategory, categoryId) => {
    await CallAPI('/imageSubCategory/up/' + id, null, 'PUT')
    await getSubCategories(setLoad, setError, categoryId, setSubCategories, setActiveView, setActiveSubCategory)
}

const moveDownSub = async (id, setLoad, setError, setSubCategories, setActiveView, setActiveSubCategory, categoryId) => {
    await CallAPI('/imageSubCategory/down/' + id, null, 'PUT')
    await getSubCategories(setLoad, setError, categoryId, setSubCategories, setActiveView, setActiveSubCategory)
}

export const getSubCategories = async (setLoad, setError, categoryId, setSubCategories, setActiveView, setActiveSubCategory) => {
    const result = await CallAPI('/imageSubCategory/all/' + categoryId, null, 'GET')
    if (result.isSuccess) {
        const subCategories = []
        result.data.forEach(subCategory => {
            subCategories.push(
                <tr key={subCategory.id}>
                    <td>{subCategory.name}</td>
                    <td>{subCategory.link}</td>
                    <td>
                        <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Przenieś niżej"
                                onClick={() => moveDownSub(subCategory.id, setLoad, setError, setSubCategories, setActiveView, setActiveSubCategory, categoryId)}
                        >
                            <FeatherIcon icon="arrow-down"/>
                        </button>
                        <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Przenieś wyżej"
                                onClick={() => moveUpSub(subCategory.id, setLoad, setError, setSubCategories, setActiveView, setActiveSubCategory, categoryId)}
                        >
                            <FeatherIcon icon="arrow-up"/>
                        </button>
                        <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Zobacz szczegóły"
                                onClick={() => showDetails(setActiveView, setActiveSubCategory, 'CategorySubDetailsView', subCategory.id, subCategory.name)}
                        >
                            <FeatherIcon icon="eye"/>
                        </button>
                        <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Usuń"
                                onClick={() => deleteImageSubCategory(categoryId, subCategory.id, null, setLoad, setError, setSubCategories, setActiveView, setActiveSubCategory)}>
                            <FeatherIcon icon="trash"/>
                        </button>
                    </td>
                </tr>
            )
        })
        setSubCategories(subCategories)
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania podkategorii"
        })
    }
    setLoad(false)
}

export const saveImageCategory = async (accessToken, setError, setLoad, setCategories, setActiveView, setActiveCategory, name, link, isProject) => {

    const body = {
        name: name,
        link: link,
        isProject: isProject
    }

    const result = await CallAPIWithoutResponse('/imageCategory', body, 'POST', accessToken)
    if (result.isSuccess) {
        await getImageCategories(setLoad, setError, setCategories, accessToken, setActiveView, setActiveCategory, isProject)
    } else {
        setError({
            show: true,
            title: "Bląd zapisania",
            message: "Błąd podczas zapisywania kategorii"
        })
    }
}

export const updateImageCategory = async (accessToken, setError, id, name, link, title, metaDescription, metaKeywords,
                                          metaRobots, linkCanonical, siteImage, openGraph) => {

    const body = {
        id: id,
        name: name,
        link: link,
        title: title,
        metaDescription: metaDescription,
        metaKeywords: metaKeywords,
        metaRobots: metaRobots,
        linkCanonical: linkCanonical,
        siteImage: siteImage,
        openGraph: openGraph
    }

    const result = await CallAPIWithoutResponse('/imageCategory', body, 'PUT', accessToken)
    if (!result.isSuccess) {
        setError({
            show: true,
            title: "Bląd zapisania",
            message: "Błąd podczas zapisywania kategorii"
        })
    }
}

export const updateImageSubCategory = async (accessToken, setError, id, name, link, title, metaDescription, metaKeywords,
                                             metaRobots, linkCanonical, siteImage, openGraph) => {

    const body = {
        id: id,
        name: name,
        link: link,
        title: title,
        metaDescription: metaDescription,
        metaKeywords: metaKeywords,
        metaRobots: metaRobots,
        linkCanonical: linkCanonical,
        siteImage: siteImage,
        openGraph: openGraph
    }

    const result = await CallAPIWithoutResponse('/imageSubCategory', body, 'PUT', accessToken)
    if (!result.isSuccess) {
        setError({
            show: true,
            title: "Bląd zapisania",
            message: "Błąd podczas zapisywania podkategorii"
        })
    }
}

export const saveImageSubCategory = async (accessToken, setError, setLoad, setSubCategories, categoryId, setActiveView, setActiveSubCategory, name, link) => {

    const body = {
        name: name,
        link: link
    }

    const result = await CallAPIWithoutResponse('/imageSubCategory/' + categoryId, body, 'POST', accessToken)
    if (result.isSuccess) {
        await getSubCategories(setLoad, setError, categoryId, setSubCategories, setActiveView, setActiveSubCategory)
    } else {
        setError({
            show: true,
            title: "Bląd zapisania",
            message: "Błąd podczas zapisywania podkategorii"
        })
    }
}

export const deleteImageCategory = async (id, accessToken, setLoad, setError, setCategories, setActiveView, setActiveCategory) => {
    setLoad(true)
    const result = await CallAPIWithoutResponse('/imageCategory/delete/' + id, null, 'DELETE', accessToken)
    if (result.isSuccess) {
        await getImageCategories(setLoad, setError, setCategories, accessToken, setActiveView, setActiveCategory)
    } else {
        setError({
            show: true,
            title: "Bląd usuwania",
            message: "Błąd podczas usuwania kategorii"
        })
    }
    setLoad(false)
}

export const deleteImageSubCategory = async (categoryId, subCategoryId, accessToken, setLoad, setError, setSubCategories, setActiveView, setActiveSubCategory) => {
    setLoad(true)
    const result = await CallAPIWithoutResponse('/imageSubCategory/delete/' + categoryId + '/' + subCategoryId, null, 'DELETE', null)
    if (result.isSuccess) {
        await getSubCategories(setLoad, setError, categoryId, setSubCategories, setActiveView, setActiveSubCategory)
    } else {
        setError({
            show: true,
            title: "Bląd usuwania",
            message: "Błąd podczas usuwania podkategorii"
        })
    }
    setLoad(false)
}

export const saveImageCategoryTopContent = async (accessToken, setError, id, topHeader, topContent) => {
    const body = {
        id: id,
        topHeader: topHeader,
        topContent: topContent,
    }
    const result = await CallAPIWithoutResponse('/imageCategory/topContent', body, 'POST', accessToken)
    if (!result.isSuccess) {
        setError({
            show: true,
            title: "Bląd zapisywania",
            message: "Błąd podczas zapisywania głównej treści"
        })
    }
}

export const saveImageSubCategoryTopContent = async (accessToken, setError, id, topHeader, topContent) => {
    const body = {
        id: id,
        topHeader: topHeader,
        topContent: topContent,
    }
    const result = await CallAPIWithoutResponse('/imageSubCategory/topContent', body, 'POST', accessToken)
    if (!result.isSuccess) {
        setError({
            show: true,
            title: "Bląd zapisywania",
            message: "Błąd podczas zapisywania głównej treści podkategorii"
        })
    }
}

export const addExtraContent = async (categoryId, accessToken, setLoad, setError, isSubCategory, setData) => {
    const url = isSubCategory ? '/imageSubCategory/extraContent/' : '/imageCategory/extraContent/'
    const result = await CallAPIWithoutResponse(url + categoryId, {}, 'POST', accessToken)
    if (result.isSuccess) {
        if (isSubCategory) {
            await getImageSubCategory(categoryId, setLoad, setError, setData)
        } else {
            await getImageCategory(categoryId, setLoad, setError, setData)
        }
    } else {
        setError({
            show: true,
            title: "Bląd dodawania treśći",
            message: "Błąd podczas dodawania dodatkowej treści"
        })
    }
}

export const deleteExtraContent = async (categoryId, accessToken, setLoad, setError, isSubCategory, data, setData) => {
    const url = isSubCategory ? '/imageSubCategory/extraContent/' : '/imageCategory/extraContent/'
    const result = await CallAPIWithoutResponse(url + categoryId, data, 'DELETE', accessToken)
    if (result.isSuccess) {
        if (isSubCategory) {
            await getImageSubCategory(categoryId, setLoad, setError, setData)
        } else {
            await getImageCategory(categoryId, setLoad, setError, setData)
        }
    } else {
        setError({
            show: true,
            title: "Bląd usuwania treśći",
            message: "Błąd podczas usuwania dodatkowej treści"
        })
    }
}

export const saveExtraContent = async (accessToken, setError, id, header, content) => {
    const body = {
        id: id,
        header: header,
        content: content
    }
    const result = await CallAPIWithoutResponse('/extraContent', body, 'PUT', accessToken)
    if (!result.isSuccess) {
        setError({
            show: true,
            title: "Bląd zapisywania treśći",
            message: "Błąd podczas zapisywania dodatkowej treści"
        })
    }
}