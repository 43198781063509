import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../../../context/AppContext";
import {ImageTemplateContext} from "../../../../context/ImageTemplateContext";
import {addImage, addSize, deleteImage, deleteSize, updateTemplateImage} from "./functions";
import Input from "../../../form/input";
import PrimaryButton from "../../../buttons/primaryButton";
import FeatherIcon from "feather-icons-react";
import FileInfo from "../../../fileInfo";
import FileInput from "../../../form/fileInput";
import PriceInput from "../../../form/priceInput";
import GraphicsSections from "./graphicsSections";
import {CallAPIWithoutResponse} from "../../../../api/API";
// todo refactor
const ManyGraphicsEditorTab = () => {

    const {user, setError} = useContext(AppContext);
    const {
        id,
        editorSizes,
        setUpdate,
        editorColor,
        minWidth,
        minHeight,
        graphicWidth,
        graphicHeight,
        graphic,
        graphicPrint,
        graphicsSections
    } = useContext(ImageTemplateContext)

    const widthRef = useRef('')
    const heightRef = useRef('')
    const editorColorRef = useRef('')
    const minWidthRef = useRef('')
    const minHeightRef = useRef('')
    const graphicFileRef = useRef('')
    const graphicWidthRef = useRef('')
    const graphicHeightRef = useRef('')
    const graphicPrintFileRef = useRef('')

    const [sizeRows, setSizeRows] = useState([])
    const [price, setPrice] = useState(null)
    const [graphicName, setGraphicName] = useState(graphic)
    const [graphicPrintName, setGraphicPrintName] = useState(graphicPrint)

    useEffect(() => {
        const rows = []
        editorSizes.forEach(row => {
            rows.push(
                <tr key={row.id}>
                    <td>{row.width}</td>
                    <td>{row.height}</td>
                    <td>{row.price}</td>
                    <td>
                        <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Usuń"
                                onClick={() => deleteSize({
                                    id: id,
                                    accessToken: user.accessToken,
                                    setError: setError,
                                    setUpdate: setUpdate,
                                    body: {
                                        id: row.id,
                                        width: row.width,
                                        height: row.height,
                                        price: row.price,
                                    }
                                })}>
                            <FeatherIcon icon="trash"/>
                        </button>
                    </td>
                </tr>
            )
        })
        setSizeRows(rows)
    }, [editorSizes])

    const addGraphicsSection = async () => {
        const result = await CallAPIWithoutResponse('/imageTemplate/graphicSection/' + id, null, 'POST', user.accessToken)
        if (result.isSuccess) {
            setUpdate(true)
        } else {
            setError({
                show: true,
                title: "Bląd dodawania",
                message: "Błąd dodawania sekcji"
            })
        }
    }

    return (
        <>
            <h3>Dostępne rozmiary</h3>
            <div className="table-responsive">
                <table className="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th>Szerokość</th>
                        <th>Wysokość</th>
                        <th>Cena</th>
                        <th>Akcje</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sizeRows}
                    </tbody>
                </table>
            </div>
            <div className="mb-3">
                <Input id={'width'} label={'Szerokość'} type={'number'}
                       require={true} reference={widthRef}/>
            </div>
            <div className="mb-3">
                <Input id={'height'} label={'Wysokość'} type={'number'}
                       require={true} reference={heightRef}/>
            </div>
            <div className="mb-3">
                <PriceInput id={'price'} label={'Cena'} value={null} setValue={setPrice}/>
            </div>
            <PrimaryButton label={'Dodaj rozmiar'}
                           onClickFunction={
                               () => addSize({
                                   id: id,
                                   body: {
                                       width: widthRef.current.value,
                                       height: heightRef.current.value,
                                       price: price,
                                   },
                                   accessToken: user.accessToken,
                                   setError: setError,
                                   setUpdate: setUpdate
                               })}
            />
            <hr/>
            <div className="mb-3">
                <Input id={'editorColor'} label={'Kolor edytora'} type={'text'}
                       value={editorColor} reference={editorColorRef}/>
            </div>
            <div className="mb-3">
                <Input id={'minWidth'} label={'Minimalna szerokość zdjęcia'} type={'number'}
                       value={minWidth} reference={minWidthRef}/>
            </div>
            <div className="mb-3">
                <Input id={'minHeight'} label={'Minimalna wysokość zdjęcia'} type={'number'}
                       value={minHeight} reference={minHeightRef}/>
            </div>
            <div className="mb-3">
                {graphicName ?
                    <FileInfo
                        header={'Grafika'}
                        fileName={graphicName}
                        src={`/template/${id}/${graphicName}`}
                        onClick={() => deleteImage({
                            id: id,
                            accessToken: user.accessToken,
                            setError: setError,
                            type: 'graphicEditor',
                            setImage: setGraphicName
                        })}
                    />
                    :
                    <FileInput id={"graphic"} label={"Grafika"} require={true}
                               reference={graphicFileRef}
                               onChange={() => addImage({
                                   id: id,
                                   accessToken: user.accessToken,
                                   setError: setError,
                                   type: 'graphicEditor',
                                   image: graphicFileRef.current.files[0],
                                   setImage: setGraphicName
                               })}/>
                }
            </div>
            <div className="mb-3">
                <Input id={'graphicWidth'} label={'Szerokość grafiki'} type={'number'}
                       value={graphicWidth} reference={graphicWidthRef}/>
            </div>
            <div className="mb-3">
                <Input id={'graphicHeight'} label={'Wysokość grafiki'} type={'number'}
                       value={graphicHeight} reference={graphicHeightRef}/>
            </div>
            <div className="mb-3">
                {graphicPrintName ?
                    <FileInfo
                        header={'Grafika do druku (png)'}
                        fileName={graphicPrintName}
                        src={`/template/${id}/${graphicPrintName}`}
                        onClick={() => deleteImage({
                            id: id,
                            accessToken: user.accessToken,
                            setError: setError,
                            type: 'graphicPrintEditor',
                            setImage: setGraphicPrintName
                        })}
                    />
                    :
                    <FileInput id={"graphicPrint"} label={"Grafika do druku (png)"} require={true}
                               reference={graphicPrintFileRef}
                               onChange={() => addImage({
                                   id: id,
                                   accessToken: user.accessToken,
                                   setError: setError,
                                   type: 'graphicPrintEditor',
                                   image: graphicPrintFileRef.current.files[0],
                                   setImage: setGraphicPrintName
                               })}/>
                }
            </div>
            <PrimaryButton label={'Zapisz'}
                           onClickFunction={
                               () => updateTemplateImage(
                                   {
                                       url: 'updateEditor',
                                       id: id,
                                       setError: setError,
                                       accessToken: user.accessToken,
                                       body: {
                                           editorColor: editorColorRef.current.value,
                                           minWidth: minWidthRef.current.value,
                                           minHeight: minHeightRef.current.value,
                                           graphicWidth: graphicWidthRef.current.value,
                                           graphicHeight: graphicHeightRef.current.value,
                                       }
                                   }
                               )}
            />
            <hr/>
            {graphicsSections.map((section, index) => {
                return <GraphicsSections
                    key={section.id}
                    graphicId={section.id}
                    number={index + 1}
                    editorFieldWidth={section.editorFieldWidth}
                    editorFieldHeight={section.editorFieldHeight}
                    editorFieldPositionTop={section.editorFieldPositionTop}
                    editorFieldPositionLeft={section.editorFieldPositionLeft}
                />
            })}
            <PrimaryButton label={'Dodaj sekcje edytora'} onClickFunction={addGraphicsSection} />
        </>
    )
}

export default ManyGraphicsEditorTab;