import React, {useEffect, useState} from "react";
import './TextBoxCard.css'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {Card as ReactCard, Col, Row} from "react-bootstrap";
import Card from "../card";
import DangerButton from "../../buttons/dangerButton";
import Image_1 from "./images/1.png";
import Image_2 from "./images/2.png";
import Image_3 from "./images/3.png";
import Image_4 from "./images/4.png";
import Image_5 from "./images/5.png";
import Image_6 from "./images/6.png";
import Image_7 from "./images/7.png";
import Image_8 from "./images/8.png";
import Image_9 from "./images/9.png";
import Image_10 from "./images/10.png";
import Image_11 from "./images/11.png";
import Image_12 from "./images/12.png";
import Image_13 from "./images/13.png";
import Image_14 from "./images/14.png";
import Image_15 from "./images/15.png";
import Image_16 from "./images/16.png";
import Image_17 from "./images/17.png";
import Image_18 from "./images/18.png";
import Image_19 from "./images/19.png";
import Image_20 from "./images/20.png";
import Image_21 from "./images/21.png";
import Image_22 from "./images/22.png";
import DeleteTextBoxModal from "./DeleteTextBoxModal";
import SaveTextBoxModal from "./SaveTextBoxModal";
import {useApi} from "../../../hooks/useApi";
import EditButton from "./EditButton";

const TextBoxCard = ({page}) => {
    const [draggableData, setDraggableData] = useState([]);
    const [showDeleteTextBoxModal, setShowDeleteTextBoxModal] = useState(false);
    const [showSaveTextBoxModal, setShowSaveTextBoxModal] = useState(false);
    const [contextItem, setContextItem] = useState(null)

    const {getApi, putApi} = useApi()

    const getTextBoxes = async () => {
        let data = []
        const result = await getApi('/guiComponent/' + page + '/TEXT_BOX')
        if (result.isSuccess) {
            result.data.forEach(it => {
                const itemData = JSON.parse(it.data)
                const item = {
                    id: it.id,
                    type: itemData?.type,
                    variant: itemData?.variant,
                    heading: itemData?.title,
                    text: itemData?.text,
                    list: itemData?.recordsList?.map(item => "[" + item.name + "], "),
                    recordsList: itemData?.recordsList,
                    contentType: itemData?.contentType,
                    position: itemData?.position,
                    buttonLabel: itemData?.buttonLabel,
                    buttonLink: itemData?.buttonLink,
                    file: itemData.file
                }
                data.push(item)
            })
            data = [...data].sort((a, b) => a.position - b.position);
        }
        setDraggableData(data)
    }

    const reorder = (array, fromIndex, toIndex) => {
        const newArr = [...array];

        const chosenItem = newArr.splice(fromIndex, 1)[0];
        newArr.splice(toIndex, 0, chosenItem);

        return newArr;
    };

    const onDragEnd = result => {
        const {source, destination} = result;
        if (!destination) {
            return;
        }
        const reorderedItems = reorder(draggableData, source.index, destination.index);
        reorderedItems.forEach((record, index) => {
            record.position = index;
            const data = {
                type: record.type,
                variant: record.variant,
                contentType: record.contentType,
                recordsList: record.recordsList,
                title: record.heading,
                text: record.text,
                position: record.position,
                buttonLabel: record?.buttonLabel,
                buttonLink: record?.buttonLink,
                file: record.file
            }
            const body = {
                page: page,
                type: 'TEXT_BOX',
                data: JSON.stringify(data)
            }
            void putApi('/guiComponent/' + record.id, body)
        });
        setDraggableData(reorderedItems);
    };

    const getImage = (item) => {
        const imageParams = [item.type, item.variant]
        if (item.heading?.length > 0) {
            imageParams.push("TITLE")
        }
        if (item.text?.length > 0) {
            imageParams.push("TEXT")
        }
        if (item.recordsList?.length > 0) {
            imageParams.push("LIST")
        }
        const code = imageParams.join("_")
        switch (code) {
            case "BASIC_BLUE_TITLE":
                return Image_1;
            case "BASIC_WHITE_TITLE":
                return Image_2;
            case "BASIC_YELLOW_TITLE":
                return Image_3;
            case "BASIC_BLUE_TITLE_TEXT":
                return Image_4;
            case "BASIC_WHITE_TITLE_TEXT":
                return Image_5;
            case "BASIC_YELLOW_TITLE_TEXT":
                return Image_6;
            case "BASIC_NO_BACKGROUND_TITLE":
                return Image_7;
            case "BASIC_NO_BACKGROUND_TITLE_TEXT":
                return Image_8;
            case "OVERRIDING_BLUE_TITLE":
                return Image_9;
            case "OVERRIDING_WHITE_TITLE":
                return Image_10;
            case "OVERRIDING_YELLOW_TITLE":
                return Image_11;
            case "BASIC_BLUE_TITLE_LIST":
                return Image_12;
            case "BASIC_WHITE_TITLE_LIST":
                return Image_13;
            case "BASIC_YELLOW_TITLE_LIST":
                return Image_14;
            case "BASIC_NO_BACKGROUND_TITLE_LIST":
                return Image_15;
            case "BASIC_NO_BACKGROUND_LIST":
                return Image_16;
            case "BASIC_BLUE_LIST":
                return Image_17;
            case "BASIC_WHITE_LIST":
                return Image_18;
            case "BASIC_YELLOW_LIST":
                return Image_19;
            case "OVERRIDING_BLUE_LIST":
                return Image_20;
            case "OVERRIDING_WHITE_LIST":
                return Image_21;
            case "OVERRIDING_YELLOW_LIST":
                return Image_22;
            default:
                return null;
        }
    }

    const showDeleteModal = (item) => {
        setContextItem(item)
        setShowDeleteTextBoxModal(true)
    }

    return (
        <Card cardName="Boxy z treścią"
              showHeaderButton={true}
              buttonLabel="Dodaj nowy box"
              onStart={getTextBoxes}
              onClickButton={() => setShowSaveTextBoxModal(true)}>
            <DeleteTextBoxModal
                show={showDeleteTextBoxModal}
                setShow={setShowDeleteTextBoxModal}
                item={contextItem}
                getTextBoxes={getTextBoxes}
            />
            <SaveTextBoxModal
                show={showSaveTextBoxModal}
                setShow={setShowSaveTextBoxModal}
                getTextBoxes={getTextBoxes}
                textBoxesSize={draggableData?.length}
                page={page}
            />
            <DragDropContext onDragEnd={onDragEnd}>
                <Row>
                    <Col>
                        <StrictModeDroppable droppableId="droppable" type="DRAG">
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="kanban-items-container bg-white dark__bg-1000 rounded-2 py-3 mb-3"
                                >
                                    {draggableData.map((item, index) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={`drag${item.id}`}
                                            index={index}
                                        >
                                            {provided => (
                                                <div>
                                                    <ReactCard
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="mb-3 kanban-item shadow-sm dark__bg-1100"
                                                    >
                                                        <ReactCard.Body>
                                                            <Row>
                                                                <Col xs={12} md={9}>
                                                                    <Row>
                                                                        <Col xs={12} lg={4}>
                                                                            <img
                                                                                className="img-fluid textBoxCardItemImage mb-3"
                                                                                src={getImage(item)}
                                                                                alt='Text box'
                                                                            />
                                                                        </Col>
                                                                        <Col xs={12} lg={8}>
                                                                            <p className="textBoxCardItemDesc text-sm m-0">
                                                                                Typ: {{
                                                                                    'BASIC' : 'Podstawowy',
                                                                                    'OVERRIDING' : 'Nachodzący'
                                                                                }[item.type]} --
                                                                                Wariant: {{
                                                                                    'BLUE': 'Niebieski',
                                                                                    'WHITE': 'Biały',
                                                                                    'YELLOW': 'Żółty',
                                                                                    'NO_BACKGROUND': 'Bez tła',
                                                                                    }[item.variant]}
                                                                            </p>
                                                                            {item.heading && <p className="textBoxCardItemDesc text-sm m-0">Tytuł: {item.heading}</p>}
                                                                            {item.text && <p className="textBoxCardItemDesc text-sm m-0">Treść: {item.text}</p>}
                                                                            {item.list?.length > 0 && <p className="textBoxCardItemDesc text-sm m-0">Lista: {item.list}</p>}
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col xs={12} md={3}>
                                                                    <Row className="justify-content-end mt-3">
                                                                        <EditButton key={item.id} item={item} getTextBoxes={getTextBoxes} page={page} />
                                                                        <Col xs={6}>
                                                                            <DangerButton label="Usuń"
                                                                                          onClickFunction={() => showDeleteModal(item)}/>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </ReactCard.Body>
                                                    </ReactCard>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </StrictModeDroppable>
                    </Col>
                </Row>
            </DragDropContext>
        </Card>
    );
};

const StrictModeDroppable = ({children, ...props}) => {
    const [enabled, setEnabled] = useState(false);
    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true));
        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);
    if (!enabled) {
        return null;
    }
    return <Droppable {...props}>{children}</Droppable>;
};

export default TextBoxCard;