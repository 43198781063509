import React, {useContext, useRef, useState} from "react";
import {AppContext} from "../../context/AppContext";
import {LoginFunction} from "./Functions";
import PrimaryButton from "../../components/buttons/primaryButton";
import Input from "../../components/form/input";
import ErrorAlert from "../../components/alerts/error";
// todo refactor
const Login = () => {

    const [isLoad, setLoad] = useState(false);
    const [isError, setError] = useState(false)
    const {setUser} = useContext(AppContext);
    const loginRef = useRef("");
    const passwordRef = useRef("");

    return (
        <div id="layoutAuthentication" className="bg-primary">
            <div id="layoutAuthentication_content">
                <main>
                    <div className="container-xl px-4">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="card shadow-lg border-0 rounded-lg mt-5">
                                    <div className="card-header justify-content-center"><h3
                                        className="fw-light my-4">Logowanie</h3></div>
                                    <div className="card-body">
                                        {isError ?
                                            <ErrorAlert title={'Błąd logowania'} message={'Błędny login lub hasło'}/>
                                            : null
                                        }
                                        <form
                                            onSubmit={(event) => LoginFunction(event, setLoad, loginRef, passwordRef, setUser, setError)}>
                                            <div className="mb-3">
                                                <Input id={'inputLogin'} label={'Login'} type={'text'}
                                                       reference={loginRef} require={true}/>
                                            </div>
                                            <div className="mb-3">
                                                <Input id={'inputPassword'} label={'Hasło'} type={'password'}
                                                       reference={passwordRef} require={true}/>
                                            </div>
                                            <div
                                                className="d-flex align-items-center justify-content-end mt-4 mb-0">
                                                <PrimaryButton label={'Zaloguj'} type={'submit'} loading={isLoad}/>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="card-footer text-center"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Login;