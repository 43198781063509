import {CallAPI, CallAPIWithoutResponse} from "../../../api/API";
// todo refactor
export const getBanerContent = async (setLoad, setError, setData) => {
    const result = await CallAPI('/baner', null, 'GET')
    if (result.isSuccess) {
        setData(result.data)
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania baneru"
        })
    }
    setLoad(false)
}

export const saveBanerContent = async (accessToken, setError, content, fontColor, backgroundColor) => {

    const body = {
        content: content,
        fontColor: fontColor,
        backgroundColor: backgroundColor
    }

    const result = await CallAPIWithoutResponse('/baner/add', body, 'POST', accessToken)
    if (!result.isSuccess) {
        setError({
            show: true,
            title: "Bląd zapisania",
            message: "Błąd podczas zapisywania baneru"
        })
    }
}