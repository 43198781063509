import React, {useContext} from "react";
import {ToastContainer} from "react-bootstrap";
import ToastBS from "react-bootstrap/Toast";
import {AppContext} from "../../../context/AppContext";
// todo refactor
const AppError = () => {

    const {error, setError} = useContext(AppContext);

    return (
        <ToastContainer position="bottom-end"
                        className="p-3 position-fixed">
            <ToastBS bg={"warning"}
                     onClose={() => setError({show: false})}
                     show={error.show}
                     delay={10000}
                     autohide>
                <ToastBS.Header>
                    <strong className="me-auto">{error.title}</strong>
                    <small></small>
                </ToastBS.Header>
                <ToastBS.Body>{error.message}</ToastBS.Body>
            </ToastBS>
        </ToastContainer>
    )
}

export default AppError;