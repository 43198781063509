import {CallAPI, CallAPIWithFile, CallAPIWithoutResponse} from "../../../api/API";
import React from "react";
import FeatherIcon from "feather-icons-react";
// todo refactor
export const getSlides = async (setLoad, setError, setSlides, accessToken, setActiveDetails) => {
    const result = await CallAPI('/slide/all', null, 'GET')
    if (result.isSuccess) {
        const slides = []
        result.data.forEach(slide => {
            slides.push(
                <tr key={slide.id}>
                    <td>{slide.title}</td>
                    <td>{slide.content}</td>
                    <td>{slide.link}</td>
                    <td>{slide.imageName}</td>
                    <td>{slide.imageAlt}</td>
                    <td>
                        <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Zobacz szczegóły"
                                onClick={() => setActiveDetails(slide)}
                        >
                            <FeatherIcon icon="eye"/>
                        </button>
                        <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Usuń"
                                onClick={() => deleteSlide(slide.id, accessToken, setLoad, setError, setSlides, setActiveDetails)}>
                            <FeatherIcon icon="trash"/>
                        </button>
                    </td>
                </tr>
            )
        })
        setSlides(slides)
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania danych slidera"
        })
    }
    setLoad(false)
}

export const saveSlide = async (accessToken, setError, setLoad, setSlides, imageName, imageAlt, title, titleColor, content, contentColor, link, file, setActiveDetails) => {
    const body = new FormData();
    body.append('file', file)
    body.append('imageName', imageName)
    body.append('imageAlt', imageAlt)
    body.append('title', title)
    body.append('titleColor', titleColor)
    body.append('content', content)
    body.append('contentColor', contentColor)
    body.append('link', link)

    const result = await CallAPIWithFile('/slide', body, accessToken)
    if (result.isSuccess) {
        await getSlides(setLoad, setError, setSlides, accessToken, setActiveDetails)
    } else {
        setError({
            show: true,
            title: "Bląd zapisania",
            message: "Błąd podczas zapisywania slidera"
        })
    }
}

export const deleteSlide = async (id, accessToken, setLoad, setError, setSlides, setActiveDetails) => {
    setLoad(true)
    const result = await CallAPIWithoutResponse('/slide/delete/' + id, null, 'DELETE', accessToken)
    if (result.isSuccess) {
        await getSlides(setLoad, setError, setSlides, accessToken, setActiveDetails)
    } else {
        setError({
            show: true,
            title: "Bląd usuwania",
            message: "Błąd podczas usuwania slidera"
        })
    }
    setLoad(false)
}