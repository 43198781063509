import React from "react";
import CurrencyInput from 'react-currency-input-field';
// todo refactor
const PriceInput = ({id, label, value, setValue}) => {

    return (
        <>
            <label className="small mb-1"
                   htmlFor={id}>
                {label}
            </label>
            <CurrencyInput
                id={id}
                name={label}
                placeholder={label}
                defaultValue={value}
                decimalsLimit={2}
                decimalSeparator="."
                onValueChange={(value) => setValue(value)}
                className="form-control"
            />
        </>
    )
}

export default PriceInput;