import React, {useRef, useState} from "react";
import {useApi} from "../../../hooks/useApi";
import Input from "../../form/input";
import TextArea from "../../form/textArea";
import PriceInput from "../../form/priceInput";
import FileInfo from "../../fileInfo";
import FileInput from "../../form/fileInput";
import PrimaryButton from "../../buttons/primaryButton";
// todo refactor
const Details = ({activeFrame, onStart}) => {

    const {postFormData, putApi, deleteApi} = useApi();

    const [price, setPrice] = useState(activeFrame.price);
    const [imageName, setImageName] = useState(activeFrame.image);

    const nameRef = useRef('')
    const descriptionRef = useRef('')
    const imageRef = useRef('')
    const imageAltRef = useRef('')

    const addImage = async () => {
        const body = new FormData();
        body.append('image', imageRef.current.files[0])
        const success = await postFormData('/frame/image/' + activeFrame.id, body)
        if (success) {
            setImageName(imageRef.current.files[0].name)
        }
    }

    const deleteImage = async () => {
        const success = await deleteApi('/frame/image/' + activeFrame.id)
        if (success) {
            setImageName(null)
        }
    }

    const saveFrame = async () => {
        const body = {
            id: activeFrame.id,
            image: imageName,
            imageAlt: imageAltRef.current.value,
            name: nameRef.current.value,
            description: descriptionRef.current.value,
            price: price
        }
        await putApi('/frame/update/' + activeFrame.id, body)
        await onStart()
    }

    return (
        <>
            <div className="mb-3">
                <Input id='name'
                       label='Nazwa'
                       type='text'
                       reference={nameRef}
                       value={activeFrame.name}
                />
            </div>
            <div className="mb-3">
                <TextArea id='description'
                          label='Opis'
                          reference={descriptionRef}
                          defaultValue={activeFrame.description}
                          rows={6}
                />
            </div>
            <div className="mb-3">
                <PriceInput id='price'
                            label='Cena'
                            value={activeFrame.price}
                            setValue={setPrice}
                />
            </div>
            <div className="mb-3">
                {imageName ?
                    <FileInfo
                        header='Grafika ramy'
                        fileName={imageName}
                        src={`/frame/${activeFrame.id}/${imageName}`}
                        onClick={deleteImage}
                    />
                    :
                    <FileInput id='image'
                               label='Grafika ramy'
                               reference={imageRef}
                               onChange={addImage}
                    />
                }
            </div>
            <div className="mb-3">
                <Input id='imageAlt'
                       label='Alt grafiki ramy'
                       type='text'
                       reference={imageAltRef}
                       value={activeFrame.imageAlt}/>
            </div>
            <div className='col-4'>
                <PrimaryButton label='Zapisz'
                               onClickFunction={saveFrame}
                />
            </div>
        </>
    )
}

export default Details;