import React, {useEffect, useState} from "react";
import {ImageCardContext} from "../../../context/ImageCardContext";
import CategoryView from "./CategoryView";
import CategoryDetailsView from "./CategoryDetailsView";
import CategorySubDetailsView from "./CategorySubDetailsView";
// todo refactor
const ImageCategoriesCard = ({isProject = false}) => {

    const [activeView, setActiveView] = useState('CategoryView');
    const [activeCategory, setActiveCategory] = useState({})
    const [activeSubCategory, setActiveSubCategory] = useState({})
    const [header, setHeader] = useState('Kategorie')

    useEffect(() => {
        if (activeView === 'CategoryView') {
            setHeader('Kategorie')
        } else if (activeView === 'CategoryDetailsView') {
            setHeader('Kategoria: ' + activeCategory.name)
        } else if (activeView === 'CategorySubDetailsView') {
            setHeader('Kategoria: ' + activeCategory.name + ' > Podkategoria: ' + activeSubCategory.name)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeView])

    return (
        <ImageCardContext.Provider value={
            {
                setActiveView: setActiveView,
                activeCategory: activeCategory,
                setActiveCategory: setActiveCategory,
                activeSubCategory: activeSubCategory,
                setActiveSubCategory: setActiveSubCategory,
                isProject: isProject,
            }
        }>
            <div className="col-lg-12">
                <div className="card card-header-actions mb-4">
                    <div className="card-header">
                        {header}
                        {activeView === 'CategoryView' ?
                            null
                            :
                            <button className="btn btn-primary btn-sm"
                                    onClick={() =>
                                        activeView === 'CategorySubDetailsView' ? setActiveView('CategoryDetailsView') : setActiveView('CategoryView')}>
                                Wstecz
                            </button>
                        }
                    </div>
                    <div className="card-body">
                        {
                            {
                                'CategoryView': <CategoryView/>,
                                'CategoryDetailsView': <CategoryDetailsView/>,
                                'CategorySubDetailsView': <CategorySubDetailsView/>,
                            }[activeView]
                        }
                    </div>
                </div>
            </div>
        </ImageCardContext.Provider>
    )
}

export default ImageCategoriesCard;