import React, {useEffect, useState} from "react";
import AddDiscountCard from "../../cards/Discount/AddDiscountCard";
import TabWrapper from "../TabWrapper";
import DiscountsList from "../../cards/Discount/DiscountsList";
import {useApi} from "../../../hooks/useApi";

const Discount = () => {

    const {getApi} = useApi()

    const [discounts, setDiscounts] = useState([])

    const getDiscounts = async () => {
        const result = await getApi('/discount/')
        if (result.isSuccess) {
            setDiscounts(result.data)
        }
    }

    useEffect(() => {
        void getDiscounts()
    }, [])

    return (
        <TabWrapper>
            <AddDiscountCard getDiscounts={getDiscounts}/>
            <DiscountsList discounts={discounts} getDiscounts={getDiscounts}/>
        </TabWrapper>
    )
};

export default Discount;