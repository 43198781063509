import {useContext} from "react";
import {AppContext} from "../context/AppContext";
import {CallAPIWithoutResponse, CallSecurityAPI} from "../api/API";
// todo refactor
export const useApi = () => {

    const {user, setError} = useContext(AppContext);

    const getApi = async (url) => {
        const result = await CallSecurityAPI(url, null, 'GET', user.accessToken)
        if (!result.isSuccess) {
            setError({
                show: true,
                title: "Bląd",
                message: result.data.message
            })
        }
        return result;
    }

    const postApi = async (url, body) => {
        let result = {isSuccess: false, data: {}};
        await fetch(process.env.REACT_APP_API_URL + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.accessToken
            },
            credentials: 'include',
            body: JSON.stringify(body)
        }).then(response => {
            if (response.ok) {
                result.isSuccess = true;
                return response;
            } else {
                result.isSuccess = false;
                return response.text().then(text => {
                    throw JSON.parse(text);
                });
            }
        })
            .then(response => {
                return response?.text();
            })
            .then(data => {
                result.data = data ? JSON.parse(data) : {};
            })
            .catch(error => {
                result.isSuccess = false;
                result.data = error;
            });
        if (!result.isSuccess) {
            setError({
                show: true,
                title: "Bląd",
                message: "Błąd zapisywania danych"
            })
        }
        return result;
    };

    const putApi = async (url, body) => {
        let result = {isSuccess: false, data: {}};
        await fetch(process.env.REACT_APP_API_URL + url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.accessToken
            },
            credentials: 'include',
            body: JSON.stringify(body)
        }).then(response => {
            if (response.ok) {
                result.isSuccess = true;
                return response;
            } else {
                result.isSuccess = false;
                return response.text().then(text => {
                    throw JSON.parse(text);
                });
            }
        })
            .then(response => {
                return response?.text();
            })
            .then(data => {
                result.data = data ? JSON.parse(data) : {};
            })
            .catch(error => {
                result.isSuccess = false;
                result.data = error;
            });
        if (!result.isSuccess) {
            setError({
                show: true,
                title: "Bląd",
                message: "Błąd zapisywania danych"
            })
        }
        return result;
    };

    const postFormData = async (url, body) => {
        let success = false;
        await fetch(process.env.REACT_APP_API_URL + url, {
            method: 'POST',
            body: body,
        }).then((response) => {
            success = response.ok;
        }).catch(() => {
            success = false
        });
        if (!success) {
            setError({
                show: true,
                title: "Bląd",
                message: "Błąd zapisywania danych"
            })
        }
        return success
    }

    const deleteApi = async (url) => {
        const result = await CallAPIWithoutResponse(url, null, 'DELETE', user.accessToken)
        if (!result.isSuccess) {
            setError({
                show: true,
                title: "Bląd",
                message: "Błąd podczas usuwania"
            })
        }
        return result;
    }

    return {getApi, postApi, postFormData, putApi, deleteApi};
}