import React from "react";
import ImageCategoriesCard from "../../cards/imageCategoriesCard";
import TabWrapper from "../TabWrapper";

const Project = () => {

    return (
        <TabWrapper>
            <ImageCategoriesCard isProject={true}/>
        </TabWrapper>
    )
};

export default Project;