import React, {useState} from "react";
import Modal from "../../modal/Modal";
import {Col, Row} from "react-bootstrap";
import Radio from "../../form/radio/Radio";
import TextArea from "../../form/textArea";
import Space from "../../layout/Space";
import ListRecords from "./ListRecords";
import {useApi} from "../../../hooks/useApi";
import UploadFile from "../../form/uploadFile/UploadFile";
import Input from "../../form/input";

const SaveTextBoxModal = ({show, setShow, getTextBoxes, textBoxesSize, page}) => {

    const [selectedType, setSelectedType] = useState('BASIC');
    const [selectedVariant, setSelectedVariant] = useState('BLUE');
    const [selectedVariantValidation, setSelectedVariantValidation] = useState(null);
    const [selectedContentType, setSelectedContentType] = useState('TEXT');
    const [recordsList, setRecordsList] = useState([])
    const [title, setTitle] = useState("")
    const [titleValidation, setTitleValidation] = useState(null)
    const [text, setText] = useState("")
    const [fileBlob, setFileBlob] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [imageAlt, setImageAlt] = useState('')
    const [buttonLabel, setButtonLabel] = useState('');
    const [buttonLink, setButtonLink] = useState('');

    const types = [
        {
            value: "BASIC",
            label: "Podstawowy"
        },
        {
            value: "OVERRIDING",
            label: "Nachodzący"
        }
    ]

    const variantBasic = [
        {
            value: "BLUE",
            label: "Niebieski"
        },
        {
            value: "WHITE",
            label: "Biały"
        },
        {
            value: "YELLOW",
            label: "Żółty"
        },
        {
            value: "NO_BACKGROUND",
            label: "Bez tła"
        }
    ]

    const variantOverriding = [
        {
            value: "BLUE",
            label: "Niebieski"
        },
        {
            value: "WHITE",
            label: "Biały"
        },
        {
            value: "YELLOW",
            label: "Żółty"
        }
    ]

    const contentType = [
        {
            value: "TEXT",
            label: "Tekst"
        },
        {
            value: "LIST",
            label: "Lista"
        }
    ]

    const {postApi} = useApi()

    const closeAndClear = () => {
        setSelectedType('BASIC')
        setSelectedVariant('BLUE')
        setSelectedContentType('TEXT')
        setRecordsList([])
        setTitle("")
        setText("")
        setImageAlt('')
        setButtonLabel('')
        setButtonLink('')
        setShow(false)
    }

    const cancel = () => {
        closeAndClear()
    }

    const valid = () => {
        let isValid = true
        const emptyTitle = title === "" || title === null

        if (emptyTitle && selectedContentType === "TEXT") {
            isValid = false
            setTitleValidation("Tytuł jest wymagany")
        } else {
            setTitleValidation(null)
        }

        if (selectedVariant === "NO_BACKGROUND" && selectedType === "OVERRIDING") {
            isValid = false
            setSelectedVariantValidation("Wybierz wariant koloru")
        } else {
            setSelectedVariantValidation(null)
        }

        return isValid;
    }

    const approve = async () => {
        if (valid()) {
            const fileBody = {
                "id" : null,
                "fileName" : fileName,
                "fileId" : null,
                "extension": '.' + fileName.split('.').pop(),
                "fileBlob" : fileBlob
            }
            const resultFilePost = await postApi("/appFile/", fileBody)

            const data = {
                type: selectedType,
                variant: selectedVariant,
                contentType: selectedContentType,
                recordsList: selectedContentType === "LIST" ? recordsList : [],
                title: title,
                text: selectedContentType === "TEXT" ? text : "",
                position: textBoxesSize,
                buttonLabel: buttonLabel,
                buttonLink: buttonLink,
                file: { ...resultFilePost.data, imageAlt: imageAlt }
            }
            const body = {
                page: page,
                type: 'TEXT_BOX',
                data: JSON.stringify(data)
            }
            await postApi('/guiComponent/', body)
            await getTextBoxes()
            closeAndClear()
        }
    }

    const onUploadFile = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const reader = new FileReader();
        reader.onload = (event) => {
            const base64String = event.target.result;
            setFileBlob(base64String.split(',')[1])
        };
        reader.readAsDataURL(file);
    }

    return (
        show &&
        <Modal title="Dodaj nowy tekst box"
               onCancel={() => cancel()}
               onApprove={() => approve()}>
            <Row>
                <Col>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <strong>Typ tekst boxa:</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-sm-flex justify-content-center">
                            <Radio options={types} value={selectedType} setValue={setSelectedType}/>
                        </Col>
                    </Row>
                    <Space space={30}/>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <strong>Wariant koloru:</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-sm-flex justify-content-center" xs={12}>
                            <Radio options={selectedType === "BASIC" ?
                                variantBasic :
                                variantOverriding}
                                   value={selectedVariant}
                                   setValue={setSelectedVariant}
                                   isValid={!selectedVariantValidation}
                            />
                        </Col>
                        <Col className="d-sm-flex justify-content-center" xs={12}>
                            {selectedVariantValidation ? <span className="text-danger">{selectedVariantValidation}</span> : null}
                        </Col>
                    </Row>
                    <Space space={30}/>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <UploadFile label="Dodaj grafikę" onChange={(e) => onUploadFile(e)}/>
                        </Col>
                    </Row>
                    <Space space={30}/>
                    <Row>
                        <Col className="d-sm-flex justify-content-center">
                            {
                                selectedType === "OVERRIDING" ?
                                    <p>Sugerowany rozmiar: wysokość: 440px szerokość: 840px </p>
                                    :
                                    <p>Sugerowany rozmiar: wysokość: 390px szerokość: 530px </p>
                            }
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Col sm={6}>
                            <Input id='ALT' label='ALT Grafiki' type='text'
                                   value={imageAlt}
                                   onChange={(e) => setImageAlt(e.target.value)}/>
                        </Col>
                    </Row>
                    <Space space={30}/>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <strong>Przycisk:</strong>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Col sm={6} className='mt-3'>
                            <Input id='buttonLabel'
                                   label='Etykieta przycisku'
                                   type='text'
                                   value={buttonLabel}
                                   onChange={(e) => setButtonLabel(e.target.value)}/>
                        </Col>
                        <Col sm={6} className='mt-3'>
                            <Input id='buttonLink'
                                   label='Link przycisku'
                                   type='text'
                                   value={buttonLink}
                                   onChange={(e) => setButtonLink(e.target.value)}/>
                        </Col>
                    </Row>
                    <Space space={30}/>
                    <Row>
                        <Col>
                            <TextArea label='Tytuł'
                                      defaultValue={title}
                                      rows={2}
                                      isValid={titleValidation === null}
                                      onChange={(e) => setTitle(e.target.value)}/>
                            {titleValidation ? <span className="text-danger">{titleValidation}</span> : null}
                        </Col>
                    </Row>
                    <Space space={30}/>
                    <Row>
                        <Col className="d-sm-flex justify-content-center">
                            <Radio options={contentType} value={selectedContentType} setValue={setSelectedContentType}/>
                        </Col>
                    </Row>
                    {
                        selectedContentType === "TEXT" && selectedType !== "OVERRIDING" &&
                        <Row>
                            <Col>
                                <TextArea label='Treść'
                                          defaultValue={text}
                                          rows={6}
                                          onChange={(e) => setText(e.target.value)}/>
                            </Col>
                        </Row>
                    }
                    {
                        selectedContentType === "LIST" &&
                        <ListRecords recordsList={recordsList} setRecordsList={setRecordsList}/>
                    }
                </Col>
            </Row>
        </Modal>
    )
};

export default SaveTextBoxModal;