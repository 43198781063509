import {CallAPI, CallAPIWithFile, CallAPIWithoutResponse} from "../../../../api/API";
// todo refactor
export const updateTemplateImage = async ({url, id, accessToken, setError, body}) => {
    const result = await CallAPIWithoutResponse('/imageTemplate/' + url + '/' + id, body, 'PUT', accessToken)
    if (!result.isSuccess) {
        setError({
            show: true,
            title: "Bląd zapisywania",
            message: "Błąd zapisywania danych"
        })
    }
}

export const addImage = async ({id, accessToken, setError, image, type, setImage}) => {
    const body = new FormData();
    body.append('image', image)
    body.append('type', type)

    const result = await CallAPIWithFile('/imageTemplate/image/' + id, body, accessToken)
    if (result.isSuccess) {
        setImage(image.name)
    } else {
        setError({
            show: true,
            title: "Bląd zapisania",
            message: "Błąd podczas zapisywania grafiki"
        })
    }
}

export const addSize = async ({id, body, accessToken, setError, setUpdate}) => {
    const result = await CallAPIWithoutResponse('/imageTemplate/size/' + id, body, 'POST', accessToken)
    if (result.isSuccess) {
        setUpdate(true)
    } else {
        setError({
            show: true,
            title: "Bląd dodawania",
            message: "Błąd dodawania rozmiaru"
        })
    }
}

export const deleteImage = async ({id, accessToken, setError, type, setImage}) => {
    const result = await CallAPIWithoutResponse('/imageTemplate/image/' + id + '/' + type, null, 'DELETE', accessToken)
    if (result.isSuccess) {
        setImage(null)
    } else {
        setError({
            show: true,
            title: "Bląd usuwania",
            message: "Błąd usuwania grafiki"
        })
    }
}

export const deleteSize = async ({id, accessToken, body, setError, setUpdate}) => {
    const result = await CallAPIWithoutResponse('/imageTemplate/size/' + id, body, 'DELETE', accessToken)
    if (result.isSuccess) {
        setUpdate(true)
    } else {
        setError({
            show: true,
            title: "Bląd usuwania",
            message: "Błąd usuwania rozmiaru"
        })
    }
}

export const deleteImageTemplate= async ({id, accessToken, setError, setUpdate}) => {
    const result = await CallAPIWithoutResponse('/imageTemplate/delete/' + id, null, 'DELETE', accessToken)
    if (result.isSuccess) {
        setUpdate(true)
    } else {
        setError({
            show: true,
            title: "Bląd usuwania",
            message: "Błąd usuwania szablonu"
        })
    }
}

export const getImageCategory = async ({setError, setCategories, setCategoriesData}) => {
    const result = await CallAPI('/imageCategory/all', null, 'GET')
    if (result.isSuccess) {
        setCategoriesData(result.data)
        const categories = []
        categories.push('')
        result.data.forEach(category => {
            categories.push(category.link)
        })
        setCategories(categories)
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania kategorii"
        })
    }
}