import {CallAPI, CallAPIWithoutResponse} from "../../../api/API";
// todo refactor
export const getCompany = async (setLoad, setError, setCompany) => {
    const result = await CallAPI('/company', null, 'GET')
    if (result.isSuccess) {
        setCompany(result.data)
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania danych firmy"
        })
    }
    setLoad(false)
}

export const saveCompany = async (accessToken, setError, name, street, addressNumber, zipCode, city, phone, nip, accountNumber, bank) => {

    const body = {
        name: name,
        street: street,
        addressNumber: addressNumber,
        zipCode: zipCode,
        city: city,
        phone: phone,
        nip: nip,
        accountNumber: accountNumber,
        bank: bank
    }

    const result = await CallAPIWithoutResponse('/company/add', body, 'POST', accessToken)
    if (!result.isSuccess) {
        setError({
            show: true,
            title: "Bląd zapisania",
            message: "Błąd podczas zapisywania danych firmy"
        })
    }
}