import {CallSecurityAPI} from "../../../../api/API";
// todo refactor
export const getAllTemplatesImage = async ({accessToken, setError, setData}) => {
    const result = await CallSecurityAPI('/imageTemplate/all', null, 'GET', accessToken)
    if (result.isSuccess) {
        setData(result.data)
    } else {
        setError({
            show: true,
            title: "Bląd dodawania",
            message: "Błąd dodawania nowego szablonu"
        })
    }
}