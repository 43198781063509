import React, {useContext, useRef, useState} from "react";
import {AppContext} from "../../../../context/AppContext";
import {ImageTemplateContext} from "../../../../context/ImageTemplateContext";
import {addImage, deleteImage, updateTemplateImage} from "./functions";
import Input from "../../../form/input";
import TextArea from "../../../form/textArea";
import PrimaryButton from "../../../buttons/primaryButton";
import FileInput from "../../../form/fileInput";
import FileInfo from "../../../fileInfo";
// todo refactor
const ContentTab = () => {

    const {user, setError} = useContext(AppContext);
    const {
        id,
        header,
        helpMovieLink,
        contentMovieLink,
        firstContentTitle,
        firstContentText,
        secondContentTitle,
        secondContentText,
        imageSmallSecondContent,
        imageSmallSecondContentAlt,
        imageBigSecondContent,
        imageBigSecondContentAlt,
        lastContentTitle,
        lastContentText,
        imageSmallLastContent,
        imageSmallLastContentAlt,
        imageBigLastContent,
        imageBigLastContentAlt
    } = useContext(ImageTemplateContext)

    const headerRef = useRef('')
    const helpMovieLinkRef = useRef('')
    const contentMovieLinkRef = useRef('')
    const firstContentTitleRef = useRef('')
    const firstContentTextRef = useRef('')
    const secondContentTitleRef = useRef('')
    const secondContentTextRef = useRef('')
    const imageSmallSecondContentFileRef = useRef('')
    const imageSmallSecondContentAltRef = useRef('')
    const imageBigSecondContentFileRef = useRef('')
    const imageBigSecondContentAltRef = useRef('')
    const lastContentTitleRef = useRef('')
    const lastContentTextRef = useRef('')
    const imageSmallLastContentFileRef = useRef('')
    const imageSmallLastContentAltRef = useRef('')
    const imageBigLastContentFileRef = useRef('')
    const imageBigLastContentAltRef = useRef('')

    const [imageSmallSecondContentName, setImageSmallSecondContentName] = useState(imageSmallSecondContent)
    const [imageBigSecondContentName, setImageBigSecondContentName] = useState(imageBigSecondContent)
    const [imageSmallLastContentName, setImageSmallLastContentName] = useState(imageSmallLastContent)
    const [imageBigLastContentName, setImageBigLastContentName] = useState(imageBigLastContent)

    return (

        <>
            <div className="mb-3">
                <Input id={'header'} label={'Nagłówek'} type={'text'}
                       reference={headerRef} require={true} value={header}/>
            </div>
            <div className="mb-3">
                <Input id={'helpMovieLink'} label={'Link film pomocniczny'} type={'text'}
                       reference={helpMovieLinkRef} require={true} value={helpMovieLink}/>
            </div>
            <div className="mb-3">
                <Input id={'contentMovieLink'} label={'Link do filmu w treści'} type={'text'}
                       reference={contentMovieLinkRef} require={true} value={contentMovieLink}/>
            </div>
            <div className="mb-3">
                <Input id={'firstContentTitle'} label={'Tytuł pierwszej treści'} type={'text'}
                       reference={firstContentTitleRef} require={true} value={firstContentTitle}/>
            </div>
            <div className="mb-3">
                <TextArea id={'firstContentText'} label={'Pierwsza treść'} reference={firstContentTextRef}
                          defaultValue={firstContentText} require={true} rows={8}/>
            </div>
            <div className="mb-3">
                <Input id={'secondContentTitle'} label={'Tytuł drugiej treści'} type={'text'}
                       reference={secondContentTitleRef} require={true} value={secondContentTitle}/>
            </div>
            <div className="mb-3">
                <TextArea id={'secondContentText'} label={'Druga treść'} reference={secondContentTextRef}
                          defaultValue={secondContentText} require={true} rows={8}/>
            </div>
            <div className="mb-3">
                {imageSmallSecondContentName ?
                    <FileInfo
                        header={'Mała grafika - druga treść'}
                        fileName={imageSmallSecondContentName}
                        src={`/template/${id}/${imageSmallSecondContentName}`}
                        onClick={() => deleteImage({
                            id: id,
                            accessToken: user.accessToken,
                            setError: setError,
                            type: 'imageSmallSecondContent',
                            setImage: setImageSmallSecondContentName
                        })}
                    />
                    :
                    <FileInput id={"imageSmallSecondContent"} label={"Mała grafika - druga treść"} require={true}
                               reference={imageSmallSecondContentFileRef}
                               onChange={() => addImage({
                                   id: id,
                                   accessToken: user.accessToken,
                                   setError: setError,
                                   type: 'imageSmallSecondContent',
                                   image: imageSmallSecondContentFileRef.current.files[0],
                                   setImage: setImageSmallSecondContentName
                               })}/>
                }
            </div>
            <div className="mb-3">
                <Input id={'imageSmallSecondContentAlt'} label={'Alt małej grafiki - druga treść'} type={'text'}
                       reference={imageSmallSecondContentAltRef} require={true} value={imageSmallSecondContentAlt}/>
            </div>
            <div className="mb-3">
                <Input id={'lastContentTitle'} label={'Tytuł trzeciej treści'} type={'text'}
                       reference={lastContentTitleRef} require={true} value={lastContentTitle}/>
            </div>
            <div className="mb-3">
                <TextArea id={'lastContentText'} label={'Trzecia treść'} reference={lastContentTextRef}
                          defaultValue={lastContentText} require={true} rows={8}/>
            </div>
            <div className="mb-3">
                {imageSmallLastContentName ?
                    <FileInfo
                        header={'Mała grafika - trzecia treść'}
                        fileName={imageSmallLastContentName}
                        src={`/template/${id}/${imageSmallLastContentName}`}
                        onClick={() => deleteImage({
                            id: id,
                            accessToken: user.accessToken,
                            setError: setError,
                            type: 'imageSmallLastContent',
                            setImage: setImageSmallLastContentName
                        })}
                    />
                    :
                    <FileInput id={"imageSmallLastContent"} label={"Mała grafika - trzecia treść"} require={true}
                               reference={imageSmallLastContentFileRef}
                               onChange={() => addImage({
                                   id: id,
                                   accessToken: user.accessToken,
                                   setError: setError,
                                   type: 'imageSmallLastContent',
                                   image: imageSmallLastContentFileRef.current.files[0],
                                   setImage: setImageSmallLastContentName
                               })}/>
                }
            </div>
            <div className="mb-3">
                <Input id={'imageSmallLastContentAlt'} label={'Alt małej grafiki - trzecia treść'} type={'text'}
                       reference={imageSmallLastContentAltRef} require={true} value={imageSmallLastContentAlt}/>
            </div>
            <div className="mb-3">
                {imageBigSecondContentName ?
                    <FileInfo
                        header={'Duża grafika 1'}
                        fileName={imageBigSecondContentName}
                        src={`/template/${id}/${imageBigSecondContentName}`}
                        onClick={() => deleteImage({
                            id: id,
                            accessToken: user.accessToken,
                            setError: setError,
                            type: 'imageBigSecondContent',
                            setImage: setImageBigSecondContentName
                        })}
                    />
                    :
                    <FileInput id={"imageBigSecondContent"} label={"Duża grafika 1"} require={true}
                               reference={imageBigSecondContentFileRef}
                               onChange={() => addImage({
                                   id: id,
                                   accessToken: user.accessToken,
                                   setError: setError,
                                   type: 'imageBigSecondContent',
                                   image: imageBigSecondContentFileRef.current.files[0],
                                   setImage: setImageBigSecondContentName
                               })}/>
                }
            </div>
            <div className="mb-3">
                <Input id={'imageBigSecondContentAlt'} label={'Alt dużej grafiki 1'} type={'text'}
                       reference={imageBigSecondContentAltRef} require={true} value={imageBigSecondContentAlt}/>
            </div>
            <div className="mb-3">
                {imageBigLastContentName ?
                    <FileInfo
                        header={'Duża grafika 2'}
                        fileName={imageBigLastContentName}
                        src={`/template/${id}/${imageBigLastContentName}`}
                        onClick={() => deleteImage({
                            id: id,
                            accessToken: user.accessToken,
                            setError: setError,
                            type: 'imageBigLastContent',
                            setImage: setImageBigLastContentName
                        })}
                    />
                    :
                    <FileInput id={"imageBigLastContent"} label={"Duża grafika 2"} require={true}
                               reference={imageBigLastContentFileRef}
                               onChange={() => addImage({
                                   id: id,
                                   accessToken: user.accessToken,
                                   setError: setError,
                                   type: 'imageBigLastContent',
                                   image: imageBigLastContentFileRef.current.files[0],
                                   setImage: setImageBigLastContentName
                               })}/>
                }
            </div>
            <div className="mb-3">
                <Input id={'imageBigLastContentAlt'} label={'Alt dużej grafiki 2'} type={'text'}
                       reference={imageBigLastContentAltRef} require={true} value={imageBigLastContentAlt}/>
            </div>
            <PrimaryButton label={'Zapisz'}
                           onClickFunction={
                               () => updateTemplateImage(
                                   {
                                       url: 'updateContent',
                                       id: id,
                                       setError: setError,
                                       accessToken: user.accessToken,
                                       body: {
                                           header: headerRef.current.value,
                                           helpMovieLink: helpMovieLinkRef.current.value,
                                           contentMovieLink: contentMovieLinkRef.current.value,
                                           firstContentTitle: firstContentTitleRef.current.value,
                                           firstContentText: firstContentTextRef.current.value,
                                           secondContentTitle: secondContentTitleRef.current.value,
                                           secondContentText: secondContentTextRef.current.value,
                                           imageSmallSecondContentAlt: imageSmallSecondContentAltRef.current.value,
                                           imageBigSecondContentAlt: imageBigSecondContentAltRef.current.value,
                                           lastContentTitle: lastContentTitleRef.current.value,
                                           lastContentText: lastContentTextRef.current.value,
                                           imageSmallLastContentAlt: imageSmallLastContentAltRef.current.value,
                                           imageBigLastContentAlt: imageBigLastContentAltRef.current.value
                                       }
                                   }
                               )}
            />
        </>
    )
}

export default ContentTab;