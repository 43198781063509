import React, {useContext, useRef} from "react";
import {AppContext} from "../../../../context/AppContext";
import {ImageTemplateContext} from "../../../../context/ImageTemplateContext";
import {updateTemplateImage} from "./functions";
import Input from "../../../form/input";
import Select from "../../../form/select";
import TextArea from "../../../form/textArea";
import PrimaryButton from "../../../buttons/primaryButton";
// todo refactor
const BaseDataTab = () => {

    const {user, setError} = useContext(AppContext);
    const {
        id,
        description,
        name,
        link,
        title,
        metaDescription,
        metaKeywords,
        metaRobots,
        linkCanonical,
        siteImage,
        openGraph
    } = useContext(ImageTemplateContext)

    const nameRef = useRef("");
    const descriptionRef = useRef("");
    const linkRef = useRef("");
    const titleRef = useRef("");
    const metaDescriptionRef = useRef("");
    const metaKeywordsRef = useRef("");
    const metaRobotsRef = useRef("");
    const linkCanonicalRef = useRef("");
    const siteImageRef = useRef("");
    const openGraphRef = useRef("");

    return (

        <>
            <div className="mb-3">
                <Input id={'name'} label={'Nazwa'} type={'text'}
                       reference={nameRef} require={true} value={name}/>
            </div>
            <div className="mb-3">
                <TextArea id={'description'} label={'Opis (notatka)'} reference={descriptionRef}
                          defaultValue={description} require={true} rows={8}/>
            </div>
            <div className="mb-3">
                <Input id={'link'} label={'Link'} type={'text'}
                       reference={linkRef} require={true} value={link}/>
            </div>
            <div className="mb-3">
                <Input id={'title'} label={'Tytuł'} type={'text'}
                       reference={titleRef} require={true} value={title}/>
            </div>
            <div className="mb-3">
                <Input id={'metaDescription'} label={'Metadane - opis'} type={'text'}
                       reference={metaDescriptionRef} require={true} value={metaDescription}/>
            </div>
            <div className="mb-3">
                <Input id={'metaKeywords'} label={'Metadane - słowa kluczowe'} type={'text'}
                       reference={metaKeywordsRef} require={true} value={metaKeywords}/>
            </div>
            <div className="mb-3">
                <Select id={'metaRobots'} label={'Metadane - robots'} reference={metaRobotsRef}
                        require={true} value={metaRobots}
                        optionsData={['', 'index,follow', 'noindex,follow', 'noindex,nofollow', 'index,nofollow']}/>
            </div>
            <div className="mb-3">
                <Input id={'linkCanonical'} label={'Kanoniczny URL'} type={'text'}
                       reference={linkCanonicalRef} require={true} value={linkCanonical}/>
            </div>
            <div className="mb-3">
                <Input id={'siteImage'} label={'Site image'} type={'text'}
                       reference={siteImageRef} require={true} value={siteImage}/>
            </div>
            <div className="mb-3">
                <TextArea id={'openGraph'} label={'Open Graph'} reference={openGraphRef}
                          defaultValue={openGraph} require={true} rows={8}/>
            </div>
            <PrimaryButton label={'Zapisz'}
                           onClickFunction={
                               () => updateTemplateImage(
                                   {
                                       url: 'updateBase',
                                       id: id,
                                       setError: setError,
                                       accessToken: user.accessToken,
                                       body: {
                                           name: nameRef.current.value,
                                           description: descriptionRef.current.value,
                                           link: linkRef.current.value,
                                           title: titleRef.current.value,
                                           metaDescription: metaDescriptionRef.current.value,
                                           metaKeywords: metaKeywordsRef.current.value,
                                           metaRobots: metaRobotsRef.current.value,
                                           linkCanonical: linkCanonicalRef.current.value,
                                           siteImage: siteImageRef.current.value,
                                           openGraph: openGraphRef.current.value,
                                       }
                                   }
                               )}
            />
        </>
    )
}

export default BaseDataTab;