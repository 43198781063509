import React from "react";
import Card from "../card";
import BlogEntryRecord from "./BlogEntryRecord";

const BlogEntryListCard = ({blogData, setBlogData, setActiveEntry}) => {

    const entries = []
    blogData.forEach((category) => {
        category.entry.forEach((entry) => {
            entries.push(entry)
        })
    })

    return (
        <Card cardName="Wpisy bloga">
            {entries.map((entry) => {
                return <BlogEntryRecord
                    key={entry.link}
                    entry={entry}
                    setBlogData={setBlogData}
                    setActiveEntry={setActiveEntry}
                />
            })}
        </Card>
    )
};

export default BlogEntryListCard;