import React, {useEffect, useState} from "react";
import PrimaryButton from "../../buttons/primaryButton";
import Spinner from "../../spinner";
import Select from "../../form/select";
import {useApi} from "../../../hooks/useApi";

const RecommendedProductCards = () => {

    const [isLoad, setLoad] = useState(true);
    const [id, setId] = useState(null);
    const [options, setOptions] = useState([])
    const [products, setProducts] = useState([])
    const [recommendedProductOne, setRecommendedProductOne] = useState('')
    const [recommendedProductOneId, setRecommendedProductOneId] = useState(null)
    const [recommendedProductTwo, setRecommendedProductTwo] = useState('')
    const [recommendedProductTwoId, setRecommendedProductTwoId] = useState(null)

    const {getApi, putApi, postApi} = useApi()

    const onClickSave = async () => {
        const data = {
            recommendedProductOne: {name: recommendedProductOne, id: recommendedProductOneId},
            recommendedProductTwo: {name: recommendedProductTwo, id: recommendedProductTwoId}
        }
        const body = {
            page: 'BLOG',
            type: 'RECOMMENDED_PRODUCT',
            data: JSON.stringify(data)
        }
        if (id === null) {
            await postApi('/guiComponent/', body)
        } else {
            await putApi('/guiComponent/' + id, body)
        }
    }

    const getRecommendedProducts = async () => {
        const result = await getApi('/guiComponent/BLOG/RECOMMENDED_PRODUCT')
        if (result.isSuccess && result.data.length > 0) {
            const data = JSON.parse(result.data[0].data)
            setId(result.data[0].id)
            setRecommendedProductOne(data.recommendedProductOne.name)
            setRecommendedProductTwo(data.recommendedProductTwo.name)
        }
        const resultProducts = await getApi('/imageTemplate/all')
        if (result.isSuccess) {
            setProducts(resultProducts.data)
            setOptions(resultProducts.data.map(product => {return product.name}))
        }
        setLoad(false)
    }

    useEffect(() => {
        void getRecommendedProducts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="col-lg-5">
            <div className="card card-header-actions mb-4">
                <div className="card-header">
                    Polecane Produkty
                    <PrimaryButton
                        label='Zapisz'
                        onClickFunction={() => onClickSave()}
                    />
                </div>
                <div className="card-body">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        <>
                            <div className="mb-3">
                                <Select
                                    label='Polecany produkt - 1'
                                    value={recommendedProductOne}
                                    optionsData={options}
                                    onChange={(e) => {
                                        setRecommendedProductOne(e.target.value)
                                        setRecommendedProductOneId(products.find(record => record.name === e.target.value)?.id)
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <Select
                                    label='Polecany produkt - 2'
                                    value={recommendedProductTwo}
                                    optionsData={options}
                                    onChange={(e) => {
                                        setRecommendedProductTwo(e.target.value)
                                        setRecommendedProductTwoId(products.find(record => record.name === e.target.value)?.id)
                                    }}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
};

export default RecommendedProductCards;