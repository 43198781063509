import React, {useState} from "react";
import Card from "../card";
import {Col, Row} from "react-bootstrap";
import Input from "../../form/input";
import Space from "../../layout/Space";
import TextArea from "../../form/textArea";
import UploadFile from "../../form/uploadFile/UploadFile";
import {useApi} from "../../../hooks/useApi";

const BlogEntryCard = ({activeEntry, setBlogData}) => {

    const [file, setFile] = useState(activeEntry?.file)
    const [fileBlob, setFileBlob] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [alt, setAlt] = useState(activeEntry?.file?.imageAlt)
    const [cardTitle, setCardTitle] = useState(activeEntry?.cardTitle)
    const [cardDescription, setCardDescription] = useState(activeEntry?.cardDescription ? activeEntry?.cardDescription : '' )

    const {getApi, postApi, putApi, deleteApi} = useApi()

    const onUploadFile = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const reader = new FileReader();
        reader.onload = (event) => {
            const base64String = event.target.result;
            setFileBlob(base64String.split(',')[1])
        };
        reader.readAsDataURL(file);
    }

    const onDeleteFile = async () => {
        await deleteApi("/appFile/" + file?.id)
        setFile(null)
        let id = null
        let data = []
        const result = await getApi('/guiComponent/BLOG/BLOG_DATA')
        if (result.isSuccess && result.data.length > 0) {
            for (const category of JSON.parse(result.data[0].data)) {
                let entries = []
                for (const entry of category.entry) {
                    if (entry.link === activeEntry.link) {
                        entry.file = null
                    }
                    entries.push(entry)
                }
                category.entry = entries
                data.push(category)
            }
            id = result.data[0].id
        }
        const body = {
            page: 'BLOG',
            type: 'BLOG_DATA',
            data: JSON.stringify(data)
        }
        await putApi('/guiComponent/' + id, body)
        const resultAfterSave = await getApi('/guiComponent/BLOG/BLOG_DATA')
        if (resultAfterSave.isSuccess && resultAfterSave.data.length > 0) {
            setBlogData(JSON.parse(resultAfterSave.data[0].data));
        }
    }

    const onSave = async () => {
        let id = null
        let data = []
        const result = await getApi('/guiComponent/BLOG/BLOG_DATA')
        if (result.isSuccess && result.data.length > 0) {
            for (const category of JSON.parse(result.data[0].data)) {
                let entries = []
                for (const entry of category.entry) {
                    if (entry.link === activeEntry.link) {
                        // jak jest fileBlob to wiadomo ze zostala zmieniona grafika,
                        // jak nie zostala zmieniona to nie wysylamy starej na serwer bo juz tam jest.
                        if (fileBlob) {
                            const fileBody = {
                                "id": null,
                                "fileName": fileName,
                                "fileId": null,
                                "extension": '.' + fileName.split('.').pop(),
                                "fileBlob": fileBlob
                            }
                            const resultFilePost = await postApi("/appFile/", fileBody)
                            entry.file = {...resultFilePost.data, imageAlt: alt}
                        } else {
                            entry.file = {...file, imageAlt: alt}
                        }
                        entry.cardTitle = cardTitle
                        entry.cardDescription = cardDescription
                    }
                    entries.push(entry)
                }
                category.entry = entries
                data.push(category)
            }
            id = result.data[0].id
        }
        const body = {
            page: 'BLOG',
            type: 'BLOG_DATA',
            data: JSON.stringify(data)
        }
        await putApi('/guiComponent/' + id, body)
        const resultAfterSave = await getApi('/guiComponent/BLOG/BLOG_DATA')
        if (resultAfterSave.isSuccess && resultAfterSave.data.length > 0) {
            setBlogData(JSON.parse(resultAfterSave.data[0].data));
            let file = null
            for (const category of JSON.parse(resultAfterSave.data[0].data)) {
                for (const entry of category.entry) {
                    if (entry.link === activeEntry.link) {
                        file = entry.file
                    }
                }
            }
            setFile(file)
        }
    }

    return (
        <Card cardName='Karta wpisu'
              buttonLabel='Zapisz'
              showHeaderButton={true}
              onClickButton={() => onSave()}>
            <Row>
                <Col>
                    <UploadFile file={file}
                                label="Przesłana grafika"
                                onDelete={() => onDeleteFile()}
                                onChange={(e) => onUploadFile(e)}
                    />
                </Col>
            </Row>
            <Space space={30}/>
            <Row>
                <Col>
                    <Input
                        label='Alt grafiki'
                        type='text'
                        value={alt}
                        onChange={(e) => setAlt(e.target.value)}
                    />
                </Col>
            </Row>
            <Space space={30}/>
            <Row>
                <Col>
                    <Input
                        label='Tytuł karty'
                        type='text'
                        value={cardTitle}
                        onChange={(e) => setCardTitle(e.target.value)}
                    />
                </Col>
            </Row>
            <Space space={30}/>
            <Row>
                <Col>
                    <TextArea
                        label='Opis karty'
                        value={cardDescription}
                        onChange={(e) => setCardDescription(e.target.value)}
                        rows={4}
                    />
                </Col>
            </Row>
        </Card>
    );
};

export default BlogEntryCard;