import React, {useContext, useEffect, useRef, useState} from "react";
import PrimaryButton from "../../buttons/primaryButton";
import TextArea from "../../form/textArea";
import Spinner from "../../spinner";
import {getContactData, saveContactData} from "./Functions";
import {AppContext} from "../../../context/AppContext";
import Input from "../../form/input";
// todo refactor
const ContactCard = () => {

    const {user, setError} = useContext(AppContext);
    const [isLoad, setLoad] = useState(true);
    const [data, setData] = useState({});
    const mainHeaderRef = useRef("");
    const subHeaderRef = useRef("");
    const descriptionRef = useRef("");
    const contentRef = useRef("");

    useEffect(() => {
        void getContactData(setLoad, setError, setData)
    }, [])

    return (
        <div className="col-lg-9">
            <div className="card card-header-actions">
                <div className="card-header">
                    Treść strony
                    <PrimaryButton label={'Zapisz'}
                                   onClickFunction={() => saveContactData(
                                       user.accessToken,
                                       setError,
                                       mainHeaderRef.current.value,
                                       subHeaderRef.current.value,
                                       descriptionRef.current.value,
                                       contentRef.current.value,
                                   )}/>
                </div>
                <div className="card-body d-flex flex-column justify-content-center">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        <>
                            <div className="mb-3">
                                <Input id={'mainHeader'} label={'Tytuł'} type={'text'}
                                       reference={mainHeaderRef} require={true} value={data.mainHeader}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'subHeader'} label={'Podtytuł'} type={'text'}
                                       reference={subHeaderRef} require={true} value={data.subHeader}/>
                            </div>
                            <div className="mb-3">
                                <TextArea label={'Opis'} id={'description'} rows={4} reference={descriptionRef}
                                          defaultValue={data.description}/>
                            </div>
                            <div className="mb-3">
                                <TextArea label={'Treść'} id={'content'} rows={4} reference={contentRef}
                                          defaultValue={data.content}/>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default ContactCard;