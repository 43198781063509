import React, {useEffect, useState} from "react";
import PrimaryButton from "../../buttons/primaryButton";
import Spinner from "../../spinner";
import Input from "../../form/input";
import Select from "../../form/select";
import TextArea from "../../form/textArea";
import {useApi} from "../../../hooks/useApi";

const BasicPageDataCard = ({page}) => {

    const [isLoad, setLoad] = useState(true);
    const [id, setId] = useState(null);
    const [title, setTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');
    const [metaRobots, setMetaRobots] = useState('');
    const [linkCanonical, setLinkCanonical] = useState('');
    const [siteImage, setSiteImage] = useState('');
    const [openGraph, setOpenGraph] = useState('');

    const {getApi, putApi, postApi} = useApi()

    const onClickSave = async () => {
        const data = {
            title: title,
            metaDescription: metaDescription,
            metaKeywords: metaKeywords,
            metaRobots: metaRobots,
            linkCanonical: linkCanonical,
            siteImage: siteImage,
            openGraph: openGraph
        }
        const body = {
            page: page,
            type: 'HEAD_DATA',
            data: JSON.stringify(data)
        }
        if (id === null) {
            await postApi('/guiComponent/', body)
        } else {
            await putApi('/guiComponent/' + id, body)
        }
    }

    const getHeadData = async () => {
        const result = await getApi('/guiComponent/' + page + '/HEAD_DATA')
        if (result.isSuccess && result.data.length > 0) {
            const data = JSON.parse(result.data[0].data)
            setId(result.data[0].id)
            setTitle(data?.title)
            setMetaDescription(data?.metaDescription)
            setMetaKeywords(data?.metaKeywords)
            setMetaRobots(data?.metaRobots)
            setLinkCanonical(data?.linkCanonical)
            setSiteImage(data?.siteImage)
            setOpenGraph(data?.openGraph)
        }
        setLoad(false)
    }

    useEffect(() => {
        void getHeadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="col-lg-6">
            <div className="card card-header-actions mb-4">
                <div className="card-header">
                    Podstawowe dane
                    <PrimaryButton
                        label='Zapisz'
                        onClickFunction={() => onClickSave()}
                    />
                </div>
                <div className="card-body">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        <>
                            <div className="mb-3">
                                <Input
                                    label='Tytuł'
                                    type='text'
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <Input
                                    label='Metadane - opis'
                                    type='text'
                                    value={metaDescription}
                                    onChange={(e) => setMetaDescription(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <Input
                                    label='Metadane - słowa kluczowe'
                                    type='text'
                                    value={metaKeywords}
                                    onChange={(e) => setMetaKeywords(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <Select
                                    label='Metadane - robots'
                                    value={metaRobots}
                                    optionsData={['', 'index,follow', 'noindex,follow', 'noindex,nofollow', 'index,nofollow']}
                                    onChange={(e) => setMetaRobots(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <Input
                                    label='Kanoniczny URL'
                                    type='text'
                                    value={linkCanonical}
                                    onChange={(e) => setLinkCanonical(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <Input
                                    label='Site image'
                                    type='text'
                                    value={siteImage}
                                    onChange={(e) => setSiteImage(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <TextArea
                                    label='Open Graph'
                                    defaultValue={openGraph}
                                    rows={8}
                                    onChange={(e) => setOpenGraph(e.target.value)}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
};

export default BasicPageDataCard;