import React, {useContext} from "react";
import {FileInfoContext} from "./FileInfoContext";
import FeatherIcon from "feather-icons-react";
// todo refactor
const DeleteButton = () => {

    const {onClick} = useContext(FileInfoContext)

    return (
        <button className="btn btn-transparent-dark" type="button" onClick={onClick}>
            <FeatherIcon icon="trash"/>
        </button>
    );
};

export default DeleteButton