import {CallAPI, CallAPIWithoutResponse} from "../../../api/API";
import React from "react";
import FeatherIcon from "feather-icons-react";
// todo refactor
export const getFaq = async (setLoad, setError, setFaq) => {
    const result = await CallAPI('/faq/all', null, 'GET')
    if (result.isSuccess) {
        const faqList = []
        result.data.forEach(faq => {
            faqList.push(
                <tr key={faq.id}>
                    <td>{faq.question}</td>
                    <td>{faq.answer}</td>
                    <td>
                        <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Usuń"
                                onClick={() => deleteFaq(faq.id, setLoad, setError, setFaq)}>
                            <FeatherIcon icon="trash"/>
                        </button>
                    </td>
                </tr>
            )
        })
        setFaq(faqList)
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania FAQ"
        })
    }
    setLoad(false)
}

export const saveFaq = async (accessToken, setError, setLoad, setFaq, question, answer) => {

    const body = {
        question: question,
        answer: answer
    }

    const result = await CallAPIWithoutResponse('/faq', body, 'POST', accessToken)
    if (result.isSuccess) {
        await getFaq(setLoad, setError, setFaq)
    } else {
        setError({
            show: true,
            title: "Bląd zapisania",
            message: "Błąd podczas zapisywania FAQ"
        })
    }
}

export const deleteFaq = async (id, setLoad, setError, setFaq) => {
    setLoad(true)
    const result = await CallAPIWithoutResponse('/faq/delete/' + id, null, 'DELETE', null)
    if (result.isSuccess) {
        await getFaq(setLoad, setError, setFaq)
    } else {
        setError({
            show: true,
            title: "Bląd usuwania",
            message: "Błąd podczas usuwania FAQ"
        })
    }
    setLoad(false)
}