import {CallAPIWithoutResponse, CallSecurityAPI} from "../../../api/API";
// todo refactor
export const getContactData = async (accessToken, setLoad, setError, setData) => {
    const result = await CallSecurityAPI('/templatePage', null, 'GET', accessToken)
    if (result.isSuccess) {
        setData(result.data)
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania podstawowych danych"
        })
    }
    setLoad(false)
}

export const saveContactData = async (accessToken, setError, header, content) => {

    const body = {
        topHeader: header,
        topContent: content
    }

    const result = await CallAPIWithoutResponse('/templatePage', body, 'PUT', accessToken)
    if (!result.isSuccess) {
        setError({
            show: true,
            title: "Bląd zapisania",
            message: "Błąd podczas zapisywania głównej treści strony"
        })
    }
}