import React, {useContext, useState} from "react";
import {AppContext} from "../../../context/AppContext";
import Spinner from "../../spinner";
import {onClick} from "./Functions";
// todo refactor
const GetPhotoButton = ({id, name, url}) => {

    const {user, setError} = useContext(AppContext);
    const [isLoad, setLoad] = useState(false)

    return (
        isLoad ?
            <Spinner/>
            :
            <button className="btn btn-link"
                    type="button"
                    key={id}
                    onClick={() => onClick(url, user.accessToken, name, setLoad, setError)}
            >
                {name}
            </button>

    )
}

export default GetPhotoButton;