import React from "react";
// todo refactor
const TextArea = ({id, label, reference, require, defaultValue, value, rows, onChange, isValid = true}) => {

    return (
        <>
            <label htmlFor={id}>{label}</label>
            <textarea className={`form-control ${isValid ? "" : "border-danger"}`}
                      id={id}
                      placeholder={label}
                      rows={rows}
                      defaultValue={defaultValue}
                      value={value}
                      required={require}
                      ref={reference}
                      onChange={onChange}/>
        </>
    )
}

export default TextArea;