import React from "react";

const Body = ({children}) => {

    return (
        <div className="modal-body">
            {children}
        </div>
    )
};

export default Body;