import {CallAPI} from "../../api/API";
// todo refactor
export const LoginFunction = async (event, setLoad, loginRef, passwordRef, setUser, setError) => {
    event.preventDefault()
    setLoad(true)
    const body = {
        login: loginRef.current.value,
        password: passwordRef.current.value
    }

    const result = await CallAPI('/login', body, 'POST')
    if (result.isSuccess) {
        setUser({
            logged: true,
            accessToken: "Bearer " + result.data.access_token,
        })
    } else {
        setError(true)
    }
    setLoad(false)
}