import React from "react";
import "./Modal.css"
import Background from "./Background";
import ModalBox from "./ModalBox";
import Body from "./Body";
import Header from "./Header";
import Footer from "./Footer";

const Modal = ({children, title, onApprove, onCancel, size = 'xl'}) => {

    return (
        <Background>
            <ModalBox size={size}>
                <Header title={title}/>
                <Body>
                    {children}
                </Body>
                <Footer onCancel={onCancel} onApprove={onApprove}/>
            </ModalBox>
        </Background>
    )
};

export default Modal;