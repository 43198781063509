import React, {useContext, useEffect, useState} from "react";
import {
    addExtraContent,
    getImageCategory,
    getImageSubCategory,
} from "./Functions";
import {ImageCardContext} from "../../../context/ImageCardContext";
import {AppContext} from "../../../context/AppContext";
import Spinner from "../../spinner";
import PrimaryButton from "../../buttons/primaryButton";
import ExtraContentBox from "./ExtraContentBox";
// todo refactor
const ExtraContentTab = ({isSubCategory}) => {

    const {activeCategory, activeSubCategory} = useContext(ImageCardContext);
    const {user, setError} = useContext(AppContext);

    const [isLoad, setLoad] = useState(true);
    const [data, setData] = useState({})
    const [contentsBox, setContentsBox] = useState([])

    useEffect(() => {
        if (isSubCategory) {
            void getImageSubCategory(activeSubCategory.id, setLoad, setError, setData)
        } else {
            void getImageCategory(activeCategory.id, setLoad, setError, setData)
        }
    }, [])

    useEffect(() => {
        if (data.extraContents !== undefined) {
            const boxs = []
            data.extraContents.forEach(item => {
                boxs.push(<ExtraContentBox data={item} setData={setData} setLoad={setLoad} isSubCategory={isSubCategory}
                                           key={item.id}/>)
            })
            setContentsBox(boxs);
        }
    }, [data])

    return (
        <>
            {isLoad ?
                <div className='d-flex justify-content-center'>
                    <Spinner/>
                </div>
                :
                <>
                    <PrimaryButton label={'Dodaj dodatkową treść'}
                                   onClickFunction={() =>
                                       addExtraContent(
                                           isSubCategory ? activeSubCategory.id : activeCategory.id,
                                           user.accessToken,
                                           setLoad,
                                           setError,
                                           isSubCategory,
                                           setData
                                       )
                                   }/>
                    <hr/>
                    {contentsBox}
                </>
            }
        </>
    )
}

export default ExtraContentTab;