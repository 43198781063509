import React, {useContext} from "react";
import BaseDataTab from "./baseDataTab";
import SubCategoryTab from "./subCategoryTab";
import TopContentTab from "./TopContentTab";
import ExtraContentTab from "./ExtraContentTab";
import Box from "./Box";
import {ImageCardContext} from "../../../context/ImageCardContext";
import TextBoxCard from "../textBoxCard/TextBoxCard";

const CategoryDetailsView = () => {

    const {activeCategory, isProject} = useContext(ImageCardContext);

    return (
        <div className="card">
            <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs"
                    id="cardTab"
                    role="tablist"
                >
                    <li className="nav-item">
                        <a className="nav-link active"
                           id="overview-tab"
                           href="#base"
                           data-bs-toggle="tab"
                           role="tab"
                           aria-controls="overview"
                           aria-selected="true"
                        >
                            Podstawowe dane
                        </a>
                    </li>
                    {isProject ?
                        null
                        :
                        <li className="nav-item">
                            <a className="nav-link"
                               id="example-tab"
                               href="#subcategory"
                               data-bs-toggle="tab"
                               role="tab"
                               aria-controls="example"
                               aria-selected="false"
                            >
                                Podkategorie
                            </a>
                        </li>
                    }
                    <li className="nav-item">
                        <a className="nav-link"
                           id="example-tab"
                           href="#topContent"
                           data-bs-toggle="tab"
                           role="tab"
                           aria-controls="example"
                           aria-selected="false"
                        >
                            Główna treść
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link"
                           id="example-tab"
                           href="#extraContent"
                           data-bs-toggle="tab"
                           role="tab"
                           aria-controls="example"
                           aria-selected="false"
                        >
                            Dodatkowa treść
                        </a>
                    </li>
                    {isProject ?
                        null
                        :
                        <li className="nav-item">
                            <a className="nav-link"
                               id="example-tab"
                               href="#box"
                               data-bs-toggle="tab"
                               role="tab"
                               aria-controls="example"
                               aria-selected="false"
                            >
                                Box
                            </a>
                        </li>
                    }
                    <li className="nav-item">
                        <a className="nav-link"
                           id="example-tab"
                           href="#textBoxex"
                           data-bs-toggle="tab"
                           role="tab"
                           aria-controls="example"
                           aria-selected="false"
                        >
                            Boxy z treścią
                        </a>
                    </li>
                </ul>
            </div>
            <div className="card-body">
                <div className="tab-content"
                     id="cardTabContent"
                >
                    <div className="tab-pane fade show active"
                         id="base"
                         role="tabpanel"
                         aria-labelledby="overview-tab"
                    >
                        <BaseDataTab isSubCategory={false}/>
                    </div>
                    <div className="tab-pane fade"
                         id="subcategory"
                         role="tabpanel"
                         aria-labelledby="example-tab"
                    >
                        <SubCategoryTab/>
                    </div>
                    <div className="tab-pane fade"
                         id="topContent"
                         role="tabpanel"
                         aria-labelledby="example-tab"
                    >
                        <TopContentTab isSubCategory={false}/>
                    </div>
                    <div className="tab-pane fade"
                         id="extraContent"
                         role="tabpanel"
                         aria-labelledby="example-tab"
                    >
                        <ExtraContentTab isSubCategory={false}/>
                    </div>
                    <div className="tab-pane fade"
                         id="box"
                         role="tabpanel"
                         aria-labelledby="example-tab"
                    >
                        <Box/>
                    </div>
                    <div className="tab-pane fade"
                         id="textBoxex"
                         role="tabpanel"
                         aria-labelledby="example-tab"
                    >
                        <TextBoxCard page={'CATEGORY-' + activeCategory.id}/>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default CategoryDetailsView;