import React from "react";
// todo refactor
const Input = ({id, label, type, reference, require, value, onChange}) => {

    return (
        <>
            <label className="small mb-1"
                   htmlFor={id}>
                {label}
            </label>
            <input className="form-control"
                   id={id}
                   type={type}
                   placeholder={label}
                   ref={reference}
                   required={require}
                   defaultValue={value}
                   onChange={onChange}
            />
        </>
    )
}

export default Input;