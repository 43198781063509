import React, {useState} from "react";
import GetPhotoButton from "../../buttons/getPhotoButton";
// todo refactor
const PrintingInColor = ({product, contextId, printLogo, activeOrder}) => {

    const [color, setColor] = useState('ffffff');

    return (
        <>
            <p>Plik do druku (zadrukowane boki w kolorze):
                {product.imageTemplate.type === 'Własne zdjęcie' ?
                    <GetPhotoButton id={product.id}
                                    name={product.photoName + '_' + product.editorSize.width + 'x' + product.editorSize.height + '_' + activeOrder.id + '_frame_color.jpeg'}
                                    url={'/approvedOrder/downloadPhoto/frame/' + contextId + '/' + product.id + '?printedSides=printingInColor&color=' + color + '&printLogo=' + printLogo}
                    />
                    :
                    <GetPhotoButton id={product.id}
                                    name={product.photoName + '_' + product.editorSize.width + 'x' + product.editorSize.height + '_' + activeOrder.id + '_frame_color.jpeg'}
                                    url={'/approvedOrder/downloadPhoto/graphic/' + contextId + '/' + product.id + '?printedSides=printingInColor&color=' + color + '&printLogo=' + printLogo}
                    />
                }
            </p>
            <div className="mb-3 col-2">
                <label className="small mb-1"
                       htmlFor='colorFrame'>
                    Kolor (HEX)
                </label>
                <input className="form-control"
                       id='colorFrame'
                       type='text'
                       placeholder='Kolor (HEX)'
                       onInput={(event)=> setColor(event.target.value.substring(1))}
                />
            </div>
        </>
    )
}

export default PrintingInColor