import React, {useEffect, useState} from "react";
import TabWrapper from "../TabWrapper";
import BasicPageDataCard from "../../cards/basicPageDataCard/BasicPageDataCard";
import TextBoxCard from "../../cards/textBoxCard/TextBoxCard";
import RecommendedProductCards from "../../cards/recommendedProductCards/RecommendedProductCards";
import BlogCategoryAddCard from "../../cards/blogCategoryCard/BlogCategoryAddCard";
import BlogCategoryListCard from "../../cards/blogCategoryCard/BlogCategoryListCard";
import {useApi} from "../../../hooks/useApi";

const BlogPage = () => {

    const [blogData, setBlogData] = useState([])
    const [activeCategory, setActiveCategory] = useState(null)

    const {getApi} = useApi()

    const getBlogData = async () => {
        const result = await getApi('/guiComponent/BLOG/BLOG_DATA')
        if (result.isSuccess && result.data.length > 0) {
            const data = JSON.parse(result.data[0].data)
            setBlogData(data)
        }
    }

    useEffect(() => {
        void getBlogData()
    }, [])

    return (
        <TabWrapper>
            {activeCategory == null ?
                <>
                    <BasicPageDataCard key={'BLOG'} page='BLOG'/>
                    <RecommendedProductCards/>
                    <BlogCategoryAddCard setBlogData={setBlogData}/>
                    <BlogCategoryListCard blogData={blogData}
                                          setBlogData={setBlogData}
                                          setActiveCategory={setActiveCategory}/>
                    <TextBoxCard page='BLOG'/>
                </>
                :
                <>
                    <BasicPageDataCard key={'BLOG_' + activeCategory.link} page={'BLOG_' + activeCategory.link}/>
                    <TextBoxCard page={'BLOG_' + activeCategory.link}/>
                </>
            }
        </TabWrapper>
    )
};

export default BlogPage;