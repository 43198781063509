import React, {useEffect, useState} from "react";

const Select = ({id, label, reference, require, value, onChange, optionsData}) => {

    const [options, setOptions] = useState([])
    const [activeValue, setActiveValue] = useState(value);

    useEffect(() => {
        const prepareOptions = optionsData.map((option, i) => {
            return (
                <option value={option} key={i}>{option}</option>
            )
        })
        setOptions(prepareOptions)
    }, [optionsData])

    useEffect(() => {
        setActiveValue(value)
    }, [value])

    const handleOnChange = (e) => {
        setActiveValue(e.target.value)
        onChange(e)
    }

    return (
        <>
            <label htmlFor={id}>{label}</label>
            <select className="form-control"
                    id={id}
                    ref={reference}
                    required={require}
                    onChange={(e) => handleOnChange(e)}
                    value={activeValue ? activeValue.toString() : ""}
            >
                {options}
            </select>
        </>
    )
}

export default Select;