import React, {useContext, useEffect, useRef, useState} from "react";
import Spinner from "../../spinner";
import Input from "../../form/input";
import {CallAPI} from "../../../api/API";
import {AppContext} from "../../../context/AppContext";
import FeatherIcon from "feather-icons-react";
import PrimaryButton from "../../buttons/primaryButton";
import TabWrapper from "../TabWrapper";
// todo refactor
const Footer = () => {

    const {setError} = useContext(AppContext);

    const [isLoad, setLoad] = useState(false);
    const [columnOneName, setColumnOneName] = useState('')
    const [columnTwoName, setColumnTwoName] = useState('')
    const [columnThreeName, setColumnThreeName] = useState('')
    const [columnOne, setColumnOne] = useState([])
    const [columnTwo, setColumnTwo] = useState([])
    const [columnThree, setColumnThree] = useState([])
    const [fbLink, setFbLink] = useState('')
    const [twitterLink, setTwitterLink] = useState('')

    const fbLinkRef = useRef("");
    const twitterLinkRef = useRef("");
    const colOneNameRef = useRef("");
    const colTwoNameRef = useRef("");
    const colThreeNameRef = useRef("");
    const nameRefOne = useRef("");
    const linkRefOne = useRef("");
    const nameRefTwo = useRef("");
    const linkRefTwo = useRef("");
    const nameRefThree = useRef("");
    const linkRefThree = useRef("");

    useEffect(() => {
        void getFooter()
    }, [])

    const getFooter = async () => {
        const result = await CallAPI('/footer', null, 'GET')
        if (result.isSuccess) {
            const data = result.data
            setColumnOneName(data.columnOneName)
            setColumnTwoName(data.columnTwoName)
            setColumnThreeName(data.columnThreeName)
            setFbLink(data.fbLink)
            setTwitterLink(data.twitterLink)

            const linksOne = []
            data.linksColumnOne.forEach(link => {
                linksOne.push(
                    <tr key={link.id}>
                        <td>{link.name}</td>
                        <td>{link.link}</td>
                        <td>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Przenieś niżej"
                                    onClick={async () => {
                                        await CallAPI('/footer/down/link/' + link.id, null, 'PUT')
                                        void await getFooter()
                                    }}
                            >
                                <FeatherIcon icon="arrow-down"/>
                            </button>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Przenieś wyżej"
                                    onClick={async () => {
                                        await CallAPI('/footer/up/link/' + link.id, null, 'PUT')
                                        void await getFooter()
                                    }}
                            >
                                <FeatherIcon icon="arrow-up"/>
                            </button>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Usuń"
                                    onClick={async () => {
                                        await CallAPI('/footer?column=1&linkId=' + link.id, null, 'DELETE')
                                        void await getFooter()
                                    }}
                            >
                                <FeatherIcon icon="trash"/>
                            </button>
                        </td>
                    </tr>
                )
            })
            setColumnOne(linksOne)

            const linksTwo = []
            data.linksColumnTwo.forEach(link => {
                linksTwo.push(
                    <tr key={link.id}>
                        <td>{link.name}</td>
                        <td>{link.link}</td>
                        <td>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Przenieś niżej"
                                    onClick={async () => {
                                        await CallAPI('/footer/down/link/' + link.id, null, 'PUT')
                                        void await getFooter()
                                    }}
                            >
                                <FeatherIcon icon="arrow-down"/>
                            </button>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Przenieś wyżej"
                                    onClick={async () => {
                                        await CallAPI('/footer/up/link/' + link.id, null, 'PUT')
                                        void await getFooter()
                                    }}
                            >
                                <FeatherIcon icon="arrow-up"/>
                            </button>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Usuń"
                                    onClick={async () => {
                                        await CallAPI('/footer?column=2&linkId=' + link.id, null, 'DELETE')
                                        void await getFooter()
                                    }}
                            >
                                <FeatherIcon icon="trash"/>
                            </button>
                        </td>
                    </tr>
                )
            })
            setColumnTwo(linksTwo)

            const linksThree = []
            data.linksColumnThree.forEach(link => {
                linksThree.push(
                    <tr key={link.id}>
                        <td>{link.name}</td>
                        <td>{link.link}</td>
                        <td>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Przenieś niżej"
                                    onClick={async () => {
                                        await CallAPI('/footer/down/link/' + link.id, null, 'PUT')
                                        void await getFooter()
                                    }}
                            >
                                <FeatherIcon icon="arrow-down"/>
                            </button>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Przenieś wyżej"
                                    onClick={async () => {
                                        await CallAPI('/footer/up/link/' + link.id, null, 'PUT')
                                        void await getFooter()
                                    }}
                            >
                                <FeatherIcon icon="arrow-up"/>
                            </button>
                            <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Usuń"
                                    onClick={async () => {
                                        await CallAPI('/footer?column=3&linkId=' + link.id, null, 'DELETE')
                                        void await getFooter()
                                    }}
                            >
                                <FeatherIcon icon="trash"/>
                            </button>
                        </td>
                    </tr>
                )
            })
            setColumnThree(linksThree)
        } else {
            setError({
                show: true,
                title: "Bląd pobierania",
                message: "Błąd pobierania danych stopki"
            })
        }
        setLoad(false)
    }

    return (
        <TabWrapper>
            <div className="col-lg-10">
                <div className="card card-header-actions mb-4">
                    <div className="card-header">
                        Linki social media
                    </div>
                    <div className="card-body">
                        {isLoad ?
                            <div className='d-flex justify-content-center'>
                                <Spinner/>
                            </div>
                            :
                            <>
                                <div className="mb-3 col-4">
                                    <Input id='fbLink'
                                           label='Facebook link'
                                           type='text'
                                           require={true}
                                           reference={fbLinkRef}
                                           value={fbLink}
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input id='twitterLink'
                                           label='Twitter link'
                                           type='text'
                                           require={true}
                                           reference={twitterLinkRef}
                                           value={twitterLink}
                                    />
                                </div>
                                <PrimaryButton
                                    label={'Zapisz'}
                                    onClickFunction={async () => {
                                        await CallAPI('/footer/socialMedia',
                                            {
                                                fbLink: fbLinkRef.current.value,
                                                twitterLink: twitterLinkRef.current.value
                                            }
                                            , 'POST')
                                    }}
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="col-lg-10">
                <div className="card card-header-actions mb-4">
                    <div className="card-header">
                        Kolumna 1
                    </div>
                    <div className="card-body">
                        {isLoad ?
                            <div className='d-flex justify-content-center'>
                                <Spinner/>
                            </div>
                            :
                            <>
                                <div className="mb-3 col-4">
                                    <Input id={'colOneName'} label={'Nagłówek - nazwa'} type={'text'}
                                           require={true} reference={colOneNameRef}
                                           value={columnOneName}
                                    />
                                </div>
                                <PrimaryButton
                                    label={'Zapisz'}
                                    onClickFunction={async () => {
                                        await CallAPI('/footer/columnName?column=1&name=' + colOneNameRef.current.value, null, 'POST')
                                    }}
                                />
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                        <tr>
                                            <th>Nazwa</th>
                                            <th>Link</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {columnOne}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mb-3">
                                    <Input id={'name'} label={'Nazwa'} type={'text'}
                                           require={true} reference={nameRefOne}/>
                                </div>
                                <div className="mb-3">
                                    <Input id={'Link'} label={'Link'} type={'text'}
                                           require={true} reference={linkRefOne}/>
                                </div>
                                <PrimaryButton label={'Dodaj link'}
                                               onClickFunction={async () => {
                                                   await CallAPI('/footer/link?column=1&name=' + nameRefOne.current.value + '&link=' + linkRefOne.current.value, null, 'POST')
                                                   void await getFooter()
                                               }}/>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="col-lg-10">
                <div className="card card-header-actions mb-4">
                    <div className="card-header">
                        Kolumna 2
                    </div>
                    <div className="card-body">
                        {isLoad ?
                            <div className='d-flex justify-content-center'>
                                <Spinner/>
                            </div>
                            :
                            <>
                                <div className="mb-3 col-4">
                                    <Input id={'colOneName'} label={'Nagłówek - nazwa'} type={'text'}
                                           require={true} reference={colTwoNameRef}
                                           value={columnTwoName}/>
                                </div>
                                <PrimaryButton
                                    label={'Zapisz'}
                                    onClickFunction={async () => {
                                        await CallAPI('/footer/columnName?column=2&name=' + colTwoNameRef.current.value, null, 'POST')
                                    }}
                                />
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                        <tr>
                                            <th>Nazwa</th>
                                            <th>Link</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {columnTwo}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mb-3">
                                    <Input id={'name'} label={'Nazwa'} type={'text'}
                                           require={true} reference={nameRefTwo}/>
                                </div>
                                <div className="mb-3">
                                    <Input id={'Link'} label={'Link'} type={'text'}
                                           require={true} reference={linkRefTwo}/>
                                </div>
                                <PrimaryButton label={'Dodaj link'}
                                               onClickFunction={async () => {
                                                   await CallAPI('/footer/link?column=2&name=' + nameRefTwo.current.value + '&link=' + linkRefTwo.current.value, null, 'POST')
                                                   void await getFooter()
                                               }}/>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="col-lg-10">
                <div className="card card-header-actions mb-4">
                    <div className="card-header">
                        Kolumna 3
                    </div>
                    <div className="card-body">
                        {isLoad ?
                            <div className='d-flex justify-content-center'>
                                <Spinner/>
                            </div>
                            :
                            <>
                                <div className="mb-3 col-4">
                                    <Input id={'colOneName'} label={'Nagłówek - nazwa'} type={'text'}
                                           require={true} reference={colThreeNameRef}
                                           value={columnThreeName}/>
                                </div>
                                <PrimaryButton
                                    label={'Zapisz'}
                                    onClickFunction={async () => {
                                        await CallAPI('/footer/columnName?column=3&name=' + colThreeNameRef.current.value, null, 'POST')
                                    }}
                                />
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                        <tr>
                                            <th>Nazwa</th>
                                            <th>Link</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {columnThree}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mb-3">
                                    <Input id={'name'} label={'Nazwa'} type={'text'}
                                           require={true} reference={nameRefThree}/>
                                </div>
                                <div className="mb-3">
                                    <Input id={'Link'} label={'Link'} type={'text'}
                                           require={true} reference={linkRefThree}/>
                                </div>
                                <PrimaryButton label={'Dodaj link'}
                                               onClickFunction={async () => {
                                                   await CallAPI('/footer/link?column=3&name=' + nameRefThree.current.value + '&link=' + linkRefThree.current.value, null, 'POST')
                                                   void await getFooter()
                                               }}/>
                            </>
                        }
                    </div>
                </div>
            </div>
        </TabWrapper>
    )
};

export default Footer;