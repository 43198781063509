import React, {useContext, useState} from "react";
import Input from "../../form/input";
import TextArea from "../../form/textArea";
import PrimaryButton from "../../buttons/primaryButton";
import DangerButton from "../../buttons/dangerButton";
import {CallAPIWithoutResponse} from "../../../api/API";
import {AppContext} from "../../../context/AppContext";
// todo refactor
const Content = ({item, getContent}) => {

    const {user, setError} = useContext(AppContext);

    const [header, setHeader] = useState(item.header)
    const [content, setContent] = useState(item.content)
    const [link, setLink] = useState(item.link)
    const [alt, setAlt] = useState(item.alt)
    const [buttonOneLabel, setButtonOneLabel] = useState(item.buttonOneLabel)
    const [buttonOneLink, setButtonOneLink] = useState(item.buttonOneLink)
    const [buttonTwoLabel, setButtonTwoLabel] = useState(item.buttonTwoLabel)
    const [buttonTwoLink, setButtonTwoLink] = useState(item.buttonTwoLink)

    const onClickSave = async () => {
        const body = {
            id: item.id,
            header: header,
            content: content,
            link: link,
            image: item.image,
            alt: alt,
            isMovie: item.isMovie,
            isImage: item.isImage,
            buttonOneLabel: buttonOneLabel,
            buttonOneLink: buttonOneLink,
            buttonTwoLabel: buttonTwoLabel,
            buttonTwoLink: buttonTwoLink,
        }
        const result = await CallAPIWithoutResponse('/mainPageContent', body, 'PUT', user.accessToken)
        if (!result.isSuccess) {
            setError({
                show: true,
                title: "Bląd zapisywania treśći",
                message: "Błąd podczas zapisywania treści"
            })
        }
        void getContent();
    }

    const onClickDelete = async () => {
        const result = await CallAPIWithoutResponse('/mainPageContent/' + item.id, null, 'DELETE', user.accessToken)
        if (!result.isSuccess) {
            setError({
                show: true,
                title: "Bląd usuwania treśći",
                message: "Błąd podczas usuwania treści"
            })
        }
        void getContent();
    }

    return (
        <>
            <h6>{item.id}</h6>
            <div className="mb-3">
                <Input id={'header'} label={'Tytuł + nagłówek (h1, h2, h3...)'} type={'text'}
                       require={true} value={header} onChange={e => setHeader(e.target.value)}/>
            </div>
            <div className="mb-3">
                <TextArea id={'content'} label={'Treść'}
                          require={true} rows={12} defaultValue={content} onChange={e => setContent(e.target.value)}/>
            </div>
            <div className="mb-3">
                <p>Typ: {item.isMovie ? "Film" : item.isImage ? "Grafika" : "Sama treść"} </p>
            </div>
            {
                item.isMovie
                &&
                <div className="mb-3">
                    <Input id={'link'} label={'Link do filmu'} type={'text'}
                           value={link} require={true} onChange={e => setLink(e.target.value)}/>
                </div>
            }
            {
                item.isImage
                &&
                <>
                    <div className="mb-3">
                        <p>Grafika: {item.image}</p>
                    </div>
                    <div className="mb-3">
                        <Input id={'alt'} label={'Alt grafiki'} type={'text'}
                               value={alt} require={true} onChange={e => setAlt(e.target.value)}/>
                    </div>
                </>
            }
            {
                (item.isMovie || item.isImage)
                &&
                <>
                    <div className="mb-3">
                        <Input id={'buttonOneLabel'} label={'Przycisk 1 - label'} type={'text'}
                               value={buttonOneLabel} require={true} onChange={e => setButtonOneLabel(e.target.value)}/>
                    </div>
                    <div className="mb-3">
                        <Input id={'buttonOneLink'} label={'Przycisk 1 - link'} type={'text'}
                               value={buttonOneLink} require={true} onChange={e => setButtonOneLink(e.target.value)}/>
                    </div>
                    <div className="mb-3">
                        <Input id={'buttonTwoLabel'} label={'Przycisk 2 - label'} type={'text'}
                               value={buttonTwoLabel} require={true} onChange={e => setButtonTwoLabel(e.target.value)}/>
                    </div>
                    <div className="mb-3">
                        <Input id={'buttonTwoLink'} label={'Przycisk 2 - link'} type={'text'}
                               value={buttonTwoLink} require={true} onChange={e => setButtonTwoLink(e.target.value)}/>
                    </div>
                </>
            }
            <div>
                <PrimaryButton label={'Zapisz'}
                               onClickFunction={() => onClickSave()}/>
                <DangerButton label={'Usuń'}
                              onClickFunction={() => onClickDelete()}/>
            </div>
            <hr/>
        </>
    )
};

export default Content;