import React from "react";
import Card from "../card";
import BlogCategoryRecord from "./BlogCategoryRecord";

const BlogCategoryListCard = ({blogData, setBlogData, setActiveCategory}) => {

    return (
        <Card cardName="Kategorie bloga">
            {blogData.map((category) => {
                return <BlogCategoryRecord
                    key={category.link}
                    category={category}
                    setBlogData={setBlogData}
                    setActiveCategory={setActiveCategory}
                />
            })}
        </Card>
    )
};

export default BlogCategoryListCard;