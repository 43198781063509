import React, {useContext, useEffect, useRef, useState} from "react";
import PrimaryButton from "../../buttons/primaryButton";
import TextArea from "../../form/textArea";
import Spinner from "../../spinner";
import {getAboutData, saveAboutData} from "./Functions";
import {AppContext} from "../../../context/AppContext";
import Input from "../../form/input";
import FileInput from "../../form/fileInput";
// todo refactor
const AboutCard = () => {

    const {user, setError} = useContext(AppContext);
    const [isLoad, setLoad] = useState(true);
    const [data, setData] = useState({});
    const mainHeaderRef = useRef("");
    const subHeaderTopRef = useRef("");
    const descriptionTopRef = useRef("");
    const contentTopRef = useRef("");
    const buttonTopLabelRef = useRef("");
    const buttonTopLinkRef = useRef("");
    const movieLinkRef = useRef("");
    const subHeaderBottomRef = useRef("");
    const descriptionBottomRef = useRef("");
    const contentBottomRef = useRef("");
    const buttonBottomOneLabelRef = useRef("");
    const buttonBottomOneLinkRef = useRef("");
    const buttonBottomTwoLabelRef = useRef("");
    const buttonBottomTwoLinkRef = useRef("");
    const imageOneRef = useRef("");
    const imageTwoRef = useRef("");
    const imageOneAltRef = useRef("");
    const imageTwoAltRef = useRef("");

    useEffect(() => {
        void getAboutData(setLoad, setError, setData)
    }, [])

    return (
        <div className="col-lg-9 mb-4">
            <div className="card card-header-actions">
                <div className="card-header">
                    Treść strony
                    <PrimaryButton label={'Zapisz'}
                                   onClickFunction={() => saveAboutData(
                                       user.accessToken,
                                       setError,
                                       mainHeaderRef.current.value,
                                       subHeaderTopRef.current.value,
                                       descriptionTopRef.current.value,
                                       contentTopRef.current.value,
                                       buttonTopLabelRef.current.value,
                                       buttonTopLinkRef.current.value,
                                       movieLinkRef.current.value,
                                       subHeaderBottomRef.current.value,
                                       descriptionBottomRef.current.value,
                                       contentBottomRef.current.value,
                                       buttonBottomOneLabelRef.current.value,
                                       buttonBottomOneLinkRef.current.value,
                                       buttonBottomTwoLabelRef.current.value,
                                       buttonBottomTwoLinkRef.current.value,
                                       imageOneRef.current.files[0],
                                       imageTwoRef.current.files[0],
                                       imageOneAltRef.current.value,
                                       imageTwoAltRef.current.value
                                   )}/>
                </div>
                <div className="card-body d-flex flex-column justify-content-center">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        <>
                            <div className="mb-3">
                                <Input id={'mainHeader'} label={'Tytuł'} type={'text'}
                                       reference={mainHeaderRef} require={true} value={data.mainHeader}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'subHeaderTop'} label={'Podtytuł 1'} type={'text'}
                                       reference={subHeaderTopRef} require={true} value={data.subHeaderTop}/>
                            </div>
                            <div className="mb-3">
                                <TextArea label={'Opis 1'} id={'descriptionTop'} rows={4} reference={descriptionTopRef}
                                          defaultValue={data.descriptionTop}/>
                            </div>
                            <div className="mb-3">
                                <TextArea label={'Treść 1'} id={'contentTop'} rows={4} reference={contentTopRef}
                                          defaultValue={data.contentTop}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'buttonTopLabel'} label={'Label przycisku'} type={'text'}
                                       reference={buttonTopLabelRef} require={true} value={data.buttonTopLabel}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'buttonTopLink'} label={'Link przycisku'} type={'text'}
                                       reference={buttonTopLinkRef} require={true} value={data.buttonTopLink}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'movieLink'} label={'Link do filmu'} type={'text'}
                                       reference={movieLinkRef} require={true} value={data.movieLink}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'subHeaderBottom'} label={'Podtytuł 2'} type={'text'}
                                       reference={subHeaderBottomRef} require={true} value={data.subHeaderBottom}/>
                            </div>
                            <div className="mb-3">
                                <TextArea label={'Opis 2'} id={'descriptionBottom'} rows={4}
                                          reference={descriptionBottomRef}
                                          defaultValue={data.descriptionBottom}/>
                            </div>
                            <div className="mb-3">
                                <TextArea label={'Treść 2'} id={'contentBottom'} rows={4} reference={contentBottomRef}
                                          defaultValue={data.contentBottom}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'buttonBottomOneLabel'} label={'Labale przycisku 1'} type={'text'}
                                       reference={buttonBottomOneLabelRef} require={true}
                                       value={data.buttonBottomOneLabel}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'buttonBottomOneLink'} label={'Link przycisku 1'} type={'text'}
                                       reference={buttonBottomOneLinkRef} require={true}
                                       value={data.buttonBottomOneLink}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'buttonBottomTwoLabel'} label={'Label przycisku 2'} type={'text'}
                                       reference={buttonBottomTwoLabelRef} require={true}
                                       value={data.buttonBottomTwoLabel}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'buttonBottomTwoLink'} label={'Link przycisku 2'} type={'text'}
                                       reference={buttonBottomTwoLinkRef} require={true}
                                       value={data.buttonBottomTwoLink}/>
                            </div>
                            <div className="mb-3">
                                <FileInput id={"imageOne"} label={"Grafika 1"} require={true} reference={imageOneRef}/>
                            </div>
                            <div className="mb-3">
                                <FileInput id={"imageTwo"} label={"Grafika 2"} require={true} reference={imageTwoRef}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'imageOneAlt'} label={'Alt grafiki 1'} type={'text'}
                                       reference={imageOneAltRef} require={true} value={data.imageOneAlt}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'imageTwoAlt'} label={'Alt grafiki 2'} type={'text'}
                                       reference={imageTwoAltRef} require={true} value={data.imageTwoAlt}/>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default AboutCard;