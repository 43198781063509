import React, {useState} from "react";
import {Card as ReactCard, Col, Row} from "react-bootstrap";
import DangerButton from "../../buttons/dangerButton";
import SecondaryButton from "../../buttons/SecondaryButton";
import DeleteBlogCategoryModal from "./DeleteBlogCategoryModal";

const BlogCategoryRecord = ({category, setBlogData, setActiveCategory}) => {

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    return (
        <ReactCard className="mb-3 shadow-sm" key={category.link}>
            <DeleteBlogCategoryModal
                category={category}
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                setBlogData={setBlogData}/>
            <ReactCard.Body>
                <Row>
                    <Col xs={12} md={9}>
                        <Row>
                            <Col xs={12} lg={8}>
                                <p className="text-lg m-0">{category.name}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={3}>
                        <Row className="justify-content-end mt-3">
                            <Col xs={6} className="d-flex justify-content-end">
                                <SecondaryButton label="Edytuj"
                                                 onClickFunction={() => setActiveCategory(category)}
                                />
                            </Col>
                            <Col xs={6}>
                                <DangerButton label="Usuń"
                                              onClickFunction={() => setShowDeleteModal(true)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ReactCard.Body>
        </ReactCard>
    )
};

export default BlogCategoryRecord;