import {CallAPI, CallAPIWithoutResponse} from "../../../api/API";
import React from "react";
import FeatherIcon from "feather-icons-react";
// todo refactor
export const getTags = async (setLoad, setError, setTags) => {
    const result = await CallAPI('/tag/all', null, 'GET')
    if (result.isSuccess) {
        const tags = []
        result.data.forEach(tag => {
            tags.push(
                <tr key={tag.id}>
                    <td>{tag.name}</td>
                    <td>{tag.link}</td>
                    <td>
                        <button className="btn btn-datatable btn-icon btn-transparent-dark"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Usuń"
                                onClick={() => deleteTag(tag.id, setLoad, setError, setTags)}>
                            <FeatherIcon icon="trash"/>
                        </button>
                    </td>
                </tr>
            )
        })
        setTags(tags)
    } else {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd pobierania tagów"
        })
    }
    setLoad(false)
}

export const saveTag = async (accessToken, setError, setLoad, setTags, name, link) => {

    const body = {
        name: name,
        link: link
    }

    const result = await CallAPIWithoutResponse('/tag', body, 'POST', accessToken)
    if (result.isSuccess) {
        await getTags(setLoad, setError, setTags)
    } else {
        setError({
            show: true,
            title: "Bląd zapisania",
            message: "Błąd podczas zapisywania tagu"
        })
    }
}

export const deleteTag = async (id, setLoad, setError, setTags) => {
    setLoad(true)
    const result = await CallAPIWithoutResponse('/tag/delete/' + id, null, 'DELETE', null)
    if (result.isSuccess) {
        await getTags(setLoad, setError, setTags)
    } else {
        setError({
            show: true,
            title: "Bląd usuwania",
            message: "Błąd podczas usuwania tagu"
        })
    }
    setLoad(false)
}