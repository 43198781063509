import React, {useContext, useEffect, useRef, useState} from "react";
import Spinner from "../../spinner";
import {AppContext} from "../../../context/AppContext";
import Input from "../../form/input";
import TextArea from "../../form/textArea";
import PrimaryButton from "../../buttons/primaryButton";
import {getSlides, saveSlide} from "./Functions";
import FileInput from "../../form/fileInput";
import {CallAPI, CallAPIWithFile} from "../../../api/API";
import DetailsSlider from "./DetailsSlider";
// todo refactor
const SliderCard = () => {

    const {user, setError} = useContext(AppContext);
    const [isLoad, setLoad] = useState(true);
    const [slides, setSlides] = useState([])
    const [activeDetails, setActiveDetails] = useState(0);
    const [delay, setDelay] = useState(0)
    const titleRef = useRef("");
    const titleColorRef = useRef("");
    const contentRef = useRef("");
    const contentColorRef = useRef("");
    const linkRef = useRef("");
    const altRef = useRef("");
    const fileRef = useRef("");

    const getDelay = async () => {
        const result = await CallAPI('/slide/getDelay', null, 'GET')
        if (result.isSuccess) {
            setDelay(result.data)
        } else {
            setError({
                show: true,
                title: "Bląd pobierania",
                message: "Błąd pobierania czasu wyświetlania"
            })
        }
        setLoad(false)
    }

    const saveDelay = async () => {
        const body = new FormData();
        body.append('delay', delay)
        const result = await CallAPIWithFile('/slide/setDelay', body, user.accessToken)
        if (!result.isSuccess) {
            setError({
                show: true,
                title: "Bląd zapisywania",
                message: "Błąd zapisywania czasu wyświetlania"
            })
        }
    }

    useEffect(() => {
        void getSlides(setLoad, setError, setSlides, user.accessToken, setActiveDetails)
        void getDelay()
    }, [])
    return (
        <div className="col-lg-10">
            <div className="card card-header-actions mb-4">
                <div className="card-header">
                    Slider
                    {activeDetails ?
                        <button className="btn btn-primary btn-sm"
                                onClick={() => setActiveDetails(null)}>
                            Wstecz
                        </button>
                        : null
                    }
                </div>
                <div className="card-body">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        activeDetails ?
                            <DetailsSlider slide={activeDetails} setSlides={setSlides} setActiveDetails={setActiveDetails}/>
                            :
                            <>
                                <div className="row mb-3">
                                    <div className="col">
                                        <Input id={'delay'} label={'Czas wyświetlania (sekundy)'} type={'number'}
                                               require={true} value={delay} onChange={e => setDelay(e.target.value)}/>
                                    </div>
                                    <div className="col mt-4">
                                        <PrimaryButton label={'Zapisz'}
                                                       onClickFunction={() => saveDelay()}/>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <thead>
                                        <tr>
                                            <th>Tytuł</th>
                                            <th>Treść</th>
                                            <th>Link</th>
                                            <th>Plik</th>
                                            <th>ALT grafiki</th>
                                            <th>Akcje</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {slides}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mb-3">
                                    <Input id={'title'} label={'Tytuł'} type={'text'}
                                           require={true} reference={titleRef}/>
                                </div>
                                <div className="mb-3">
                                    <Input id={'titleColor'} label={'Kolor tytułu (hex)'} type={'text'}
                                           require={true} reference={titleColorRef}/>
                                </div>
                                <div className="mb-3">
                                    <TextArea label={'Treść'} id={'Treść'} rows={4} reference={contentRef}/>
                                </div>
                                <div className="mb-3">
                                    <Input id={'contentColor'} label={'Kolor treści (hex)'} type={'text'}
                                           require={true} reference={contentColorRef}/>
                                </div>
                                <div className="mb-3">
                                    <Input id={'Link'} label={'Link'} type={'text'}
                                           require={true} reference={linkRef}/>
                                </div>
                                <div className="mb-3">
                                    <Input id={'ALT grafiki'} label={'ALT grafiki'} type={'text'}
                                           require={true} reference={altRef}/>
                                </div>
                                <div className="mb-3">
                                    <FileInput id={"slideFile"} label={"Grafika"} require={true} reference={fileRef}/>
                                </div>
                                <PrimaryButton label={'Zapisz'}
                                               onClickFunction={
                                                   () => saveSlide(
                                                       user.accessToken,
                                                       setError,
                                                       setLoad,
                                                       setSlides,
                                                       fileRef.current.files[0].name,
                                                       altRef.current.value,
                                                       titleRef.current.value,
                                                       titleColorRef.current.value,
                                                       contentRef.current.value,
                                                       contentColorRef.current.value,
                                                       linkRef.current.value,
                                                       fileRef.current.files[0],
                                                       setActiveDetails
                                                   )}/>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}

export default SliderCard;