import React, {useState} from "react";
import SmallSpinner from "../spinner/SmallSpinner";

const SecondaryButton = ({label, onClickFunction}) => {

    const [isLoad, setLoad] = useState(false);

    const onClick = async () => {
        setLoad(true)
        if (onClickFunction) {
            await onClickFunction()
        }
        setLoad(false)
    }

    return (
        <button className="btn btn-secondary"
                type={'button'}
                disabled={isLoad}
                onClick={onClick}
        >
            {isLoad && <SmallSpinner/>}
            {label}
        </button>
    )
}

export default SecondaryButton;