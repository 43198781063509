import React, {useState} from "react";
import Modal from "../../modal/Modal";
import {Col, Row} from "react-bootstrap";
import {useApi} from "../../../hooks/useApi";
import Space from "../../layout/Space";
import Input from "../../form/input";

const EditTextBoxModal = ({show, setShow, discount, getDiscounts}) => {

    const selectedType = discount?.discountType;
    const [code, setCode] = useState(discount?.code);
    const [expirationDate, setExpirationDate] = useState(discount?.expirationDate);
    const [percent, setPercent] = useState(discount?.percent);
    const [maxNumberOfUses, setMaxNumberOfUses] = useState(discount?.maxNumberOfUses);
    const [definedValue, setDefinedValue] = useState(discount?.definedValue);

    const [codeValidation, setCodeValidation] = useState(null)
    const [definedValueValidation, setDefinedValueValidation] = useState(null)
    const [expirationDateValidation, setExpirationDateValidation] = useState(null)
    const [percentValidation, setPercentValidation] = useState(null)
    const [maxNumberOfUsesValidation, setMaxNumberOfUsesValidation] = useState(null)

    const {putApi} = useApi()

    const cancel = () => {
        setShow(false)
    }

    const clearValidation = () => {
        setCodeValidation(null)
        setDefinedValueValidation(null)
        setExpirationDateValidation(null)
        setPercentValidation(null)
        setMaxNumberOfUsesValidation(null)
    }

    const valid = () => {
        let isValid = true
        const emptyCode = code === "" || code === null
        if (emptyCode) {
            isValid = false
            setCodeValidation("Kod jest wymagany")
        }
        if (selectedType === "VOUCHER") {
            const emptyDefinedValue = definedValue === "" || definedValue === null
            if (emptyDefinedValue) {
                isValid = false
                setDefinedValueValidation("Wartość rabatu jest wymagana")
            }
        } else {
            const emptyExpirationDate = expirationDate === "" || expirationDate === null
            if (emptyExpirationDate) {
                isValid = false
                setExpirationDateValidation("Data ważności jest wymagana")
            }
            const emptyPercent = percent === "" || percent === null
            if (emptyPercent) {
                isValid = false
                setPercentValidation("Procent rabatu jest wymagany")
            }
            const emptyMaxNumberOfUses = maxNumberOfUses === "" || maxNumberOfUses === null
            if (emptyMaxNumberOfUses) {
                isValid = false
                setMaxNumberOfUsesValidation("Liczba kuponów jest wymagana")
            }
        }
        return isValid;
    }

    const approve = async () => {
        if (valid()) {
            const body = {
                code: code,
                expirationDate: expirationDate,
                discountType: selectedType,
                percent: percent,
                maxNumberOfUses: maxNumberOfUses,
                numberOfUses: 0,
                definedValue: definedValue,
                currentValue: definedValue
            }
            await putApi('/discount/' + discount.id, body)
            getDiscounts()
            clearValidation()
            setShow(false)
        }
    }

    return (
        show &&
        <Modal title="Edytuj rabat"
               onCancel={() => cancel()}
               onApprove={() => approve()}>
            <Row>
                <Col>
                    <Row>
                        <Col xs={6}>
                            <Input
                                label='Kod'
                                type='text'
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                            {codeValidation ? <span className="text-danger">{codeValidation}</span> : null}
                        </Col>
                        {selectedType === 'VOUCHER' &&
                            <Col xs={6}>
                                <Input
                                    label='Wartość rabatu'
                                    type='number'
                                    value={definedValue}
                                    onChange={(e) => setDefinedValue(e.target.value)}
                                />
                                {definedValueValidation ? <span className="text-danger">{definedValueValidation}</span> : null}
                            </Col>
                        }
                        {selectedType === 'PERCENTAGE' &&
                            <Col>
                                <Input
                                    label='Data ważności'
                                    type='datetime-local'
                                    value={expirationDate}
                                    onChange={(e) => setExpirationDate(e.target.value)}
                                />
                                {expirationDateValidation ?
                                    <span className="text-danger">{expirationDateValidation}</span> : null}
                            </Col>
                        }
                    </Row>
                    {selectedType === 'PERCENTAGE' &&
                        <>
                            <Space space={30}/>
                            <Row>
                                <Col>
                                    <Input
                                        label='Procent rabatu'
                                        type='number'
                                        value={percent}
                                        onChange={(e) => setPercent(e.target.value)}
                                    />
                                    {percentValidation ? <span className="text-danger">{percentValidation}</span> : null}
                                </Col>
                                <Col>
                                    <Input
                                        label='Liczba kuponów'
                                        type='number'
                                        value={maxNumberOfUses}
                                        onChange={(e) => setMaxNumberOfUses(e.target.value)}
                                    />
                                    {maxNumberOfUsesValidation ?
                                        <span className="text-danger">{maxNumberOfUsesValidation}</span> : null}
                                </Col>
                            </Row>
                        </>
                    }
                </Col>
            </Row>
        </Modal>
    )
};

export default EditTextBoxModal;