import React from "react";
import PrimaryButton from "../buttons/primaryButton";
import SecondaryButton from "../buttons/SecondaryButton";

const Footer = ({onCancel, onApprove}) => {

    return (
        <div className="modal-footer">
            <SecondaryButton label={'Anuluj'} onClickFunction={onCancel}/>
            <PrimaryButton label={'OK'} onClickFunction={onApprove}/>
        </div>
    )
};

export default Footer;