import {CallAPIGetImage} from "../../../api/API";
// todo refactor
export const onClick = async (url, accessToken, name, setLoad, setError) => {
    setLoad(true)
    const result = await CallAPIGetImage(url, accessToken, name)
    if (!result.isSuccess) {
        setError({
            show: true,
            title: "Bląd pobierania",
            message: "Błąd podczas pobierania grafiki"
        })
    }
    setLoad(false)
}