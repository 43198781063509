import React from "react";
import SliderCard from "../../cards/sliderCard";
import RealizationCard from "../../cards/realizationCard";
import OpinionCard from "../../cards/opinionCard";
import MainPageContent from "../../cards/mainPageContent/MainPageContent";
import TextBoxCard from "../../cards/textBoxCard/TextBoxCard";
import TabWrapper from "../TabWrapper";
import BasicPageDataCard from "../../cards/basicPageDataCard/BasicPageDataCard";

const MainPage = () => {

    return (
        <TabWrapper>
            <BasicPageDataCard page={'MAIN_PAGE'}/>
            <SliderCard/>
            <MainPageContent/>
            <RealizationCard/>
            <TextBoxCard page={'MAIN'}/>
            <OpinionCard/>
        </TabWrapper>
    )
}

export default MainPage;