import React from "react";
import {ImageTemplateContext} from "../../../../context/ImageTemplateContext";
import CardCollapse from "../../../cards/cardCollapse";
import BaseDataTab from "../templateTabs/baseDataTab";
import ContentTab from "../templateTabs/contentTab";
import CardTab from "../templateTabs/cardTab";
import OwnPhotoEditorTab from "../templateTabs/ownPhotoEditorTab";
import GraphicEditorTab from "../templateTabs/graphicEditorTab";
import ConfigTab from "../templateTabs/configTab";
import TemplateText from "../templateTabs/templateText";
import ManyGraphicsEditorTab from "../templateTabs/manyGraphicsEditorTab";
import TextBoxCard from "../../../cards/textBoxCard/TextBoxCard";

const ImageTemplate = ({
                           setUpdate,
                           id,
                           type,
                           name,
                           description,
                           link,
                           title,
                           metaDescription,
                           metaKeywords,
                           metaRobots,
                           linkCanonical,
                           siteImage,
                           openGraph,
                           header,
                           helpMovieLink,
                           contentMovieLink,
                           firstContentTitle,
                           firstContentText,
                           secondContentTitle,
                           secondContentText,
                           imageSmallSecondContent,
                           imageSmallSecondContentAlt,
                           imageBigSecondContent,
                           imageBigSecondContentAlt,
                           lastContentTitle,
                           lastContentText,
                           imageSmallLastContent,
                           imageSmallLastContentAlt,
                           imageBigLastContent,
                           imageBigLastContentAlt,
                           pro,
                           cardImage,
                           cardImageAlt,
                           cardName,
                           cardDescription,
                           cardPriceText,
                           editorColor,
                           editorSizes,
                           minWidth,
                           minHeight,
                           graphicWidth,
                           graphicHeight,
                           editorFieldWidth,
                           editorFieldHeight,
                           graphic,
                           graphicPrint,
                           editorFieldPositionLeft,
                           editorFieldPositionTop,
                           category,
                           subCategory,
                           angle,
                           templateTexts,
                           graphicsSections
                       }) => {

    const value = {
        setUpdate,
        id,
        type,
        name,
        description,
        link,
        title,
        metaDescription,
        metaKeywords,
        metaRobots,
        linkCanonical,
        siteImage,
        openGraph,
        header,
        helpMovieLink,
        contentMovieLink,
        firstContentTitle,
        firstContentText,
        secondContentTitle,
        secondContentText,
        imageSmallSecondContent,
        imageSmallSecondContentAlt,
        imageBigSecondContent,
        imageBigSecondContentAlt,
        lastContentTitle,
        lastContentText,
        imageSmallLastContent,
        imageSmallLastContentAlt,
        imageBigLastContent,
        imageBigLastContentAlt,
        pro,
        cardImage,
        cardImageAlt,
        cardName,
        cardDescription,
        cardPriceText,
        editorColor,
        editorSizes,
        minWidth,
        minHeight,
        graphicWidth,
        graphicHeight,
        editorFieldWidth,
        editorFieldHeight,
        graphic,
        graphicPrint,
        editorFieldPositionLeft,
        editorFieldPositionTop,
        category,
        subCategory,
        angle,
        templateTexts,
        graphicsSections
    }

    return (
        <ImageTemplateContext.Provider value={value}>
            <CardCollapse size={12}
                          cardName={type + ' -> ' + name}
                          descripton={description}
            >
                <div className="card">
                    <div className="card-header">
                        <ul className="nav nav-tabs card-header-tabs" id="cardTab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active"
                                   id="overview-tab"
                                   href={"#base" + id}
                                   data-bs-toggle="tab" role="tab"
                                   aria-controls="overview"
                                   aria-selected="true">Podstawowe dane strony</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                   id="example-tab"
                                   href={"#content" + id}
                                   data-bs-toggle="tab" role="tab"
                                   aria-controls="overview"
                                   aria-selected="false">Treści na stronie</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                   id="example-tab"
                                   href={"#textboxes" + id}
                                   data-bs-toggle="tab" role="tab"
                                   aria-controls="overview"
                                   aria-selected="false">Boxy z treścią</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                   id="example-tab"
                                   href={"#card" + id}
                                   data-bs-toggle="tab" role="tab"
                                   aria-controls="overview"
                                   aria-selected="false">Karta szablonu</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                   id="example-tab"
                                   href={"#editor" + id}
                                   data-bs-toggle="tab" role="tab"
                                   aria-controls="overview"
                                   aria-selected="false">Edytor</a>
                            </li>
                            {type === 'Własne zdjęcie' ?
                                null
                                :
                                <li className="nav-item">
                                    <a className="nav-link"
                                       id="example-tab"
                                       href={"#templateText" + id}
                                       data-bs-toggle="tab" role="tab"
                                       aria-controls="overview"
                                       aria-selected="false">Teksty na szablonie</a>
                                </li>
                            }
                            <li className="nav-item">
                                <a className="nav-link"
                                   id="example-tab"
                                   href={"#config" + id}
                                   data-bs-toggle="tab" role="tab"
                                   aria-controls="overview"
                                   aria-selected="false">Konfiguracja</a>
                            </li>
                        </ul>
                    </div>
                    <div className="card-body col-lg-12">
                        <div className="tab-content" id="cardTabContent">
                            <div className="tab-pane fade show active" id={"base" + id} role="tabpanel"
                                 aria-labelledby="overview-tab">
                                <BaseDataTab/>
                            </div>
                            <div className="tab-pane fade" id={"content" + id} role="tabpanel"
                                 aria-labelledby="example-tab">
                                <ContentTab/>
                            </div>
                            <div className="tab-pane fade" id={"textboxes" + id} role="tabpanel"
                                 aria-labelledby="example-tab">
                                <TextBoxCard page={"IMAGE_TEMPLATE-" + id}/>
                            </div>
                            <div className="tab-pane fade" id={"card" + id} role="tabpanel"
                                 aria-labelledby="example-tab">
                                <CardTab/>
                            </div>
                            <div className="tab-pane fade" id={"editor" + id} role="tabpanel"
                                 aria-labelledby="example-tab">
                                {
                                    {
                                        'Własne zdjęcie': <OwnPhotoEditorTab/>,
                                        'Grafika': <GraphicEditorTab/>,
                                        'Nieregularna grafika': <GraphicEditorTab/>,
                                        'Wiele grafik': <ManyGraphicsEditorTab/>,
                                    }[type]
                                }
                            </div>
                            <div className="tab-pane fade" id={"templateText" + id} role="tabpanel"
                                 aria-labelledby="example-tab">
                                <TemplateText templateTexts={templateTexts} imageTemplateId={id}/>
                            </div>
                            <div className="tab-pane fade" id={"config" + id} role="tabpanel"
                                 aria-labelledby="example-tab">
                                    <ConfigTab/>
                            </div>
                        </div>
                    </div>
                </div>
            </CardCollapse>
        </ImageTemplateContext.Provider>
    )
}

export default ImageTemplate;