import React, {useContext, useEffect, useRef, useState} from "react";
import Spinner from "../../spinner";
import {AppContext} from "../../../context/AppContext";
import Input from "../../form/input";
import PrimaryButton from "../../buttons/primaryButton";
import {getTags, saveTag} from "./Functions";
// todo refactor
const TagCard = () => {

    const {user, setError} = useContext(AppContext);
    const [isLoad, setLoad] = useState(true);
    const [tags, setTags] = useState([])
    const nameRef = useRef("");
    const linkRef = useRef("");

    useEffect(() => {
        void getTags(setLoad, setError, setTags)
    }, [])

    return (
        <div className="col-lg-4">
            <div className="card card-header-actions mb-4">
                <div className="card-header">
                    Tagi produktów
                </div>
                <div className="card-body">
                    {isLoad ?
                        <div className='d-flex justify-content-center'>
                            <Spinner/>
                        </div>
                        :
                        <>
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                    <tr>
                                        <th>Nazwa</th>
                                        <th>Link</th>
                                        <th>Akcje</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tags}
                                    </tbody>
                                </table>
                            </div>
                            <div className="mb-3">
                                <Input id={'name'} label={'Nazwa'} type={'text'}
                                       require={true} reference={nameRef}/>
                            </div>
                            <div className="mb-3">
                                <Input id={'link'} label={'link'} type={'text'}
                                       require={true} reference={linkRef}/>
                            </div>
                            <PrimaryButton label={'Zapisz'}
                                           onClickFunction={
                                               () => saveTag(
                                                   user.accessToken,
                                                   setError,
                                                   setLoad,
                                                   setTags,
                                                   nameRef.current.value,
                                                   linkRef.current.value,
                                               )}/>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default TagCard;