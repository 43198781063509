import React, {useContext, useEffect, useRef, useState} from "react";
import {ImageCardContext} from "../../../context/ImageCardContext";
import {useApi} from "../../../hooks/useApi";
import Input from "../../form/input";
import TextArea from "../../form/textArea";
import PrimaryButton from "../../buttons/primaryButton";
// todo refactor
const Box = () => {

    const {activeCategory} = useContext(ImageCardContext);

    const [category, setCategory] = useState({})

    const categoryBoxTitleRef = useRef('')
    const categoryBoxDescriptionRef = useRef('')

    const {getApi, postApi} = useApi()

    const getCategory = async () => {
        const result = await getApi('/imageCategory/' + activeCategory.id)
        setCategory(result.data)
    }

    const saveCategoryBox = async () => {
        const body = {
            categoryBoxTitle: categoryBoxTitleRef.current.value,
            categoryBoxDescription: categoryBoxDescriptionRef.current.value
        }
        await postApi('/imageCategory/box/' + activeCategory.id, body)
    }

    useEffect(() => {
        void getCategory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="mb-3">
                <Input id='categoryBoxTitle'
                       label='Tytuł'
                       type='text'
                       reference={categoryBoxTitleRef}
                       value={category.categoryBoxTitle}
                />
            </div>
            <div className="mb-3">
                <TextArea id='categoryBoxDescription'
                          label='Opis'
                          reference={categoryBoxDescriptionRef}
                          defaultValue={category.categoryBoxDescription}
                          rows={12}
                />
            </div>
            <PrimaryButton label='Zapisz'
                           onClickFunction={() => saveCategoryBox()}
            />
        </>
    );

};

export default Box;