import React from "react";
import {useApi} from "../../../hooks/useApi";
import Modal from "../../modal/Modal";

const DeleteDiscountModal = ({show, setShow, discount, getDiscounts}) => {

    const {deleteApi} = useApi()

    const deleteDiscount = async () => {
        await deleteApi("/discount/" + discount.id)
        getDiscounts()
        setShow(false)
    }

    return (
        show &&
        <Modal title="Czy na pewno?"
               onCancel={() => setShow(false)}
               onApprove={() => deleteDiscount()}
               size="md">
            <p>Czy na pewno chcesz usunąć rabat {discount.code}?</p>
        </Modal>
    )
};

export default DeleteDiscountModal;